import React, { FC } from "react";

import {
  isAccessManagementAvailable,
  isAlertsAvailable,
  isClientAvailable,
  isInvoicesAvailable,
  isFastChecksAvailable,
  isMonitoredWallets
} from "utils/featuresCheck";

import { paths } from "./paths";

export const HomePage = React.lazy(() => import("pages/home"));
export const SignInPage = React.lazy(() => import("pages/auth/sign-In"));
export const SignUpPage = React.lazy(() => import("pages/auth/sign-up"));
export const ApiKeysPage = React.lazy(() => import("pages/api-keys"));
export const RiskSettingsPage = React.lazy(() => import("pages/risk-settings"));
export const RiskSettingsDetailPage = React.lazy(() => import("pages/risk-settings/detail"));
export const AlertsPage = React.lazy(() => import("pages/alerts"));
export const AlertsDetailPage = React.lazy(() => import("pages/alerts/details"));
export const TransfersPage = React.lazy(() => import("pages/transfers"));
export const MonitoredWalletsPage = React.lazy(() => import("pages/transfers/monitored-wallets"));
export const MonitoredWalletsDetailsPage = React.lazy(() =>
  import("pages/transfers/monitored-wallets-information"));
export const WalletDetailsTransfersPage = React.lazy(() =>
  import("pages/transfers/details-transfers"));
export const TransfersDetailsPage = React.lazy(() => import("pages/transfers/details"));
export const TransfersDetailsAlertPage = React.lazy(() => import("pages/transfers/details-alert"));
export const TransfersDetailsAlertsPage = React.lazy(() => import("pages/transfers/details-alerts"));
export const TransfersDetailsAlertsDetailsPage = React.lazy(() => import("pages/transfers/details-alerts-details"));
export const SettingsSafetyPage = React.lazy(() => import("pages/settings/safety"));
export const PlanPage = React.lazy(() => import("pages/plan"));
export const AccessManagementPage = React.lazy(() => import("pages/access-management"));
export const AccessManagementMobileNew = React.lazy(() => import("pages/access-management/mobile-new"));
export const AccessManagementDetailPage = React.lazy(() => import("pages/access-management/detail"));
export const ClientsPage = React.lazy(() => import("pages/clients"));
export const ClientsAlertPage = React.lazy(() => import("pages/clients/details-alert"));
export const ClientsAlertsPage = React.lazy(() => import("pages/clients/details-alerts"));
export const ClientsAlertsDetailsPage = React.lazy(() => import("pages/clients/details-alerts-details"));
export const ClientsDetailsPage = React.lazy(() => import("pages/clients/details"));
export const ClientsDetailsTransfersPage = React.lazy(() => import("pages/clients/details-transfers"));
export const ClientsDetailsTransfersDetailsPage = React.lazy(() => import("pages/clients/details-transfers-details"));
export const ClientsDetailsTransferPage = React.lazy(() => import("pages/clients/details-transfer"));
export const ClientsDetailsTransferAlertPage =
  React.lazy(() => import("pages/clients/details-transfers-details-alert"));
export const ClientsDetailsTransferAlertsPage =
  React.lazy(() => import("pages/clients/details-transfers-details-alerts"));
export const ClientsArchivePage = React.lazy(() => import("pages/clients/archive"));
export const ClientsArchiveDetailsAlertPage = React.lazy(() => import("pages/clients/archive-details-alert"));
export const ClientsArchiveDetailsAlertsPage = React.lazy(() => import("pages/clients/archive-details-alerts"));
export const ClientsArchiveDetailsAlertsDetailsPage =
  React.lazy(() => import("pages/clients/archive-details-alerts-details"));
export const ClientsArchiveDetailsPage = React.lazy(() => import("pages/clients/archive-details"));
export const ClientsArchiveDetailsTransfersPage = React.lazy(() => import("pages/clients/archive-details-transfers"));
export const ClientsArchiveDetailsTransfersDetailsPage =
  React.lazy(() => import("pages/clients/archive-details-transfers-details"));
export const ClientsArchiveDetailsTransferPage = React.lazy(() => import("pages/clients/archive-details-transfer"));
export const ClientsArchiveDetailsTransferAlertPage =
  React.lazy(() => import("pages/clients/archive-details-transfers-details-alert"));
export const ClientsArchiveDetailsTransferAlertsPage =
  React.lazy(() => import("pages/clients/archive-details-transfers-details-alerts"));
export const InvoicesPage = React.lazy(() => import("pages/invoices"));
export const MyWalletsPage = React.lazy(() => import("pages/invoices/my-wallets"));
export const FastChecksPage = React.lazy(() => import("pages/fast-checks"));
export const FastChecksDetailsPage = React.lazy(() => import("pages/fast-checks/details"));
export const FastChecksDetailsRisksPage = React.lazy(() => import("pages/fast-checks/details-risks"));

export type TRoutes = {
  path?: paths;
  component: FC;
  active: boolean;
  exact: boolean;
  isModalRoute?: boolean;
};

export const privateRoutes: TRoutes[] = [
  {
    path: paths.HOME,
    component: HomePage,
    active: isAlertsAvailable(),
    exact: true,
  },
  { path: paths.ALERTS_DETAIL, component: AlertsDetailPage, active: true, exact: true, },
  { path: paths.API_KEYS, component: ApiKeysPage, active: true, exact: true },
  { path: paths.RISK_SETTINGS, component: RiskSettingsPage, active: true, exact: true },
  { path: paths.RISK_SETTINGS_DETAILS, component: RiskSettingsDetailPage, active: true, exact: true },
  { path: paths.TRANSFERS, component: TransfersPage, active: true, exact: true },
  {
    path: paths.TRANSFERS_MONITORED_WALLETS,
    component: MonitoredWalletsPage,
    active: isMonitoredWallets(),
    exact: true
  },
  {
    path: paths.TRANSFERS_MONITORED_WALLETS_INFORMATION,
    component: MonitoredWalletsDetailsPage,
    active: isMonitoredWallets(),
    exact: true
  },
  {
    path: paths.TRANSFERS_MONITORED_WALLETS_TRANSFER,
    component: MonitoredWalletsDetailsPage,
    active: isMonitoredWallets(),
    exact: true
  },
  {
    path: paths.TRANSFERS_MONITORED_WALLETS_INFORMATION_ARCHIVE,
    component: WalletDetailsTransfersPage,
    active: isMonitoredWallets(),
    exact: true
  },
  { path: paths.TRANSFERS_DETAILS, component: TransfersDetailsPage, active: true, exact: true, isModalRoute: true },
  { path: paths.TRANSFERS_DETAILS_ALERTS, component: TransfersDetailsAlertsPage, active: true, exact: true },
  { path: paths.TRANSFERS_DETAILS_ALERT, component: TransfersDetailsAlertPage, active: true, exact: true },
  {
    path: paths.TRANSFERS_DETAILS_ALERTS_DETAILS,
    component: TransfersDetailsAlertsDetailsPage,
    active: true,
    exact: true
  },
  { path: paths.SETTINGS_SAFETY, component: SettingsSafetyPage, active: true, exact: true },
  { path: paths.PLAN, component: PlanPage, active: true, exact: true },
  {
    path: paths.ACCESS_MANAGEMENT,
    component: AccessManagementPage,
    active: isAccessManagementAvailable(),
    exact: true
  },
  {
    path: paths.ACCESS_MANAGEMENT_NEW,
    component: AccessManagementMobileNew,
    active: isAccessManagementAvailable(),
    exact: true
  },
  {
    path: paths.ACCESS_MANAGEMENT_DETAIL,
    component: AccessManagementDetailPage,
    active: isAccessManagementAvailable(),
    exact: true
  },
  {
    path: paths.CLIENTS,
    component: ClientsPage,
    active: isClientAvailable(),
    exact: true
  },
  {
    path: paths.CLIENTS_ARCHIVE,
    component: ClientsArchivePage,
    active: isClientAvailable(),
    exact: true
  },
  {
    path: paths.CLIENTS_ARCHIVE_DETAILS,
    component: ClientsArchiveDetailsPage,
    active: isClientAvailable(),
    exact: true
  },
  {
    path: paths.CLIENTS_ARCHIVE_DETAILS_ALERTS,
    component: ClientsArchiveDetailsAlertsPage,
    active: isClientAvailable(),
    exact: true
  },
  {
    path: paths.CLIENTS_ARCHIVE_DETAILS_ALERTS_DETAILS,
    component: ClientsArchiveDetailsAlertsDetailsPage,
    active: isClientAvailable(),
    exact: true
  },
  {
    path: paths.CLIENTS_ARCHIVE_DETAILS_ALERT,
    component: ClientsArchiveDetailsAlertPage,
    active: isClientAvailable(),
    exact: true
  },
  {
    path: paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS,
    component: ClientsArchiveDetailsTransfersPage,
    active: isClientAvailable(),
    exact: true
  },
  {
    path: paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS_DETAILS,
    component: ClientsArchiveDetailsTransfersDetailsPage,
    active: isClientAvailable(),
    exact: true
  },
  {
    path: paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS_DETAILS_ALERTS,
    component: ClientsArchiveDetailsTransferAlertsPage,
    active: isClientAvailable(),
    exact: true
  },
  {
    path: paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS_DETAILS_ALERT,
    component: ClientsArchiveDetailsTransferAlertPage,
    active: isClientAvailable(),
    exact: true
  },
  {
    path: paths.CLIENTS_ARCHIVE_DETAILS_TRANSFER,
    component: ClientsArchiveDetailsTransferPage,
    active: isClientAvailable(),
    exact: true
  },
  {
    path: paths.CLIENTS_DETAILS_ALERTS,
    component: ClientsAlertsPage,
    active: isClientAvailable(),
    exact: true
  },
  {
    path: paths.CLIENTS_DETAILS_ALERTS_DETAILS,
    component: ClientsAlertsDetailsPage,
    active: isClientAvailable(),
    exact: true
  },
  {
    path: paths.CLIENTS_DETAILS_ALERT,
    component: ClientsAlertPage,
    active: isClientAvailable(),
    exact: true
  },
  {
    path: paths.CLIENTS_DETAILS_TRANSFERS,
    component: ClientsDetailsTransfersPage,
    active: isClientAvailable(),
    exact: true
  },
  {
    path: paths.CLIENTS_DETAILS_TRANSFERS_DETAILS,
    component: ClientsDetailsTransfersDetailsPage,
    active: isClientAvailable(),
    exact: true
  },
  {
    path: paths.CLIENTS_DETAILS_TRANSFERS_DETAILS_ALERTS,
    component: ClientsDetailsTransferAlertsPage,
    active: isClientAvailable(),
    exact: true
  },
  {
    path: paths.CLIENTS_DETAILS_TRANSFERS_DETAILS_ALERT,
    component: ClientsDetailsTransferAlertPage,
    active: isClientAvailable(),
    exact: true
  },
  {
    path: paths.CLIENTS_DETAILS_TRANSFER,
    component: ClientsDetailsTransferPage,
    active: isClientAvailable(),
    exact: true
  },
  {
    path: paths.CLIENTS_DETAILS,
    component: ClientsDetailsPage,
    active: isClientAvailable(),
    exact: true
  },

  {
    path: paths.INVOICES,
    component: InvoicesPage,
    active: isInvoicesAvailable(),
    exact: true
  },
  {
    path: paths.INVOICES_MY_WALLETS,
    component: MyWalletsPage,
    active: isInvoicesAvailable(),
    exact: true
  },
  {
    path: paths.FAST_CHECKS,
    component: FastChecksPage,
    active: isFastChecksAvailable(),
    exact: true
  },
  {
    path: paths.FAST_CHECKS_DETAILS,
    component: FastChecksDetailsPage,
    active: isFastChecksAvailable(),
    exact: true
  },
  {
    path: paths.FAST_CHECKS_DETAILS_RISKS,
    component: FastChecksDetailsRisksPage,
    active: isFastChecksAvailable(),
    exact: true
  },
];

export const publicRoutes: TRoutes[] = [
  { path: paths.SIGN_IN, component: SignInPage, active: true, exact: true, },
  { path: paths.SIGN_UP, component: SignUpPage, active: true, exact: true, },
];

export const allRoutes = privateRoutes.concat(publicRoutes);
