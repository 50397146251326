import { paths } from "config/paths";
import { NAVIGATIONS } from "config/constants";

import { TLinks } from "../../../config/types";

const menus: TLinks[] = [
  NAVIGATIONS[paths.HOME],
  NAVIGATIONS[paths.TRANSFERS],
  NAVIGATIONS[paths.FAST_CHECKS],
  NAVIGATIONS[paths.CLIENTS],
];

export const MOBILE_MENU: TLinks[] = menus;

export const MOBILE_MENU_ADDITIONAL: TLinks[] = [];
