import { createSlice } from "@reduxjs/toolkit";


import { defaultInitialState, defaultState } from "../constants";
import { IPayloadAction } from "../rootInterface";

import {
  TFastChecksCheckAddress,
  TFastChecksCheckAddressOptions, TFastChecksChecksRiskOptions, TFastChecksRisksData,
  TFastChecksCheckTransfer,
  TFastChecksCheckTransferOptions,
  TFastChecksData, TFastChecksDetails, TFastChecksDetailsOptions,
  TFastChecksOptions,
  TFastChecksStoreState
} from "./types";

const initialState: TFastChecksStoreState = {
  fastChecks: defaultInitialState,
  fastChecksCheckTransfer: defaultState,
  fastChecksCheckAddress: defaultState,
  fastChecksDetails: defaultInitialState,
  fastChecksRisks: defaultInitialState,
  fastChecksRecheck: defaultInitialState,
  fastFilterMobileGroups: defaultInitialState,
};

const fastChecksSlice = createSlice({
  name: "fastChecks",
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fastChecksRequest(state, _: IPayloadAction<TFastChecksOptions>) {
      state.fastChecks = {
        ...state.fastChecks,
        fetching: true,
      };
    },
    fastChecksSuccess(state, action: IPayloadAction<{
      data: TFastChecksData, infiniteScroll?: boolean;
    }>) {
      let newData = action.payload.data;
      if (action.payload.infiniteScroll) {
        const prevResults = state?.fastChecks?.data?.results || [];

        newData = {
          ...action.payload.data,
          results: [...prevResults, ...(action.payload?.data?.results || [])]
        };
      }
      state.fastChecks = {
        ...state.fastChecks,
        fetching: false,
        data: newData,
        failure: null,
        initialLoading: false,
      };
    },
    fastChecksFailure(state, action) {
      state.fastChecks = {
        ...state.fastChecks,
        fetching: false,
        failure: action.payload,
        initialLoading: false,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fastChecksCheckTransferRequest(state, _: IPayloadAction<TFastChecksCheckTransferOptions>) {
      state.fastChecksCheckTransfer = {
        ...state.fastChecksCheckTransfer,
        fetching: true,
      };
    },
    fastChecksCheckTransferSuccess(state, action: IPayloadAction<TFastChecksCheckTransfer>) {
      state.fastChecksCheckTransfer = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    fastChecksCheckTransferFailure(state, action) {
      state.fastChecksCheckTransfer = {
        ...state.fastChecksCheckTransfer,
        fetching: false,
        failure: action.payload,
      };
    },
    fastChecksCheckTransferClear(state) {
      state.fastChecksCheckTransfer = defaultState;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fastChecksCheckAddressRequest(state, _: IPayloadAction<TFastChecksCheckAddressOptions>) {
      state.fastChecksCheckAddress = {
        ...state.fastChecksCheckAddress,
        fetching: true,
      };
    },
    fastChecksCheckAddressSuccess(state, action: IPayloadAction<TFastChecksCheckAddress>) {
      state.fastChecksCheckAddress = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    fastChecksCheckAddressFailure(state, action) {
      state.fastChecksCheckAddress = {
        ...state.fastChecksCheckAddress,
        fetching: false,
        failure: action.payload,
      };
    },


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fastChecksDetailsRequest(state, _: IPayloadAction<TFastChecksDetailsOptions>) {
      state.fastChecksDetails = {
        ...state.fastChecksDetails,
        fetching: true,
      };
    },
    fastChecksDetailsSuccess(state, action: IPayloadAction<TFastChecksDetails>) {
      state.fastChecksDetails = {
        fetching: false,
        data: action.payload,
        failure: null,
        initialLoading: false,
      };
    },
    fastChecksDetailsFailure(state, action) {
      state.fastChecksDetails = {
        ...state.fastChecksDetails,
        fetching: false,
        failure: action.payload,
        initialLoading: false,
      };
    },
    fastChecksDetailsClear(state) {
      state.fastChecksDetails = defaultInitialState;
    },

    fastChecksDetailsEdit(state, action: IPayloadAction<Partial<TFastChecksDetails>>) {
      const { data } = state.fastChecksDetails;
      if (!data) {
        return;
      }
      const newData: TFastChecksDetails = { ...data, ...action.payload };

      state.fastChecksDetails = {
        ...state.fastChecksDetails,
        data: newData || {}
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fastChecksRisksRequest(state, _: IPayloadAction<TFastChecksChecksRiskOptions>) {
      state.fastChecksRisks = {
        ...state.fastChecksRisks,
        fetching: true,
      };
    },
    fastChecksRisksSuccess(state, action: IPayloadAction<TFastChecksRisksData>) {
      state.fastChecksRisks = {
        fetching: false,
        data: action.payload,
        failure: null,
        initialLoading: false,
      };
    },
    fastChecksRisksFailure(state, action) {
      state.fastChecksRisks = {
        ...state.fastChecksRisks,
        fetching: false,
        failure: action.payload,
        initialLoading: false,
      };
    },
    fastChecksRisksClear(state) {
      state.fastChecksRisks = defaultInitialState;
    },

    fastChecksRecheckRequest(state, _: IPayloadAction<{ id: string}>) {
      state.fastChecksRecheck = {
        ...state.fastChecksRecheck,
        fetching: true
      };
    },
    fastChecksRecheckSuccess(state, action) {
      state.fastChecksRecheck = {
        fetching: false,
        data: action.payload,
        failure: null,
        initialLoading: false,
      };
    },
    fastChecksRecheckFailure(state, action) {
      state.fastChecksRecheck = {
        ...state.fastChecksRecheck,
        fetching: false,
        failure: action.payload,
        initialLoading: false,
      };
    },

    getFastGroupsRequest(state, _) {
      state.fastFilterMobileGroups = {
        ...state.fastFilterMobileGroups,
        fetching: true,
        failure: null,
        data: [],
      };
    },
    getFastGroupsSuccess(state, action) {
      state.fastFilterMobileGroups = {
        ...state.fastFilterMobileGroups,
        data: action.payload,
        fetching: false,
        failure: null
      };
    },
    getFastGroupsFailure(state, action) {
      state.fastFilterMobileGroups = {
        ...state.fastFilterMobileGroups,
        data: [],
        fetching: false,
        failure: action.payload,
      };
    }
  },
});

export const {
  fastChecksRequest,
  fastChecksSuccess,
  fastChecksFailure,

  fastChecksCheckTransferRequest,
  fastChecksCheckTransferSuccess,
  fastChecksCheckTransferFailure,
  fastChecksCheckTransferClear,

  fastChecksCheckAddressRequest,
  fastChecksCheckAddressSuccess,
  fastChecksCheckAddressFailure,

  fastChecksDetailsRequest,
  fastChecksDetailsSuccess,
  fastChecksDetailsFailure,
  fastChecksDetailsClear,

  fastChecksRisksRequest,
  fastChecksRisksSuccess,
  fastChecksRisksFailure,
  fastChecksRisksClear,

  fastChecksRecheckRequest,
  fastChecksRecheckSuccess,
  fastChecksRecheckFailure,

  getFastGroupsRequest,
  getFastGroupsSuccess,
  getFastGroupsFailure,

} = fastChecksSlice.actions;

export default fastChecksSlice.reducer;
