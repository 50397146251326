import { IPaginationResponse, TPaginationOptions } from "../../api/types";
import { TNullable } from "../../config/types";
import {
  TInvoicesWallet,
  TInvoicesWalletAddBody, TInvoicesWalletEditBody,
  TInvoicesWalletsData,
  TInvoicesWalletsQuery
} from "../../api/branch/invoices";

import { TRequestDataExistsHandler, TRequestHandler } from "../rootInterface";

export enum EInvoicesStatus {
  awaiting = "awaiting",
  awaitingPartialPaid = "awaiting_partial_paid",
  completing = "completing",
  completed = "completed",
  expired = "expired",
  actionRequiredPartialPaid = "action_required_partial_paid",
  actionRequiredAMLFailed = "action_required_aml_failed",
  actionRequiredAMLCompleting = "action_required_aml_completing",
  canceled = "canceled",
}
export enum EInvoicesFilterKeys {
  status = "status",
  state = "state",
  name = "name",
  asset = "asset",
  date_from = "date_from",
  date_to = "date_to",
  search = "search"
}
export enum EInvoicesState {
  all = "all",
  active = "active",
  done = "done",
}
export type TInvoicesFilter = {
  [EInvoicesFilterKeys.status]?: EInvoicesStatus
  [EInvoicesFilterKeys.state]?: EInvoicesState
  [EInvoicesFilterKeys.name]?: string
  [EInvoicesFilterKeys.asset]?: string
  [EInvoicesFilterKeys.date_from]?: TNullable<string>
  [EInvoicesFilterKeys.date_to]?: TNullable<string>
  [EInvoicesFilterKeys.search]?: string
}
export type TInvoicesOptions = {
  filter?: TInvoicesFilter
  pagination?: TPaginationOptions
  infiniteScroll?: boolean;
}
export type TInvoice = {
  id: string
  status: EInvoicesStatus
  status_date: string
  amount: number
  asset: string
  wallet: string
  blockchain: string
  invoice_id: string
  client_id: string
  created_at: string
  time_left: string
}
export type TInvoicesData = IPaginationResponse<TInvoice>
export type TInvoicesState = TRequestHandler<TInvoicesData>


export type TInvoicesWalletsOptions = {
  query?: TInvoicesWalletsQuery
  infiniteScroll?: boolean
}
export type TInvoicesWalletsState = TRequestDataExistsHandler<TInvoicesWalletsData> & {
  initialLoading: boolean
}

export type TInvoicesWalletDetailsOptions = {
  id: string
}
export type TInvoicesWalletDetailsState = TRequestHandler<TInvoicesWallet>

export type TInvoicesWalletAddOptions = {
  body: TInvoicesWalletAddBody
  callOnSuccess?: () => void
}
export type TInvoicesWalletAddState = TRequestHandler<TInvoicesWallet>

export type TInvoicesWalletEditOptions = {
  id: string
  body: TInvoicesWalletEditBody
  callOnSuccess?: () => void
}
export type TInvoicesWalletEditState = TRequestHandler<Pick<TInvoicesWallet, "name">>

export type TInvoicesWalletDeleteOptions = {
  id: string
  callOnSuccess?: () => void
}
export type TInvoicesWalletDeleteState = TRequestHandler<unknown>

export type TInvoicesStoreState = {
  invoices: TInvoicesState
  invoicesWallets: TInvoicesWalletsState
  invoicesWalletDetails: TInvoicesWalletDetailsState
  invoicesWalletAdd: TInvoicesWalletAddState
  invoicesWalletEdit: TInvoicesWalletEditState
  invoicesWalletDelete: TInvoicesWalletDeleteState
};
