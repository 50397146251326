import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { getTransfersDetailPath,
  getWalletArchiveDetailsTransfersPath, paths,
  getMonitoringParamKey
} from "config/paths";
import { maxMobileMediaWidth } from "config/constants";

import Transfer from "components/Transfer";
import { useLayoutAppNameContext } from "components/Layout/hooks";
import TransferMobile from "components/TransferMobile";

import { transfersClear } from "store/transfers/reducers";
import { TTransfer } from "store/transfers/types";
import { transfersSelector } from "store/transfers/selectors";

export const CLIENTS_DETAILS_TRANSFERS_LIMIT = 5;

const MonitoredWalletsDetailsTransfersList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { routePath } = useLayoutAppNameContext();
  const { data } = useSelector(transfersSelector);

  const isMaxMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });

  const handleClickRedirect = () => {
    if (routePath === paths.TRANSFERS_MONITORED_WALLETS_INFORMATION) {
      history.push(getWalletArchiveDetailsTransfersPath(id));
    }
  };

  useEffect(
    () => () => {
      dispatch(transfersClear());
    },
    []
  );

  const handleRedirect = (transfer: TTransfer) =>
    `${getTransfersDetailPath(transfer.id)}${location.search || getMonitoringParamKey(id)}`;

  return (
    <div className="clients-details-transfers__list">
      <div>
        {data?.results.slice(0, CLIENTS_DETAILS_TRANSFERS_LIMIT).map(transfer =>
          isMaxMobile ? (
            <TransferMobile
              key={transfer.id}
              {...transfer}
              isContinuation={false}
              hasContinuation={false}
              redirectPath={handleRedirect(transfer)}
            />
          ) : (
            <Transfer
              key={transfer.id}
              {...transfer}
              isContinuation={false}
              hasContinuation={false}
              redirectPath={handleRedirect(transfer)}
            />
          )
        )}
      </div>
      <a className="clients-details-transfers__btn" onClick={handleClickRedirect}>
        {t("clients.showTransfersPage")}
      </a>
    </div>
  );
};

export default MonitoredWalletsDetailsTransfersList;
