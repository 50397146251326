import { FC } from "react";
import get from "lodash/get";

import DirectionArrow from "components/DirectionArrow";

import { useTransferContext } from "../../index";

import TransfersDirectionIconAssert from "./icons/TransfersDirectionIconAssert";

const TransfersDirectionIcon: FC<{ error: boolean }> = ({ error }) => {
  const clientTransaction = useTransferContext();
  const direction = get(clientTransaction, "direction");

  return <div className="transfer__icon">
    {error ? <TransfersDirectionIconAssert/> : direction && <DirectionArrow direction={direction}/>}
  </div>;
};

export default TransfersDirectionIcon;