import { FC } from "react";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import isFunction from "lodash/isFunction";


import { getTransfersDetailPath } from "../../config/paths";

import { TClientsTransactionProps, TransferContext } from "../Transfer";
import TransferCrypto from "../Transfer/components/TransferCrypto";
import TransferCounterparty from "../Transfer/components/TransferCounterparty";

import TransferMobileHead from "./components/TransferMobileHead";


const TransferMobile: FC<TClientsTransactionProps> = ({
  isContinuation,
  hasContinuation,
  onClick,
  redirectPath,
  ...rest
}) => {
  const history = useHistory();
  const handleClick = () => {
    if (isFunction(onClick)) onClick();
    history.push(redirectPath || getTransfersDetailPath(rest.id));
  };

  return (
    <TransferContext.Provider value={{ ...rest }}>
      <div
        onClick={handleClick}
        className={cn("transfer", {
          isContinuation,
          hasContinuation,
          [rest.direction]: rest.direction
        })}
        id={rest.id}
      >
        <TransferMobileHead/>
        <div className="transfer-content">
          <TransferCrypto />
          <TransferCounterparty />
        </div>
      </div>
    </TransferContext.Provider>
  );
};

export default TransferMobile;