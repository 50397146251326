import { paths, pathTitle } from "config/paths";
import { TLinks } from "config/types";

import ThunderboltIcon from "components/Icons/ThunderboltIcon";
import KeyIcon from "components/Icons/KeyIcon";
import UserIcon from "components/Icons/UserIcon";
import SafetyIcon from "components/Icons/SafetyIcon";
import RocketIcon from "components/Icons/RocketIcon";
import ToolIcon from "components/Icons/ToolIcon";
import InvoicesIcon from "components/Icons/InvoicesIcon";
import WalletIcon from "components/Icons/WalletIcon";

import { isInvoicesAvailable, isMonitoredWallets } from "utils/featuresCheck";

import i18n from "../../../../../i18n";

export const MOBILE_SETTINGS_LINKS: TLinks[] = [
  {
    id: "0",
    to: paths.INVOICES,
    icon: InvoicesIcon,
    title: pathTitle(paths.INVOICES),
    active: isInvoicesAvailable(),
    iconStroke: true,
  },
  { id: "1", to: paths.RISK_SETTINGS, icon: ThunderboltIcon, title: pathTitle(paths.RISK_SETTINGS), active: true },
  { id: "2", to: paths.API_KEYS, icon: KeyIcon, title: pathTitle(paths.API_KEYS), active: true },
  { id: "3", to: paths.API_KEYS, icon: ToolIcon, title: "Preferences", active: false },
  { id: "4", to: paths.SETTINGS_SAFETY, icon: UserIcon, title: i18n.t("naming.security&Entry"), active: true },

  {
    id: "5",
    to: paths.ACCESS_MANAGEMENT,
    icon: SafetyIcon,
    title: pathTitle(paths.ACCESS_MANAGEMENT),
    active: true,
  },
  { id: "6", to: paths.PLAN, icon: RocketIcon, title: pathTitle(paths.PLAN), active: true },
  {
    id: "7",
    to: paths.TRANSFERS_MONITORED_WALLETS,
    icon: WalletIcon,
    title: pathTitle(paths.TRANSFERS_MONITORED_WALLETS),
    active: isMonitoredWallets(),
  },
];
