import { FC, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import { CLASSES, ELEMENT_IDS } from "config/constants";

type TRouteProps = {
  component: FC;
}
const ModalRouteComponent: FC<TRouteProps> = ({ component: Component, ...props }) => {
  const location = useLocation();

  useEffect(() => {
    // Чтобы при обновлении страницы не срабатывала анимация модалки
    window.history.replaceState(location.pathname, '');
  }, []);

  useEffect(() => {
    const element = document.getElementById(ELEMENT_IDS.layoutPageWrapper);
    if(element && element.classList.contains(CLASSES.modalRouteLeft)) {
      element.classList.remove(CLASSES.modalRouteLeft);
    }
  }, []);

  return (
    <div className={CLASSES.modalRouteRight}>
      <Component {...props} />
    </div>
  );
};
export default ModalRouteComponent;