
import { OptionData } from "config/types";

import { ETransferType } from "store/transfers/types";

import i18n from "../../../../../../i18n";

export const transferTypeTranslations = (): Record<ETransferType, string> => ({
  [ETransferType.deposit]: i18n.t("common.deposit"),
  [ETransferType.withdrawal]: i18n.t("common.withdrawal"),
  [ETransferType.deposit_attempt]: i18n.t("common.deposit_attempt"),
  [ETransferType.withdrawal_attempt]: i18n.t("common.withdrawal_attempt"),
  [ETransferType.import_wallet]: i18n.t("common.import_wallet"),
});

export const getTransferTypeTranslation = (type: ETransferType): string => transferTypeTranslations()[type];
export const transferTypeOptions = (): OptionData[] => {
  const translations = transferTypeTranslations();

  return Object.keys(ETransferType).map(transferType => ({
    label: translations[transferType as ETransferType],
    value: transferType
  }));
};
