import round from "lodash/round";

import { formatAmount } from "./fractions";

export enum SignFormatAssetAmount {
  MIN = "−",
  PLS = "+"
}

export type FormatAssetAmount = {
  amount?: number | null
  asset: string | null
  sign?: SignFormatAssetAmount
  disableSign?: boolean
  normalizeFrom?: number

}
type FormatRepresentation = {
  amount: number
  asset: string
  sign: string|boolean
}
type FormatAssetAmountResult = JSX.Element|string

const _amountStringRepresentation = (amount: number): string =>
  amount >= 1000 ? formatAmount(Math.ceil(amount)) : formatAmount(amount);

const _formatStringRepresentation = ({
  amount, asset, sign }: FormatRepresentation
):FormatAssetAmountResult  => {
  const $amount = _amountStringRepresentation(amount);

  return <>{sign} {$amount} {asset}</>;
};

const _power = (value: number) => Math.floor(Math.log10(value));

const _amountNormalizedRepresentation = (amount: number): { exp: number, mant: number } => {
  const exp = _power(amount);
  const w = Math.pow(10, exp);

  return {
    exp,
    mant: round(amount / w, 2)
  };
};

const _formatNormalizedRepresentation = ({
  amount, asset, sign }: FormatRepresentation
):FormatAssetAmountResult  => {
  const { mant, exp } = _amountNormalizedRepresentation(amount);

  return <>{sign} {mant} ∙ 10<sup>{exp}</sup> {asset}</>;
};

const _getSing = (amount: number, sign?: string, disableSign?: boolean) => {
  if (disableSign) return false;
  if (sign) return sign;
  return amount < 0 ? SignFormatAssetAmount.MIN : SignFormatAssetAmount.PLS;
};

const formatAssetAmount = ({
  amount, asset, sign, disableSign, normalizeFrom
}: FormatAssetAmount): FormatAssetAmountResult => {
  if (!asset) return "―";
  if (amount === null || amount === undefined) {
    return "―";
  }
  if (+amount === 0) return `0 ${asset}`;

  const $sign = _getSing(amount, sign, disableSign);
  const absAmount = Math.abs(amount);
  const _normalizeFrom = normalizeFrom || 9_999_999_999;

  if (
    amount !== 0 &&
    // (absAmount > 9_999_999_999
    (absAmount > _normalizeFrom
    || absAmount < 0.000_001
    || (absAmount > -6 && absAmount < -1 && String(absAmount).length > 10))
  ) {
    return _formatNormalizedRepresentation({ amount: absAmount, asset, sign: $sign });
  }
  return _formatStringRepresentation({ amount: absAmount, asset, sign: $sign });
};

export default formatAssetAmount;
