import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import PreparedMobileDrawer from "components/PreparedMobileDrawer";
import { useLayoutAppNameContext } from "components/Layout/hooks";

import { getUserInfoSelector } from "store/user/selectors";

import { paths } from "../../../../../config/paths";

import { MOBILE_SETTINGS_LINKS } from "./constants";


export type TMobileTabDrawerProps = {
  visible: boolean
  close: () => void;
}
const MobileTabDrawer: FC<TMobileTabDrawerProps> = ({ visible, close  }) => {
  const { t } = useTranslation();
  const { logo } = useLayoutAppNameContext();
  const { data: userInfo } = useSelector(getUserInfoSelector);

  const filteredLinks = useMemo(() => MOBILE_SETTINGS_LINKS.filter(link => {
    if (link.to === paths.API_KEYS) return userInfo?.permissions.managing_api_keys;
    if (link.to === paths.ACCESS_MANAGEMENT) return userInfo?.permissions.managing_employees;
    return link.active;
  }), [userInfo?.permissions]);

  return (
    <PreparedMobileDrawer
      visible={visible}
      close={close}
      hideHeader={true}
      className="mobile-tab-menu__drawer"
    >
      <div className="mobile-tab-menu__drawer__content content">
        <div className="mobile-tab-menu__drawer__header">
          <div className="mobile-tab-menu__drawer__logo">
            {logo}
          </div>
          <div className="mobile-tab-menu__drawer__username">
            {userInfo?.username}
          </div>
        </div>
        <div className="mobile-tab-menu__drawer__links">
          {
            filteredLinks.filter(link => link.active).map(link => (
              <NavLink
                key={link.id}
                to={link.to}
                className="mobile-tab-menu__drawer__link"
                exact={true}
                onClick={close}
              >
                {link.icon && <link.icon className={cn({ stroke: link.iconStroke })} />}
                <span>{t(link.title)}</span>
              </NavLink>
            ))
          }
        </div>
      </div>
    </PreparedMobileDrawer>
  );
};

export default MobileTabDrawer;
