import { all, fork } from "redux-saga/effects";

import signInSaga from "store/auth/effects";
import userSaga from "store/user/effects";
import googleAnalyticsSaga from 'store/analytics/effects';
import modalsSaga from 'store/modals/effects';
import commonSaga from 'store/common/effects';
import apiKeysSaga from 'store/apiKeys/effects';
import amlSaga from 'store/aml/effects';
import transfersSaga from 'store/transfers/effects';
import basicsSaga from 'store/basics/effects';
import catalogSaga from 'store/catalog/effects';
import accountSaga from 'store/account/effects';
import alertsSaga from 'store/alerts/effects';
import clientsSaga from 'store/clients/effects';
import invoicesSaga from 'store/invoices/effects';
import fastChecksSaga from 'store/fastChecks/effects';
import explorerSaga from 'store/explorer/effects';
import riskModelsSaga from 'store/riskModels/effects';
import monitoredWalletsSaga from 'store/monitoredWallets/effects';

export default function* rootSaga(): Generator {
  yield all([
    fork(signInSaga),
    fork(userSaga),
    fork(googleAnalyticsSaga),
    fork(modalsSaga),
    fork(commonSaga),
    fork(apiKeysSaga),
    fork(amlSaga),
    fork(transfersSaga),
    fork(basicsSaga),
    fork(catalogSaga),
    fork(accountSaga),
    fork(alertsSaga),
    fork(clientsSaga),
    fork(invoicesSaga),
    fork(fastChecksSaga),
    fork(explorerSaga),
    fork(riskModelsSaga),
    fork(monitoredWalletsSaga),
  ]);
}
