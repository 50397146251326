import { createSlice } from "@reduxjs/toolkit";


import { TCommonStoreState } from "./types";

const initialState: TCommonStoreState = {
  mobileAdditionalMenu: false,
  headerDrawer: false
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    clearCommonState() {
      return initialState;
    },

    setMobileAdditionalMenu(state, action) {
      state.mobileAdditionalMenu = action.payload;
    },
    setHeaderDrawer(state, action) {
      state.headerDrawer = action.payload;
    }
  },
});

export const {
  clearCommonState,

  setMobileAdditionalMenu,
  setHeaderDrawer,
} = commonSlice.actions;

export default commonSlice.reducer;
