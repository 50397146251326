{
  /* eslint-disable max-len */
}
const DownloadIcon = ({ className }: { className?: string}): JSX.Element => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g clipPath="url(#clip0_21073_38872)">
      <path d="M0.5625 11.1555C0.711684 11.1555 0.854758 11.2153 0.960248 11.3216C1.06574 11.428 1.125 11.5723 1.125 11.7227V14.5586C1.125 14.8595 1.24353 15.148 1.4545 15.3608C1.66548 15.5735 1.95163 15.693 2.25 15.693H15.75C16.0484 15.693 16.3345 15.5735 16.5455 15.3608C16.7565 15.148 16.875 14.8595 16.875 14.5586V11.7227C16.875 11.5723 16.9343 11.428 17.0398 11.3216C17.1452 11.2153 17.2883 11.1555 17.4375 11.1555C17.5867 11.1555 17.7298 11.2153 17.8352 11.3216C17.9407 11.428 18 11.5723 18 11.7227V14.5586C18 15.1604 17.7629 15.7374 17.341 16.1629C16.919 16.5884 16.3467 16.8274 15.75 16.8274H2.25C1.65326 16.8274 1.08097 16.5884 0.65901 16.1629C0.237053 15.7374 0 15.1604 0 14.5586V11.7227C0 11.5723 0.0592632 11.428 0.164752 11.3216C0.270242 11.2153 0.413316 11.1555 0.5625 11.1555Z" fill="#748ADA"/>
      <path d="M8.60173 13.3718C8.65398 13.4247 8.71605 13.4666 8.78439 13.4952C8.85273 13.5237 8.92599 13.5385 8.99998 13.5385C9.07397 13.5385 9.14723 13.5237 9.21557 13.4952C9.2839 13.4666 9.34598 13.4247 9.39823 13.3718L12.7732 9.96871C12.8788 9.8622 12.9382 9.71776 12.9382 9.56714C12.9382 9.41652 12.8788 9.27207 12.7732 9.16557C12.6676 9.05907 12.5244 8.99923 12.375 8.99923C12.2256 8.99923 12.0823 9.05907 11.9767 9.16557L9.56248 11.6011V1.62651C9.56248 1.47609 9.50322 1.33182 9.39773 1.22545C9.29224 1.11908 9.14916 1.05933 8.99998 1.05933C8.85079 1.05933 8.70772 1.11908 8.60223 1.22545C8.49674 1.33182 8.43748 1.47609 8.43748 1.62651V11.6011L6.02323 9.16557C5.91761 9.05907 5.77435 8.99923 5.62498 8.99923C5.47561 8.99923 5.33235 9.05907 5.22673 9.16557C5.12111 9.27207 5.06177 9.41652 5.06177 9.56714C5.06177 9.71776 5.12111 9.8622 5.22673 9.96871L8.60173 13.3718Z" fill="#748ADA"/>
    </g>
    <defs>
      <clipPath id="clip0_21073_38872">
        <rect width="18" height="18" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);

export default DownloadIcon;
