import { createSlice } from "@reduxjs/toolkit";

import { TPaginationOptions } from "api/types";

import { defaultInitialState, defaultPaginationData, defaultState, PAGINATION_PAGE_LIMIT } from "../constants";
import { IPayloadAction } from "../rootInterface";

import {
  TTransferData,
  TTransferDetails,
  TTransferDetailsOptions,
  TTransferOptions,
  TTransferRegisterResponseData,
  TTransferRegisterAttemptOptions,
  TTransferRegisterOptions,
  TTransfersStoreState,
  TTransferBindTransactionOptions,
  TTransferRisksData,
  TTransferRisksOptions,
  TTransferRecheckExposure,
  TTransferRecheckExposureOptions,
  TTransferRecheckCounterparty,
  TTransferRecheckCounterpartyOptions, TTransferFindTransactionToBindOptions, TTransferFindTransactionToBindData
} from "./types";

const initialState: TTransfersStoreState = {
  transfersState: {
    ...defaultState,
    data: defaultPaginationData,
    fetching: false,
    lastElementFromPreviousPage: null,
    firstElementOfNextPage: null,
    initialLoading: true,
  },
  transfersClone: null,
  transfersDetails: defaultInitialState,
  transferRegister: defaultState,
  transferRegisterAttempt: defaultState,
  transferBindTransaction: defaultState,
  transferRecheckExposure: defaultState,
  transferRecheckCounterparty: defaultState,
  transferRisks: defaultInitialState,
  transferFindTransactionToBind: defaultState,
};

const transfersSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    transfersRequest(state, action: IPayloadAction<TTransferOptions>) {
      state.transfersState = {
        ...state.transfersState,
        fetching: true,
        infiniteScroll: action.payload?.infiniteScroll
      };
    },
    transfersSuccess(
      state,
      action: IPayloadAction<{
        data: TTransferData,
        infiniteScroll?: boolean,
        pagination?: TPaginationOptions
      }>
    ) {
      const { data, infiniteScroll, pagination } = action.payload;
      let newResults = data.results;
      const offset = pagination?.offset || 0;

      state.transfersState.fetching = false;
      state.transfersState.initialLoading = false;

      switch (pagination?.limit) {
      case PAGINATION_PAGE_LIMIT+2:
        newResults = data.results.slice(1, PAGINATION_PAGE_LIMIT+1);
        state.transfersState.lastElementFromPreviousPage = data.results[0];
        state.transfersState.data.next =
            data.count > offset + PAGINATION_PAGE_LIMIT ? offset + PAGINATION_PAGE_LIMIT : null;

        state.transfersState.firstElementOfNextPage = data.results.length === PAGINATION_PAGE_LIMIT+2
          ? data.results[data.results.length-1]
          : null;
        break;
      case PAGINATION_PAGE_LIMIT+1:
        newResults = data.results.slice(0, PAGINATION_PAGE_LIMIT);
        state.transfersState.lastElementFromPreviousPage = null;
        state.transfersState.data.next =
            data.count > offset + PAGINATION_PAGE_LIMIT ? offset + PAGINATION_PAGE_LIMIT : null;

        state.transfersState.firstElementOfNextPage = data.results.length === PAGINATION_PAGE_LIMIT+1
          ? data.results[data.results.length-1]
          : null;
        break;
      default:
        state.transfersState.lastElementFromPreviousPage = null;
        state.transfersState.firstElementOfNextPage = null;
        state.transfersState.data.next = data.next;
        break;
      }

      state.transfersState.data.results = infiniteScroll
        ? [...state.transfersState.data.results, ...newResults]
        : newResults;
      state.transfersState.data.count = data.count;
      state.transfersState.infiniteScroll = infiniteScroll;

      state.transfersClone = null;
    },
    transfersFailure(state, action) {
      state.transfersState = {
        ...state.transfersState,
        initialLoading: false,
        fetching: false,
        failure: action.payload,
      };
    },
    transfersClear(state) {
      state.transfersState = {
        ...defaultState,
        data: defaultPaginationData,
        lastElementFromPreviousPage: null,
        firstElementOfNextPage: null,
        initialLoading: true,
      };
    },
    transferClone(state) {
      state.transfersClone = state.transfersState;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transfersDetailsRequest(state, _: IPayloadAction<TTransferDetailsOptions>) {
      state.transfersDetails = {
        ...state.transfersDetails,
        fetching: true,
      };
    },
    transfersDetailsSuccess(state, action: IPayloadAction<TTransferDetails>) {
      state.transfersDetails = {
        fetching: false,
        data: action.payload,
        failure: null,
        initialLoading: false
      };
    },
    transfersDetailsFailure(state, action) {
      state.transfersDetails = {
        ...state.transfersDetails,
        fetching: false,
        failure: action.payload,
        initialLoading: false
      };
    },
    transfersDetailsClear(state) {
      state.transfersDetails = defaultInitialState;
    },
    transfersDetailsEdit(state, action: IPayloadAction<Partial<TTransferDetails>>) {
      const { data } = state.transfersDetails;
      if (!data) {
        return;
      }
      const newData: TTransferDetails = { ...data, ...action.payload };

      state.transfersDetails = {
        ...state.transfersDetails,
        data: newData || {}
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transferRegisterRequest(state, _: IPayloadAction<TTransferRegisterOptions>) {
      state.transferRegister = {
        ...state.transferRegister,
        fetching: true,
      };
    },
    transferRegisterSuccess(state, action: IPayloadAction<TTransferRegisterResponseData>) {
      state.transferRegister = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    transferRegisterFailure(state, action) {
      state.transferRegister = {
        ...state.transferRegister,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transferRegisterAttemptRequest(state, _: IPayloadAction<TTransferRegisterAttemptOptions>) {
      state.transferRegisterAttempt = {
        ...state.transferRegisterAttempt,
        fetching: true,
      };
    },
    transferRegisterAttemptSuccess(state, action: IPayloadAction<TTransferRegisterResponseData>) {
      state.transferRegisterAttempt = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    transferRegisterAttemptFailure(state, action) {
      state.transferRegisterAttempt = {
        ...state.transferRegisterAttempt,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transferBindTransactionRequest(state, _: IPayloadAction<TTransferBindTransactionOptions>) {
      state.transferBindTransaction = {
        ...state.transferBindTransaction,
        fetching: true,
      };
    },
    transferBindTransactionSuccess(state, action: IPayloadAction<TTransferRegisterResponseData>) {
      state.transferBindTransaction = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    transferBindTransactionFailure(state, action) {
      state.transferBindTransaction = {
        ...state.transferBindTransaction,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transferRecheckExposureRequest(state, _: IPayloadAction<TTransferRecheckExposureOptions>) {
      state.transferRecheckExposure = {
        ...state.transferRecheckExposure,
        fetching: true,
      };
    },
    transferRecheckExposureSuccess(state, action: IPayloadAction<TTransferRecheckExposure>) {
      state.transferRecheckExposure = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    transferRecheckExposureFailure(state, action) {
      state.transferRecheckExposure = {
        ...state.transferRecheckExposure,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transferRecheckCounterpartyRequest(state, _: IPayloadAction<TTransferRecheckCounterpartyOptions>) {
      state.transferRecheckCounterparty = {
        ...state.transferRecheckCounterparty,
        fetching: true,
      };
    },
    transferRecheckCounterpartySuccess(state, action: IPayloadAction<TTransferRecheckCounterparty>) {
      state.transferRecheckCounterparty = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    transferRecheckCounterpartyFailure(state, action) {
      state.transferRecheckCounterparty = {
        ...state.transferRecheckCounterparty,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transferRisksRequest(state, _: IPayloadAction<TTransferRisksOptions>) {
      state.transferRisks = {
        ...state.transferRisks,
        fetching: true,
      };
    },
    transferRisksSuccess(state, action: IPayloadAction<TTransferRisksData>) {
      state.transferRisks = {
        fetching: false,
        data: action.payload,
        failure: null,
        initialLoading: false
      };
    },
    transferRisksFailure(state, action) {
      state.transferRisks = {
        ...state.transferRisks,
        fetching: false,
        failure: action.payload,
        initialLoading: false
      };
    },
    transferRisksClear(state) {
      state.transferRisks = defaultInitialState;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transferFindTransactionToBindRequest(state, _: IPayloadAction<TTransferFindTransactionToBindOptions>) {
      state.transferFindTransactionToBind = {
        ...state.transferFindTransactionToBind,
        fetching: true,
      };
    },
    transferFindTransactionToBindSuccess(state, action: IPayloadAction<TTransferFindTransactionToBindData>) {
      state.transferFindTransactionToBind = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    transferFindTransactionToBindFailure(state, action) {
      state.transferFindTransactionToBind = {
        ...state.transferFindTransactionToBind,
        fetching: false,
        failure: action.payload,
      };
    },
    transferFindTransactionToBindClear(state) {
      state.transferFindTransactionToBind = defaultState;
    },
  },
});

export const {
  transfersRequest,
  transfersSuccess,
  transfersFailure,
  transfersClear,
  transferClone,

  transfersDetailsRequest,
  transfersDetailsSuccess,
  transfersDetailsFailure,
  transfersDetailsClear,
  transfersDetailsEdit,

  transferRegisterRequest,
  transferRegisterSuccess,
  transferRegisterFailure,

  transferRegisterAttemptRequest,
  transferRegisterAttemptSuccess,
  transferRegisterAttemptFailure,

  transferBindTransactionRequest,
  transferBindTransactionSuccess,
  transferBindTransactionFailure,

  transferRecheckExposureRequest,
  transferRecheckExposureSuccess,
  transferRecheckExposureFailure,

  transferRecheckCounterpartyRequest,
  transferRecheckCounterpartySuccess,
  transferRecheckCounterpartyFailure,

  transferRisksRequest,
  transferRisksSuccess,
  transferRisksFailure,
  transferRisksClear,

  transferFindTransactionToBindRequest,
  transferFindTransactionToBindSuccess,
  transferFindTransactionToBindFailure,
  transferFindTransactionToBindClear,
} = transfersSlice.actions;

export default transfersSlice.reducer;
