import { FC, SVGProps } from "react";

import { colors } from "config/constants";

{/* eslint-disable max-len */}
const BasketIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5.60728 1.67648H5.42871C5.52693 1.67648 5.60728 1.59612 5.60728 1.49791V1.67648H12.393V1.49791C12.393 1.59612 12.4734 1.67648 12.5716 1.67648H12.393V3.28362H14.0001V1.49791C14.0001 0.709961 13.3595 0.0693359 12.5716 0.0693359H5.42871C4.64077 0.0693359 4.00014 0.709961 4.00014 1.49791V3.28362H5.60728V1.67648ZM16.8573 3.28362H1.143C0.747907 3.28362 0.428711 3.60282 0.428711 3.99791V4.71219C0.428711 4.81041 0.509068 4.89077 0.607282 4.89077H1.9555L2.50684 16.5649C2.54255 17.326 3.17202 17.9265 3.93318 17.9265H14.0671C14.8305 17.9265 15.4577 17.3283 15.4934 16.5649L16.0448 4.89077H17.393C17.4912 4.89077 17.5716 4.81041 17.5716 4.71219V3.99791C17.5716 3.60282 17.2524 3.28362 16.8573 3.28362ZM13.8952 16.3193H4.10505L3.56487 4.89077H14.4354L13.8952 16.3193Z" fill={colors.red6}/>
  </svg>
);

export default BasketIcon;

