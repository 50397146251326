import { FC, SVGProps } from "react";

{/* eslint-disable max-len */}
const SafetyIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M12 1.5L3 4.5V13.5C3 18.4711 7.02891 22.5 12 22.5C16.9711 22.5 21 18.4711 21 13.5V4.5L12 1.5ZM19.3125 13.5C19.3125 17.5383 16.0383 20.8125 12 20.8125C7.96172 20.8125 4.6875 17.5383 4.6875 13.5V5.76562L12 3.1875L19.3125 5.76562V13.5Z" fill="#232325"/>
    <path d="M8.86906 11.1351C8.79086 11.0566 8.69791 10.9942 8.59555 10.9517C8.49318 10.9092 8.38343 10.8873 8.27258 10.8873C8.16173 10.8873 8.05197 10.9092 7.94961 10.9517C7.84725 10.9942 7.75429 11.0566 7.67609 11.1351C7.59753 11.2133 7.53519 11.3063 7.49266 11.4087C7.45012 11.511 7.42822 11.6208 7.42822 11.7316C7.42822 11.8425 7.45012 11.9522 7.49266 12.0546C7.53519 12.157 7.59753 12.2499 7.67609 12.3281L10.7089 15.3609L10.7581 15.4101C10.8321 15.4842 10.92 15.543 11.0167 15.5832C11.1134 15.6233 11.2171 15.6439 11.3218 15.6439C11.4265 15.6439 11.5302 15.6233 11.6269 15.5832C11.7236 15.543 11.8115 15.4842 11.8855 15.4101L17.1238 10.1719C17.1979 10.0979 17.2567 10.01 17.2968 9.91332C17.3369 9.81659 17.3576 9.71291 17.3576 9.6082C17.3576 9.50349 17.3369 9.39981 17.2968 9.30309C17.2567 9.20636 17.1979 9.1185 17.1238 9.04453L17.0582 8.97891C16.9842 8.9048 16.8963 8.846 16.7996 8.80588C16.7029 8.76577 16.5992 8.74512 16.4945 8.74512C16.3898 8.74512 16.2861 8.76577 16.1894 8.80588C16.0926 8.846 16.0048 8.9048 15.9308 8.97891L11.3206 13.5867L8.86906 11.1351Z" fill="#232325"/>
  </svg>
);

export default SafetyIcon;
