import { FC, SVGProps } from "react";


{/* eslint-disable max-len */}
const ClientsArchiveSidebarIcon:FC<SVGProps<SVGSVGElement>> = (props):JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_18655_9036)">
      <path d="M13.6411 14.4444H2.35902V10H1.33337V14.4444C1.33337 14.6802 1.44143 14.9063 1.63378 15.073C1.82612 15.2397 2.087 15.3333 2.35902 15.3333H13.6411C13.9131 15.3333 14.174 15.2397 14.3663 15.073C14.5587 14.9063 14.6667 14.6802 14.6667 14.4444V10H13.6411V14.4444Z" fill="#232325"/>
      <path d="M6 10.9998C6 11.0882 6.05268 11.173 6.14645 11.2355C6.24021 11.2981 6.36739 11.3332 6.5 11.3332H9.5C9.63261 11.3332 9.75979 11.2981 9.85355 11.2355C9.94732 11.173 10 11.0882 10 10.9998C10 10.9114 9.94732 10.8266 9.85355 10.7641C9.75979 10.7016 9.63261 10.6665 9.5 10.6665H6.5C6.36739 10.6665 6.24021 10.7016 6.14645 10.7641C6.05268 10.8266 6 10.9114 6 10.9998Z" fill="#232325"/>
      <path d="M5.66663 8.1665H1.66663V5.83317H3.66663C3.45022 5.52527 3.33029 5.14049 3.32663 4.74234C3.32663 4.74234 3.32663 4.68984 3.32663 4.6665H1.41663C1.21771 4.6665 1.02695 4.75869 0.886296 4.92279C0.745644 5.08688 0.666626 5.30944 0.666626 5.5415V9.33317H6.66663L5.66663 8.1665Z" fill="#232325"/>
      <path d="M14.5834 4.6665H12.6634V4.74234C12.6625 5.13908 12.5462 5.52371 12.3334 5.83317H14.3334V8.1665H10.3334L9.33337 9.33317H15.3334V5.5415C15.3334 5.30944 15.2544 5.08688 15.1137 4.92279C14.9731 4.75869 14.7823 4.6665 14.5834 4.6665Z" fill="#232325"/>
      <path d="M7.97888 8.66667L11.1303 5.46333C11.1875 5.42077 11.235 5.36617 11.2697 5.30322C11.3045 5.24027 11.3255 5.17044 11.3315 5.09845C11.3375 5.02647 11.3283 4.95401 11.3045 4.88598C11.2807 4.81794 11.2429 4.75592 11.1936 4.70411C11.1443 4.6523 11.0846 4.61191 11.0187 4.58567C10.9528 4.55944 10.8822 4.54796 10.8115 4.55203C10.7409 4.55609 10.672 4.5756 10.6094 4.60924C10.5468 4.64288 10.492 4.68985 10.4487 4.74699L8.47284 6.73962V0.504463C8.47284 0.370671 8.4208 0.242359 8.32816 0.147754C8.23553 0.0531486 8.10989 0 7.97888 0C7.84788 0 7.72223 0.0531486 7.6296 0.147754C7.53697 0.242359 7.48492 0.370671 7.48492 0.504463V6.7598L5.5091 4.76717C5.41609 4.67285 5.29019 4.62012 5.15911 4.6206C5.02804 4.62107 4.90251 4.6747 4.81015 4.76969C4.71779 4.86469 4.66617 4.99326 4.66663 5.12712C4.66709 5.26099 4.71961 5.38918 4.81262 5.48351L7.97888 8.66667Z" fill="#232325"/>
    </g>
    <defs>
      <clipPath id="clip0_18655_9036">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default ClientsArchiveSidebarIcon;
