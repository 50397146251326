import { all, call, put, takeLatest } from "redux-saga/effects";

import api from "api/api";
import {
  TAccountCheckPackHistoryData, TAccountEmployeeData, TAccountEmployeeDeleteData,
  TAccountEmployeesCreateData,
  TAccountEmployeesData,
  TAccountPlanData, TAccountSetAlertRiskLevelData
} from "api/branch/account";

import { IPayloadAction } from "../rootInterface";

import {
  checkPackHistoryFailure,
  checkPackHistoryRequest,
  checkPackHistorySuccess,
  employeeCreateFailure,
  employeeCreateRequest,
  employeeCreateSuccess,
  employeesUnshift,
  employeesFailure,
  employeesRequest,
  employeesSuccess,
  planFailure,
  planRequest,
  planSuccess,
  employeeRequest,
  employeeSuccess,
  employeeFailure,
  employeeEditRequest,
  employeeEditSuccess,
  employeeEditFailure,
  employeeResendInviteRequest,
  employeeResendInviteSuccess,
  employeeResendInviteFailure,
  employeeDeleteRequest,
  employeeDeleteSuccess,
  employeeDeleteFailure,
  setAlertRiskLevelRequest,
  setAlertRiskLevelSuccess, setAlertRiskLevelFailure,
} from "./reducers";
import {
  TCheckPackHistoryOptions,
  TEmployeeCreateOptions,
  TEmployeeDeleteOptions,
  TEmployeeEditOptions,
  TEmployeeOptions,
  TEmployeeResendInviteOptions, TSetAlertRiskLevelOptions,
} from "./types";

function* plan() {
  try {
    const data: TAccountPlanData = yield call(api.account.accountPlan);
    yield put(planSuccess(data));
  } catch (error) {
    yield put(planFailure(error));
  }
}

function* checkPackHistory(action: IPayloadAction<TCheckPackHistoryOptions>) {
  try {
    const data: TAccountCheckPackHistoryData =
      yield call(api.account.accountCheckPackHistory, action.payload.query);
    yield put(checkPackHistorySuccess(data));
  } catch (error) {
    yield put(checkPackHistoryFailure(error));
  }
}

function* employees() {
  try {
    const data: TAccountEmployeesData =
      yield call(api.account.accountEmployees);
    yield put(employeesSuccess(data));
  } catch (error) {
    yield put(employeesFailure(error));
  }
}

function* employeeCreate(action: IPayloadAction<TEmployeeCreateOptions>) {
  try {
    const data: TAccountEmployeesCreateData =
      yield call(api.account.accountEmployeesCreate, action.payload.body);
    yield put(employeeCreateSuccess(data));
    yield put(employeesUnshift(data));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess();
  } catch (error) {
    yield put(employeeCreateFailure(error));
  }
}

function* employee(action: IPayloadAction<TEmployeeOptions>) {
  const { id } = action.payload;
  try {
    const data: TAccountEmployeeData = yield call(api.account.accountEmployee, id);
    yield put(employeeSuccess(data));
  } catch (error) {
    yield put(employeeFailure(error));
  }
}

function* employeeEdit(action: IPayloadAction<TEmployeeEditOptions>) {
  const { id, body } = action.payload;
  try {
    const data: TAccountEmployeeData = yield call(api.account.accountEmployeeEdit, id, body);
    yield put(employeeEditSuccess(data));
    yield put(employeeSuccess(data));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess();
  } catch (error) {
    yield put(employeeEditFailure(error));
  }
}

function* employeeResendInvite(action: IPayloadAction<TEmployeeResendInviteOptions>) {
  const { id } = action.payload;
  try {
    const data: TAccountEmployeeData = yield call(api.account.accountEmployeeResendInvite, id);
    yield put(employeeResendInviteSuccess(data));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess();
  } catch (error) {
    yield put(employeeResendInviteFailure(error));
  }
}

function* employeeDelete(action: IPayloadAction<TEmployeeDeleteOptions>) {
  const { id } = action.payload;
  try {
    const data: TAccountEmployeeDeleteData = yield call(api.account.accountEmployeeDelete, id);
    yield put(employeeDeleteSuccess(data));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess();
  } catch (error) {
    yield put(employeeDeleteFailure(error));
  }
}

function* setAlertRiskLevel(action: IPayloadAction<TSetAlertRiskLevelOptions>) {
  try {
    const data: TAccountSetAlertRiskLevelData = yield call(api.account.setAlertRiskLevel, action.payload.body);
    yield put(setAlertRiskLevelSuccess(data));
  } catch (error) {
    yield put(setAlertRiskLevelFailure(error));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(planRequest.type, plan),
    takeLatest(checkPackHistoryRequest.type, checkPackHistory),
    takeLatest(employeesRequest.type, employees),
    takeLatest(employeeCreateRequest.type, employeeCreate),
    takeLatest(employeeRequest.type, employee),
    takeLatest(employeeEditRequest.type, employeeEdit),
    takeLatest(employeeResendInviteRequest.type, employeeResendInvite),
    takeLatest(employeeDeleteRequest.type, employeeDelete),
    takeLatest(setAlertRiskLevelRequest.type, setAlertRiskLevel),
  ]);
}

export default Saga;
