import { createSelector } from "@reduxjs/toolkit";

import { IApplicationState, WSelector } from "../rootInterface";

import { EModals, ModalState } from "./types";

const selectState = (state: IApplicationState) => state.modals;

export function modalStateSelector<T> (modal: EModals): WSelector<ModalState<T>|undefined> {
// export function modalStateSelector<T> (modal: EModals):
// WSelector< T extends void ? boolean|undefined : ModalState<T>|undefined > {
  return createSelector(selectState, modals => {
    const state = modals[modal];
    // if (!state?.data) {
    //   return state?.open;
    // }
    return state;
  });
}

