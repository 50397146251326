import React, { FC, SVGProps } from "react";

import { paths } from "./paths";

export type OptionData = {
  label: any
  value: any
  space?: any
  disabled?: boolean
}

export type TNullable<T> = T | null;

export interface IIconsProps {
  className?: string;
  onClick?: () => void;
  fill?: string;
}

export type TFilterData = {
  value: string;
  label: string;
};

export type TRefInput = {
  refinput?: React.MutableRefObject<any>;
};

export type TChildren = React.ReactChild | React.ReactChild[] | React.FC;

export enum EValidateNames {
  email = "email",
  phone = "phone",
}

export type TLinks = {
  id: string;
  to: paths;
  icon?: FC<SVGProps<SVGSVGElement>>;
  iconStroke?: boolean
  title: string;
  active: boolean;
  sublinks?: TLinks[]
};

export interface FormikFieldProps {
  name: string
  validate?: (value: any) => undefined | string | Promise<any>
  fast?: boolean
}

export enum ECurrency {
  usd = "USD",
  rub = "RUB",
  usdt = "USDT",
  eur = "EUR",
  gbp = "GBP",
  try = "TRY",
  jpy = "JPY",
  aud = "AUD",
  uah = "UAH",
  thb = "THB",
}

export const CurrenciesSymbols = {
  [ECurrency.rub]: "₽",
  [ECurrency.usd]: "$",
  [ECurrency.usdt]: "$",
  [ECurrency.eur]: "€",
  [ECurrency.gbp]: "£",
  [ECurrency.try]: "₺",
  [ECurrency.jpy]: "¥",
  [ECurrency.aud]: "A$",
  [ECurrency.uah]: "₴",
  [ECurrency.thb]: "฿",
};

export enum EQuery {
  id = "id",
  token = "token",
  page = "page",
  showMode = "showMode",
  view = "view",
}

