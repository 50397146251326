import { FC, useCallback } from "react";
import { Redirect, RouteComponentProps, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { paths } from "config/paths";
import { publicRoutes } from "config/routes";

import useQuery from "hooks/useQuery";

import { isAuthorizedSelector } from "store/auth/selectors";

import { EStorageKeys } from "utils/storageHeplers";
import { getOnlyUtm } from "utils/getOnlyUtm";

import { getUserInfoDataSelector } from "../../../../store/user/selectors";
import PageNoteFound from "../../../../pages/pageNotFound";

import { IRouteProps } from "../../types";

import LoadingPage from "../LoadingPage";
import Route from "../Route";

const PrivateRoute: FC<IRouteProps> = ({ component: Component, ...rest }) => {
  const isAuthorized = useSelector(isAuthorizedSelector);
  const token = localStorage.getItem(EStorageKeys.TOKEN);
  const location = useLocation();
  const query = useQuery();
  const userInfo = useSelector(getUserInfoDataSelector);

  const handleRedirect = () => {
    if (location.pathname && publicRoutes.find(route => route.path && location.pathname.includes(route.path))) {
      return null;
    }
    return <Redirect to={paths.SIGN_IN + getOnlyUtm(query)} />;
  };

  const render = useCallback((routeProps: RouteComponentProps) =>
    isAuthorized
      ? <Component {...routeProps} />
      : !token ? handleRedirect() : <LoadingPage/>, [isAuthorized, token, location, query]);


  if (
    (rest.path === paths.ACCESS_MANAGEMENT && !userInfo?.permissions.managing_employees)
    || (rest.path === paths.API_KEYS && !userInfo?.permissions.managing_api_keys)
  ) {
    return <Route {...rest} component={PageNoteFound} exact={true}/>;
  }

  return <Route {...rest} component={render} exact={true}/>;
};

export default PrivateRoute;
