import { createSelector } from "@reduxjs/toolkit";

import { IApplicationState } from "../rootInterface";

const selectState = (state: IApplicationState) => state.fastChecks;

export const fastChecksSelector = createSelector(selectState, fastChecks => fastChecks?.fastChecks);
export const fastChecksCheckTransferSelector =
  createSelector(selectState, fastChecks => fastChecks?.fastChecksCheckTransfer);
export const fastChecksCheckAddressSelector =
  createSelector(selectState, fastChecks => fastChecks?.fastChecksCheckAddress);
export const fastChecksDetailsSelector =
  createSelector(selectState, fastChecks => fastChecks?.fastChecksDetails);
export const fastChecksRisksSelector =
  createSelector(selectState, fastChecks => fastChecks?.fastChecksRisks);
export const fastChecksRecheckSelector =
  createSelector(selectState, fastChecks => fastChecks?.fastChecksRecheck);

export const fastCheckFilterMobile =
  createSelector(selectState, fastChecks => fastChecks?.fastFilterMobileGroups);
