import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import cn from "classnames";
import { NavLink } from "react-router-dom";

import { TLinks } from "config/types";
import { paths, pathTitle } from "config/paths";

import { TBNDropdownChildren } from "components/BNDropdown/types";
import BNDropdown from "components/BNDropdown";
import { useLayoutAppNameContext } from "components/Layout/hooks";
import ArrowSmallIcon from "components/Icons/ArrowSmallIcon";

import { getUserInfoDataSelector } from "store/user/selectors";


const HeaderAdditional: FC = () => {
  const { t } = useTranslation();
  const data = useSelector(getUserInfoDataSelector);
  const { logout } = useLayoutAppNameContext();
  const userInfo = useSelector(getUserInfoDataSelector);

  const settingsLinks: TLinks[] = useMemo(() => ([
    { id: "1", to: paths.SETTINGS_SAFETY, title: pathTitle(paths.SETTINGS_SAFETY), active: true  },
    { id: "2", to: paths.PLAN, title: pathTitle(paths.PLAN), active: true  },
    { id: "3", to: paths.ACCESS_MANAGEMENT,
      title: pathTitle(paths.ACCESS_MANAGEMENT), active: !!userInfo?.permissions.managing_employees  },
  ]), []);

  const overlay = useCallback(({ setActive }: TBNDropdownChildren) => (
    <>
      {
        settingsLinks.filter(link => link.active).map(link => (
          <NavLink
            key={link.id}
            to={link.to}
            className={cn("dropdown-item")}
            activeClassName="active"
            exact={true}
            onClick={() => setActive(false)}
          >
            <span>{t(link.title)}</span>
          </NavLink>
        ))
      }
      <div className="dropdown-item exit" onClick={logout}>
        {t('naming.logout')}
      </div>
    </>
  ), []);

  return (
    <BNDropdown
      overlay={overlay}
      overlayStyle={{ right: 0 }}
      className="other-navigation new-header__additional-menu"
    >
      {({ active }) => (
        <>
          <div className="other-navigation__text">{data?.username}</div>
          <div
            className={cn("other-navigation_icon", {
              active,
            })}
          >
            <ArrowSmallIcon/>
          </div>
        </>
      )}
    </BNDropdown>
  );
};

export default HeaderAdditional;
