import { AxiosResponse } from "axios";
import { put, takeLatest, all, call } from "redux-saga/effects";
import request from "api";

import { IPayloadAction } from "store/rootInterface";
import {
  TAuthAction,
  TAuthRequest, TRegistrationInfoOptions, TResendCodeRequest, TSignUpOptions,
} from "store/auth/types";
import { getUserInfoRequest } from "store/user/reducers";
import { clearModalsState } from "store/modals/reducers";

import { showSuccess } from "utils/notifications";
import { getRecaptchaToken } from "utils/recaptchaExecute";
import { setStorage, EStorageKeys, removeStorageItem } from "utils/storageHeplers";

import i18n from "../../i18n";
import { isGreetingAvailable } from "../../utils/featuresCheck";

import { INITIAL_REQUESTS, USER_LOGOUT } from "./actions";
import {
  getSignInSuccess,
  getSignInRequest,
  getSignInFailure,
  logout as logoutAction,
  clearAuthState,
  resendCodeRequest,
  resendCodeSuccess,
  getBlockingTime,
  resendCodeFailure,
  getCodeToken,
  registrationInfoRequest,
  registrationInfoSuccess,
  registrationInfoFailure,
  signUpRequest,
  signUpSuccess,
  signUpFailure,
} from "./reducers";

function* initRequests() {
  yield put(getUserInfoRequest({}));
}

function* userLogout() {
  removeStorageItem(EStorageKeys.TOKEN);

  yield put(logoutAction());
  yield put(clearAuthState());
  yield put(clearModalsState());
}

function* signIn(action: IPayloadAction<TAuthAction>) {

  try {
    const recaptcha: string = yield call(getRecaptchaToken);

    const response: AxiosResponse<TAuthRequest> = yield call(
      request.post,
      "/auth/email/sign-in/",
      { ...action.payload, recaptcha }
    );
    yield put(getSignInSuccess(response.data));
    setStorage(EStorageKeys.TOKEN, response.data);

    showSuccess(i18n.t("notification.signIn"));
    yield call(initRequests);
  } catch (e) {
    yield put(getSignInFailure(e));
  }
}

function* resendCode(action: IPayloadAction<TResendCodeRequest>) {
  const { code_token, isReset } = action.payload;
  try {
    const response: AxiosResponse = yield call(
      request.post,
      `/auth/${isReset ? "reset-password" : "phone/sign-up"}/resend-code/`,
      { code_token }
    );
    yield put(resendCodeSuccess(response.data));
    yield put(getBlockingTime(response.data.blocking_time));
    yield put(getCodeToken(response.data.code_token));
    localStorage.setItem(EStorageKeys.TIMER, response.data.blocking_time);
  } catch (e) {
    yield put(resendCodeFailure(e));
  }
}

function* registrationInfo(action: IPayloadAction<TRegistrationInfoOptions>) {
  try {
    const response: AxiosResponse = yield call(
      request.get, `/auth/registration-info/`, { params: { token: action.payload.token } }
    );
    yield put(registrationInfoSuccess(response.data));
  } catch (e) {
    yield put(registrationInfoFailure(e));
  }
}

function* signUp(action: IPayloadAction<TSignUpOptions>) {
  try {
    const response: AxiosResponse = yield call(request.post, "/auth/sign-up/", action.payload.data);
    yield put(signUpSuccess(response.data));
    setStorage(EStorageKeys.TOKEN, response.data);

    showSuccess(i18n.t("notification.signIn"));
    if (isGreetingAvailable()) localStorage.setItem(EStorageKeys.GREETING, 'true');
    yield call(initRequests);
  } catch (e) {
    yield put(signUpFailure(e));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(INITIAL_REQUESTS, initRequests),
    takeLatest(USER_LOGOUT, userLogout),
    takeLatest(getSignInRequest.type, signIn),
    takeLatest(resendCodeRequest.type, resendCode),
    takeLatest(registrationInfoRequest.type, registrationInfo),
    takeLatest(signUpRequest.type, signUp),
  ]);
}

export default Saga;
