import { EInvoicesStatus, TInvoice, TInvoicesData } from "./types";

export const INVOICES_RESULTS: TInvoice[] = [
  {
    id: "asdadasdasdasdsa",
    status: EInvoicesStatus.awaiting,
    status_date: "2023-06-22T20:27:57.858250+03:00",
    amount: 100,
    asset: "USDT",
    wallet: "Metamask wallet",
    blockchain: "Ethereum (ETH)",
    invoice_id: "72у384у930dfd76asdasdas123asdkma",
    client_id: "72у384у930dfd76asdasdas123asdkma",
    created_at: "2023-06-22T20:27:57.858250+03:00",
    time_left: "2021-07-22T20:27:57.858250+03:00",
  },
  {
    id: "asdadasdasdasdsa1",
    status: EInvoicesStatus.awaitingPartialPaid,
    status_date: "2023-06-22T20:27:57.858250+03:00",
    amount: 100,
    asset: "USDT",
    wallet: "Metamask wallet",
    blockchain: "Ethereum (ETH)",
    invoice_id: "72у384у930dfd76asdasdas123asdkma",
    client_id: "72у384у930dfd76asdasdas123asdkma",
    created_at: "2023-06-22T20:27:57.858250+03:00",
    time_left: "2023-08-22T20:27:57.858250+03:00",
  },
  {
    id: "asdadasdasdasdsa2",
    status: EInvoicesStatus.completing,
    status_date: "2023-06-22T20:27:57.858250+03:00",
    amount: 100,
    asset: "USDT",
    wallet: "Metamask wallet",
    blockchain: "Ethereum (ETH)",
    invoice_id: "72у384у930dfd76asdasdas123asdkma",
    client_id: "72у384у930dfd76asdasdas123asdkma",
    created_at: "2023-06-22T20:27:57.858250+03:00",
    time_left: "2023-09-22T20:27:57.858250+03:00",
  },
  {
    id: "asdadasdasdasdsa3",
    status: EInvoicesStatus.completed,
    status_date: "2023-06-22T20:27:57.858250+03:00",
    amount: 100,
    asset: "USDT",
    wallet: "Metamask wallet",
    blockchain: "Ethereum (ETH)",
    invoice_id: "72у384у930dfd76asdasdas123asdkma",
    client_id: "72у384у930dfd76asdasdas123asdkma",
    created_at: "2023-06-22T20:27:57.858250+03:00",
    time_left: "2023-10-22T20:27:57.858250+03:00",
  },
  {
    id: "asdadasdasdasdsa4",
    status: EInvoicesStatus.expired,
    status_date: "2023-06-22T20:27:57.858250+03:00",
    amount: 100,
    asset: "USDT",
    wallet: "Metamask wallet",
    blockchain: "Ethereum (ETH)",
    invoice_id: "72у384у930dfd76asdasdas123asdkma",
    client_id: "72у384у930dfd76asdasdas123asdkma",
    created_at: "2023-06-22T20:27:57.858250+03:00",
    time_left: "2023-11-22T20:27:57.858250",
  },
  {
    id: "asdadasdasdasdsa6",
    status: EInvoicesStatus.actionRequiredPartialPaid,
    status_date: "2023-06-22T20:27:57.858250+03:00",
    amount: 100,
    asset: "USDT",
    wallet: "Metamask wallet",
    blockchain: "Ethereum (ETH)",
    invoice_id: "72у384у930dfd76asdasdas123asdkma",
    client_id: "72у384у930dfd76asdasdas123asdkma",
    created_at: "2023-06-22T20:27:57.858250+03:00",
    time_left: "2023-12-1T20:27:57.858250",
  },
  {
    id: "asdadasdasdasdsa7",
    status: EInvoicesStatus.actionRequiredAMLFailed,
    status_date: "2023-06-22T20:27:57.858250+03:00",
    amount: 100,
    asset: "USDT",
    wallet: "Metamask wallet",
    blockchain: "Ethereum (ETH)",
    invoice_id: "72у384у930dfd76asdasdas123asdkma",
    client_id: "72у384у930dfd76asdasdas123asdkma",
    created_at: "2023-06-22T20:27:57.858250+03:00",
    time_left: "2023-12-5T20:27:57.858250",
  },
  {
    id: "asdadasdasdasdsa8",
    status: EInvoicesStatus.actionRequiredAMLCompleting,
    status_date: "2023-06-22T20:27:57.858250+03:00",
    amount: 100,
    asset: "USDT",
    wallet: "Metamask wallet",
    blockchain: "Ethereum (ETH)",
    invoice_id: "72у384у930dfd76asdasdas123asdkma",
    client_id: "72у384у930dfd76asdasdas123asdkma",
    created_at: "2023-06-22T20:27:57.858250+03:00",
    time_left: "2023-12-5T8:27:57.858250",
  },
  {
    id: "asdadasdasdasdsa9",
    status: EInvoicesStatus.canceled,
    status_date: "2023-06-22T20:27:57.858250+03:00",
    amount: 100,
    asset: "USDT",
    wallet: "Metamask wallet",
    blockchain: "Ethereum (ETH)",
    invoice_id: "72у384у930dfd76asdasdas123asdkma",
    client_id: "72у384у930dfd76asdasdas123asdkma",
    created_at: "2023-06-22T20:27:57.858250+03:00",
    time_left: "2023-12-6T20:27:57.858250",
  },
];
export const INVOICES_DATA: TInvoicesData = {
  next: 2,
  previous: null,
  count: 100,
  pages	: 10,
  results: INVOICES_RESULTS,
};