import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import request from "../../api";
import { getRiskGradientColor } from "../../utils/colorGradient/RiskGradient";
import hexToRgb from "../../utils/hexToRgb";
import { showSuccessGreen } from "../../utils/notifications";
import i18n from "../../i18n";
import { TPaginationOptions } from "../../api/types";

import { IPayloadAction } from "../rootInterface";

import {
  clientArchiveFailure,
  clientArchiveRequest,
  clientArchiveSuccess,
  clientsArchiveFailure,
  clientsArchiveRemove,
  clientsArchiveRequest,
  clientsArchiveSuccess,
  clientsDetailsExposureFailure,
  clientsDetailsExposureRequest,
  clientsDetailsExposureSuccess,
  clientsDetailsFailure,
  clientsDetailsRequest,
  clientsDetailsSuccess,
  clientsFailure, clientsRemove,
  clientsRequest,
  clientsSuccess,
  clientUnArchiveFailure,
  clientUnArchiveRequest,
  clientUnArchiveSuccess
} from "./reducers";
import {
  TClientArchiveOptions,
  TClientsData,
  TClientsDetails,
  TClientsDetailsExposure, TClientsDetailsExposureOptions,
  TClientsDetailsOptions,
  TClientsFilter,
  TClientsOptions, TClientUnArchiveOptions
} from "./types";


const clientsParams = (
  filter: TClientsFilter|undefined,
  pagination: TPaginationOptions|undefined
) => {
  const params = new URLSearchParams();
  if (filter?.risk_level) params.set("risk_level", String(filter?.risk_level));
  if (filter?.date_from) params.set("last_transfer_from", String(filter?.date_from));
  if (filter?.date_to) params.set("last_transfer_to", String(filter?.date_to));
  if (filter?.search) params.set("search", String(filter?.search));
  if (pagination?.page) params.set("page", String(pagination?.page));
  if (pagination?.size) params.set("size", String(pagination?.size));

  return params;
};
function* clients(action: IPayloadAction<TClientsOptions>) {
  const params = clientsParams(action.payload.filter, action.payload.pagination);
  // params.set("archived", "false");
  try {
    const response: AxiosResponse<TClientsData> = yield call(request.get, "/clients/", { params });
    yield put(clientsSuccess({ data:  response.data, infiniteScroll: action.payload.infiniteScroll }));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess();
  } catch (error) {
    yield put(clientsFailure(error));
  }
}

function* clientsArchive(action: IPayloadAction<TClientsOptions>) {
  const params = clientsParams(action.payload.filter, action.payload.pagination);
  params.set("archived", "true");
  try {
    const response: AxiosResponse<TClientsData> = yield call(request.get, "/clients/", { params });
    yield put(clientsArchiveSuccess({ data:  response.data, infiniteScroll: action.payload.infiniteScroll }));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess();
  } catch (error) {
    yield put(clientsArchiveFailure(error));
  }
}

function* clientsDetails(action: IPayloadAction<TClientsDetailsOptions>) {
  try {
    const response: AxiosResponse<TClientsDetails> = yield call(request.get, `/clients/${action.payload.id}/`);
    yield put(clientsDetailsSuccess(response.data));
  } catch (error) {
    yield put(clientsDetailsFailure(error));
  }
}

function* clientsDetailsExposure(action: IPayloadAction<TClientsDetailsExposureOptions>) {
  try {
    const response: AxiosResponse<TClientsDetailsExposure> = yield call(
      request.get, `/clients/${action.payload.id}/exposure/`);

    const incomingExposure = [...response.data?.incoming_exposure || []].sort((a, b) => b.share - a.share );
    const outgoingExposure = [...response.data?.outgoing_exposure || []].sort((a, b) => b.share - a.share );
    yield put(clientsDetailsExposureSuccess({
      incoming_exposure: incomingExposure.map(item => {
        const color = getRiskGradientColor(item.risk_score);
        return ({
          ...item,
          color,
          rgb: color ? hexToRgb(color) : undefined
        });
      }),
      outgoing_exposure: outgoingExposure.map(item => {
        const color = getRiskGradientColor(item.risk_score);
        return ({
          ...item,
          color,
          rgb: color ? hexToRgb(color) : undefined
        });
      }),
    }));
  } catch (error) {
    yield put(clientsDetailsExposureFailure(error));
  }
}


function* clientArchive(action: IPayloadAction<TClientArchiveOptions>) {
  try {
    const response: AxiosResponse = yield call(request.post, `/clients/${action.payload.id}/archive/`);
    yield put(clientArchiveSuccess(response.data));
    yield put(clientsRemove({ id: action.payload.id }));
    showSuccessGreen({
      message: i18n.t("notification.successfully"),
      description: i18n.t("notification.clientAddedToArchive")
    });
  } catch (error) {
    yield put(clientArchiveFailure(error));
  }
}

function* clientUnArchive(action: IPayloadAction<TClientUnArchiveOptions>) {
  try {
    const response: AxiosResponse =
      yield call(request.post, `/clients/${action.payload.id}/unarchive/`);
    yield put(clientUnArchiveSuccess(response.data));
    yield put(clientsArchiveRemove({ id: action.payload.id }));
    showSuccessGreen({
      message: i18n.t("notification.successfully"),
      description: i18n.t("notification.clientRemovedFromArchive")
    });
  } catch (error) {
    yield put(clientUnArchiveFailure(error));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(clientsRequest.type, clients),
    takeLatest(clientsArchiveRequest.type, clientsArchive),
    takeLatest(clientsDetailsRequest.type, clientsDetails),
    takeLatest(clientsDetailsExposureRequest.type, clientsDetailsExposure),
    takeLatest(clientArchiveRequest.type, clientArchive),
    takeLatest(clientUnArchiveRequest.type, clientUnArchive),
  ]);
}

export default Saga;
