import axios, { AxiosRequestConfig, ResponseType } from "axios";

import { EStorageKeys, getStorageData, setStorage } from "../utils/storageHeplers";
import i18n from "../i18n";
import { paths } from "../config/paths";
import { checkIsAuthPaths } from "../utils/paths";
import { showError } from "../utils/notifications";
import { handleError } from "../utils/errorApiHandler";
import { ELanguages } from "../i18n/constants";

import { TToken } from "./index";

export type QueryParamsType = Record<string | number, any>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}
export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

const formAcceptLanguage = (lng:string | null) => {
  lng = lng || localStorage.getItem(EStorageKeys.I18NEXT_LNG);

  switch (lng) {
  case "ru":
    return ELanguages.ruRU;
  case "en":
    return ELanguages.enUS;
  default:
    return lng || navigator.language;
  }
};

const baseURL = process.env.REACT_APP_MAIN_API;

const instance = axios.create({ withCredentials: true, baseURL });

instance.interceptors.request.use(
  config => {
    const token = getStorageData(EStorageKeys.TOKEN) as TToken;

    if (token && token?.access_token) {
      config.headers.Authorization = `Bearer ${token.access_token}`;
    }

    config.headers["Accept-Language"] = formAcceptLanguage(i18n.language);
    return config;
  },
  error =>
    // Do something with request error
    Promise.reject(error)
);

instance.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;

    const token = getStorageData(EStorageKeys.TOKEN) as TToken;

    // response interceptor to refresh token on receiving token expired
    if (token?.refresh_token && error?.response?.status === 401 && !originalRequest?._retry) {
      originalRequest._retry = true;
      return axios
        .post(`${baseURL}/auth/update-token/`, {
          refresh: token.refresh_token,
        })
        .then(res => {
          if (res.status === 200) {
            setStorage(EStorageKeys.TOKEN, {
              ...res.data,
              refresh_token: token.refresh_token,
            });
            return axios(originalRequest);
          }
        })
        .catch(err => {
          if (err.response.status === 401) {
            localStorage.clear();
            return (window.location.href = paths.SIGN_IN);
          }
        });
    }

    if (!token?.refresh_token && error?.response?.status === 401 && !originalRequest?._retry) {
      localStorage.clear();
      if (!checkIsAuthPaths()) return (window.location.href = paths.SIGN_IN);
    }

    const ignoreShowError = originalRequest.url.includes("public");

    if (!ignoreShowError && (error?.response?.status === 404 || error?.response?.status === 500)) {
      showError(i18n.t("errors.somethingWentWrong"));
    } else if (typeof error.response === 'undefined') {
      showError(i18n.t("errors.networkError"));
    }

    const { parsedErrors, detail } = handleError(error);

    if (detail) showError(detail);
    return Promise.reject({
      ...error,
      parsedErrors,
    });
  }
);

const stringifyFormItem = (formItem: unknown): string => {
  if (typeof formItem === 'object' && formItem !== null) {
    return JSON.stringify(formItem);
  } else {
    return `${formItem}`;
  }
};

const createFormData = (input: Record<string, unknown>): FormData =>
  Object.keys(input || {}).reduce((formData, key) => {
    const property = input[key];
    const propertyContent: any[] = property instanceof Array ? property : [property];

    for (const formItem of propertyContent) {
      const isFileType = formItem instanceof Blob || formItem instanceof File;
      formData.append(key, isFileType ? formItem : stringifyFormItem(formItem));
    }

    return formData;
  }, new FormData());

const request = async <T = any>({
  path,
  type,
  query,
  body,
  format = "json",
  ...params
}: FullRequestParams): Promise<T> => {
  const responseFormat = format || undefined;

  if (type === ContentType.FormData && body && typeof body === 'object') {
    body = createFormData(body as Record<string, unknown>);
  }

  if (type === ContentType.Text && body && typeof body !== 'string') {
    body = JSON.stringify(body);
  }

  return instance
    .request({
      ...params,
      headers: {
        ...(params.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
    .then(response => response.data);
}
;

export default request;