import React, { useMemo }  from "react";
import { useSelector } from "react-redux";
import { Layout as AntLayout } from 'antd';
import { matchPath, useLocation } from "react-router-dom";

import { allRoutes } from "config/routes";
import { paths } from "config/paths";
import { ELEMENT_IDS } from "config/constants";

import { isAuthorizedSelector } from "store/auth/selectors";

import { ILayout } from "./types";
import LayoutProviders from "./components/LayoutProviders";
import PrivateContainer from "./components/PrivateContainer";

const Layout: React.FC<ILayout> = ({ children }) => {
  const location = useLocation();
  const isAuthorized = useSelector(isAuthorizedSelector);

  const routePath = useMemo(() => allRoutes.find(
    route => matchPath(location.pathname, route)?.isExact
  )?.path as paths, [location.pathname]);

  const content = useMemo(() => {
    if (isAuthorized) return <PrivateContainer>{ children }</PrivateContainer>;
    return children;
  }, [isAuthorized, routePath, location.pathname]);

  return (
    <LayoutProviders routePath={routePath}>
      <div id={ELEMENT_IDS.layoutPageWrapper}>
        <AntLayout className="layout-page">
          {content}
        </AntLayout>
      </div>
    </LayoutProviders>
  );
};

export default Layout;
