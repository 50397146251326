import { combineReducers } from "@reduxjs/toolkit";

import authSlice from "./auth/reducers";
import userSlice from "./user/reducers";
import modalsSlice from "./modals/reducers";
import commonSlice from "./common/reducers";
import apiKeysSlice from "./apiKeys/reducers";
import amlSlice from "./aml/reducers";
import transfersSlice from "./transfers/reducers";
import basicsSlice from "./basics/reducers";
import catalogSlice from "./catalog/reducers";
import accountSlice from "./account/reducers";
import alertsSlice from "./alerts/reducers";
import clientsSlice from "./clients/reducers";
import invoicesSlice from "./invoices/reducers";
import fastChecksSlice from "./fastChecks/reducers";
import explorerSlice from "./explorer/reducers";
import riskModelsSlice from "./riskModels/reducers";
import monitoredWalletsSlice from "./monitoredWallets/reducers";

export const rootReducer = combineReducers({
  auth: authSlice,
  user: userSlice,
  modals: modalsSlice,
  common: commonSlice,
  apiKeys: apiKeysSlice,
  aml: amlSlice,
  transfers: transfersSlice,
  basics: basicsSlice,
  catalog: catalogSlice,
  account: accountSlice,
  alerts: alertsSlice,
  clients: clientsSlice,
  invoices: invoicesSlice,
  fastChecks: fastChecksSlice,
  explorer: explorerSlice,
  riskModels: riskModelsSlice,
  monitoredWallets: monitoredWalletsSlice,
});
