import { notification } from "antd";
import { ArgsProps } from "antd/lib/notification";

import { colors } from "../config/constants";
import i18n from "../i18n";

export const showError = (text: string, description?: string): void => {
  notification.error({ message: text, description });
};

export const showSuccess = (text: string): void => {
  notification.success({ message: text });
};

export const showInfo = (text: string): void => {
  notification.destroy();
  notification.info({ message: text });
};

export const clearNotification = (): void => {
  notification.destroy();
};

export const showSuccessGreen = (args: ArgsProps): void => {
  notification.success({
    ...args,
    style: { backgroundColor: colors.green1, border: `1px solid ${colors.green3}` },
  });
};
export const showErrorRed = (args: ArgsProps): void => {
  notification.error({
    ...args,
    style: { backgroundColor: colors.red1, border: `1px solid ${colors.red2}` },
  });
};

export const notificationNoPermission = (): void => showErrorRed({
  message: i18n.t("validation.permissionRequired"),
  description: i18n.t("validation.permissionRequiredDescription")
});

export const notificationNoChecksAvailable = (): void => showError(
  i18n.t("notification.noChecksAvailable"),
  i18n.t("notification.noChecksAvailableDescription")
);
