import { createSelector } from "@reduxjs/toolkit";

import getGroupedTransactions from "utils/getGroupedTransactions";

import { IApplicationState } from "../rootInterface";

import { TTransfer } from "./types";

const selectState = (state: IApplicationState) => state.transfers;

export const transfersSelector = createSelector(selectState, transfers =>
  transfers.transfersClone ?? transfers.transfersState);
export const groupedTransfersSelector = createSelector(
  selectState,
  transfers => {
    const transfersData = transfers.transfersClone?.data || transfers.transfersState?.data;
    const transferResults = transfersData?.results || [];

    return getGroupedTransactions<TTransfer, keyof TTransfer>(
      transferResults,
      "occurred_at"
    );
  }
);

export const transfersDetailsSelector = createSelector(selectState, transfers => transfers.transfersDetails);
export const transfersDetailsCategoriesSelector =
  createSelector(selectState, transfers => transfers.transfersDetails?.data?.exposure?.indirect_exposure || []);
export const transferRegisterSelector = createSelector(selectState, transfers => transfers.transferRegister);
export const transferRegisterAttemptSelector = createSelector(
  selectState, transfers => transfers.transferRegisterAttempt);
export const transferBindTransactionSelector = createSelector(
  selectState, transfers => transfers.transferBindTransaction);
export const transferRecheckExposureSelector =
  createSelector(selectState, transfers => transfers.transferRecheckExposure);
export const transferRecheckCounterpartySelector =
  createSelector(selectState, transfers => transfers.transferRecheckCounterparty);
export const transferRisksSelector = createSelector(selectState, transfers => transfers.transferRisks);
export const transferFindTransactionToBindSelector = createSelector(selectState,
  transfers => transfers.transferFindTransactionToBind);
