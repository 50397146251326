import { FC } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import PageNoteFound from "pages/pageNotFound";

import { privateRoutes, publicRoutes } from "../../../../config/routes";

import PrivateRoute from "../PrivateRoute";
import ModalRouteComponent from "../ModalRouteComponent";

const Routes: FC = () => {
  const location = useLocation<{ routeModal: any}>();
  const routeModal = location.state && location.state.routeModal;

  return (
    <>
      <Switch location={routeModal || location}>
        {publicRoutes.filter(route => route.active).map(route => (
          <Route path={route.path} component={route.component} key={route.path} exact={route.exact}/>
        ))}
        {privateRoutes.filter(route => route.active).map(route => (
          <PrivateRoute path={route.path} key={route.path} component={route.component} exact={route.exact}/>
        ))}
        <PrivateRoute path={'*'} component={PageNoteFound} exact={true}/>
      </Switch>
      {routeModal && privateRoutes.filter(route => route.active && route.isModalRoute).map(route => (
        <PrivateRoute
          path={route.path}
          key={`modal_${route.path}`}
          component={() => <ModalRouteComponent component={route.component}/>}
        />
      ))}
    </>
  );
};

export default Routes;