import { createSelector } from "@reduxjs/toolkit";

import { IApplicationState } from "../rootInterface";

const selectState = (state: IApplicationState) => state.user;


export const getUserInfoSelector = createSelector(
  selectState,
  user => user.userInfo);
export const getUserInfoFetchingSelector = createSelector(
  selectState,
  user => user.userInfo.fetching
);
export const getUserInfoDataSelector = createSelector(
  selectState,
  user => user.userInfo.data
);
export const getUserInfoFailureSelector = createSelector(
  selectState,
  user => user.userInfo.failure
);

export const userInfoSettingsSelector = createSelector(selectState, user => user.userSettingsInfoState);
export const getEmailSelector = createSelector(selectState, user => user.userSettingsInfoState.data?.email);
export const checkEmailConfirmedSelector = createSelector(
  selectState,
  user => user.userSettingsInfoState.data?.email_confirmed
);
export const getUserInfoSettingsFetchingSelector = createSelector(
  selectState,
  user => user.userSettingsInfoState.fetching
);
export const dataBindEmailPhoneCodeSelector = createSelector(selectState,
  user => user.userBindEmailPhoneCodeState.data);
export const errorsBindEmailPhoneCodeSelector = createSelector(selectState,
  user => user.userBindEmailPhoneCodeState.failure);
export const showBindCodeSelector = createSelector(selectState, user => user.showBindCodeForm);
export const showBindPopupSelector = createSelector(selectState, user => user.showBindPopup);
export const errorsBindEmailPhoneSelector = createSelector(selectState, user => user.userBindEmailPhoneState?.failure);
export const loadingBindEmailPhoneSelector = createSelector(selectState,
  user => user.userBindEmailPhoneState?.fetching);
export const loadingUserChangePassword = createSelector(selectState, user => user.userChangePasswordState?.fetching);
export const errorsUserChangePassword = createSelector(selectState, user => user.userChangePasswordState?.failure);