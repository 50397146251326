import { FC, useEffect, useState } from "react";
import cn from "classnames";
import { Collapse } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";

import { paths } from "config/paths";

import { useHeaderDrawerContext } from "../../../NewHeader/components/NewHeaderDrawer/hooks";

import { useActiveNavLink } from "../../hooks";


const { Panel } = Collapse;

export enum ECollapseKeys {
  clients = "clients",
  invoices = "invoices",
}
export type TOpenKeys = ECollapseKeys[]|string[];

export type TSidebarCollapseProps = {
  title: string
  collapseKey: ECollapseKeys
  path: paths
  id: string
}

const SidebarCollapse: FC<TSidebarCollapseProps> = ({
  children,
  title,
  collapseKey,
  path,
  id,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const getOpenKey = () => location.pathname.includes(path) ? [collapseKey] : [];

  const [openKeys, setOpenKeys] =
    useState<TOpenKeys>([]);

  const isOpen = openKeys.includes(collapseKey);

  const isActive = useActiveNavLink();
  const { visible } = useHeaderDrawerContext();

  useEffect(() => {
    setOpenKeys(getOpenKey);
  }, [location.pathname, visible]);

  return (
    <li>
      <div
        className={cn("sidebar-nav-item", {
          disableHover: isOpen,
          active: !isOpen && isActive(path)
        })}
        id={id}
      >
        <Collapse
          ghost={true}
          activeKey={openKeys}
          expandIcon={() => <></>}
          onChange={() => {
            history.push(path);
          }}
        >
          <Panel
            key={collapseKey}
            header={
              <div className={cn("sidebar-nav-collapse-header", {
                open: isOpen,
                active: isOpen && isActive(path)
              })}
              >{t(title)}</div>
            }
          >
            {children}
          </Panel>
        </Collapse>
      </div>

    </li>
  );
};

export default SidebarCollapse;
