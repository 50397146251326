import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import request from "../../api";
import { IPaginationResponse } from "../../api/types";

import { IPayloadAction } from "../rootInterface";
import { PAGINATION_LARGE_LIMIT } from "../constants";

import {
  networksFailure,
  networksRequest,
  networksSuccess,
  tokensFailure,
  tokensRequest,
  tokensSuccess,
} from "./reducers";
import { TNetwork, TNetworksOptions, TToken, TTokensOptions } from "./types";

function* networks(action: IPayloadAction<TNetworksOptions>) {
  try {
    const response: AxiosResponse<IPaginationResponse<TNetwork>> = yield call(request.get, "/catalog/networks/", {
      params: { search: action.payload.search }
    });
    yield put(networksSuccess(response.data));
  } catch (error) {
    yield put(networksFailure(error));
  }
}

function* tokens(action: IPayloadAction<TTokensOptions>) {
  try {
    const response: AxiosResponse<IPaginationResponse<TToken>> = yield call(request.get, "/catalog/tokens/", {
      params: {
        size: action.payload.size ?? PAGINATION_LARGE_LIMIT,
        search: action.payload.search,
        network: action.payload.network,
        monitoring_support: true,
      }
    });
    yield put(tokensSuccess(response.data));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess(response.data.results);
  } catch (error) {
    yield put(tokensFailure(error));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(networksRequest.type, networks),
    takeLatest(tokensRequest.type, tokens),
  ]);
}

export default Saga;
