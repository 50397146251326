import { FC, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import { setMobileAdditionalMenu } from "store/common/reducers";
import { mobileAdditionalMenuSelector } from "store/common/selectors";


import { useActiveNavLink } from "../Sidebar/hooks";

import { MOBILE_MENU } from "./container";
import MobileTabAdditionalMenu from "./components/MobileTabAdditionalMenu";

export
const MobileTabMenu: FC = () => {
  const additionalMenu = useSelector(mobileAdditionalMenuSelector);
  const dispatch = useDispatch();
  const isActive = useActiveNavLink();

  const [shouldDelayRemoveClass, setShouldDelayRemoveClass] = useState(additionalMenu);

  useEffect(() => {
    if (!additionalMenu) {
      const timer = setTimeout(() => {
        setShouldDelayRemoveClass(false);
      }, 300);
      return () => clearTimeout(timer);
    } else {
      setShouldDelayRemoveClass(true);
    }
  }, [additionalMenu]);

  return <div className={cn("mobile-tab-menu", {
    "elevated": shouldDelayRemoveClass
  })}>
    {MOBILE_MENU.map(link => (
      <div className="mobile-tab-menu__item" key={link.id}>
        <NavLink
          to={link.to}
          exact={true}
          className="mobile-tab-menu__link"
          activeClassName={cn("", { "active": !additionalMenu })}
          isActive={() => isActive(link.to)}
          onClick={() => dispatch(setMobileAdditionalMenu(false))}
        >
          {link.icon && <link.icon className={cn({ stroke: link.iconStroke })} />}
        </NavLink>
      </div>)
    )}
    <MobileTabAdditionalMenu/>
  </div>;
};

export default MobileTabMenu;
