import React, { useMemo, useRef, useState } from "react";
import cn from "classnames";
import isBoolean from "lodash/isBoolean";
import isFunction from "lodash/isFunction";

import useOnClickOutside from "hooks/useOnClickOutside";

import { TBNDropdownChildren, TBNDropdownProps } from "./types";
import BNDropdownItem from "./components/BNDropdownItem";
import { BNDropdownContext } from "./hooks";

const BNDropdown: TBNDropdownProps = ({
  children,
  overlay,
  active: $active,
  setActive: $setActive,
  className,
  style,
  overlayStyle,
  overlayClass,
  activateOnClickOutside= true,
  onClick,
  outsideClickIgnore= []
}) => {
  const [baseActive, setBaseActive] = useState<boolean>(false);
  const isActive = isBoolean($active) ? $active : baseActive;
  const setActive = $setActive ? $setActive : setBaseActive;

  const clickedRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useOnClickOutside({
    onClickOutside: () => setActive(false),
    anchors: [
      wrapperRef,
      clickedRef,
      ...outsideClickIgnore || [],
    ],
    ignore: !activateOnClickOutside,
  });

  const callBack = (): TBNDropdownChildren => ({
    active: isActive, setActive
  });

  const shiftRight = useMemo(() => {
    if (!wrapperRef.current || !clickedRef.current) return;

    if (wrapperRef.current.offsetWidth + 25 >= window.innerWidth) {
      return;
    }

    if (window.innerWidth - clickedRef.current?.getBoundingClientRect().right <=
      window.innerWidth - wrapperRef.current.offsetWidth ) {
      return window.innerWidth - clickedRef.current?.getBoundingClientRect().right;
    }
  }, [window.innerWidth, wrapperRef.current, clickedRef.current]);

  const shiftLeft = useMemo(() => {
    if (!wrapperRef.current || !clickedRef.current) return;
    if (wrapperRef.current.offsetWidth + 25 >= window.innerWidth) {
      return;
    }

    if (window.innerWidth - clickedRef.current?.getBoundingClientRect().left >= wrapperRef.current.offsetWidth) {
      return clickedRef.current?.getBoundingClientRect().left;
    }
  }, [window.innerWidth, wrapperRef.current, clickedRef.current]);

  return (
    <BNDropdownContext.Provider value={{ active: $active, setActive }}>
      <nav className={cn("bn-dropdown-navigation", className)}
        style={style}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          if(isFunction(onClick)) onClick({ active: !!$active, setActive });
        }}
      >
        <div
          className="bn-dropdown__appearance"
          onClick={() => setActive(!isActive)}
          ref={clickedRef}
        >
          {children(callBack())}
        </div>
        <div
          ref={wrapperRef}
          style={overlayStyle || {
            top: (clickedRef.current?.getBoundingClientRect().top || 0) + 16,
            right: shiftRight ? shiftRight : (shiftLeft ? "unset" : "unset"),
            left: shiftRight ? "unset" : (shiftLeft || "50%"),
            transform: (shiftRight || shiftLeft) ? 'unset' : "translateX(-50%)",
          }}
          className={cn("bn-dropdown__overlay", overlayClass, {
            active: isActive,
          })}
        >
          {overlay(callBack())}
        </div>
      </nav>
    </BNDropdownContext.Provider>
  );
};

BNDropdown.Item = BNDropdownItem;

export default BNDropdown;
