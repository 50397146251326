import { FC } from "react";
import MediaQuery, { useMediaQuery } from "react-responsive";
import cn from "classnames";
import { useLocation } from "react-router-dom";

import { CLASSES, maxTableMediaWidth, minShowSidebarWidth } from "config/constants";

import HeaderAdditional from "./components/HeaderAdditional";
import NewHeaderDrawer from "./components/NewHeaderDrawer";
import HeaderLeft from "./components/HeaderLeft";

const NewHeader: FC = () => {
  const notTable = useMediaQuery({ minWidth: maxTableMediaWidth });
  const location = useLocation<{routeModal: unknown}>();
  const routeModal = location.state && location.state.routeModal;

  return (
    <div className={cn("new-header__wrapper", {
      [CLASSES.modalRouteRight]: !!routeModal
    })}>
      <MediaQuery maxWidth={minShowSidebarWidth - 1}>
        <NewHeaderDrawer/>
      </MediaQuery>

      <div className={cn("new-header", { container: notTable })}>
        <HeaderLeft/>
        <div className="new-header__right">
          <HeaderAdditional/>
        </div>
      </div>
    </div>
  );
};

export default NewHeader;
