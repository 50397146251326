import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Dropdown } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useTranslation } from "react-i18next";

import PlusIcon from "components/Icons/PlusIcon";

import { openModal } from "store/modals/actions";
import { EModals } from "store/modals/types";

import { dropdownAlign } from "../..";

const MobileHeaderCheck: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const items = useMemo(() => {
    const res: ItemType[] = [
      {
        key: EModals.fastCheckAddress,
        onClick: () => {
          dispatch(openModal({
            modal:EModals.fastCheckAddress
          }));
        },
        label: t("fastChecks.addressCheck")
      },
      {
        key: EModals.fastCheckTransfer,
        onClick: () => {
          dispatch(openModal({
            modal:EModals.fastCheckTransfer,
          }));
        },
        label: t("fastChecks.transferCheck")
      },
    ];
    return res;
  }, []);

  return <div className="header-mobile__left__check">
    <Dropdown
      placement="bottomLeft"
      align={dropdownAlign}
      menu={{
        className: "bn-dropdown__menu",
        items,
      }}
      trigger={["click"]}
    >
      <PlusIcon className="plus-icon"/>
    </Dropdown>
  </div>;
};

export default MobileHeaderCheck;