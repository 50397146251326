import { FC } from "react";
import { useHistory } from "react-router";

import { paths } from "config/paths";

import ClarityArchiveLineIcon from "components/Icons/ClarityArchiveLineIcon";

const MobileHeaderClientArchive: FC = () => {
  const history = useHistory();
  
  const handleClick = () => {
    history.push(paths.CLIENTS_ARCHIVE);
  };
  
  return <ClarityArchiveLineIcon className="header-mobile__archive" onClick={handleClick}/>;
};

export default MobileHeaderClientArchive;