import { THistoricalImport } from "store/monitoredWallets/types";

import compressText from "utils/compressText";

export const formIDTitle = (id: string|undefined|null): string => ((!id) ? "-" : id);

export const getNameOrAddress = (data: THistoricalImport, walletAdress?: string | null): string | undefined => {
  if (!data) {
    return walletAdress ? compressText(walletAdress) : undefined;
  }

  if (data.name) {
    return data.name || compressText(data.address);
  }
  return compressText(data.address);
};

export const formCheckIDTitle = (id: string|undefined|null): string => (!id ? '-' : id);
