import { createSlice } from "@reduxjs/toolkit";

import { defaultState } from "../constants";
import { IPayloadAction } from "../rootInterface";

import { TAuthStoreState, TRegistrationInfoOptions, TSignUpOptions } from "./types";

const initialState: TAuthStoreState = {
  signInState: defaultState,
  resendCodeState: defaultState,
  registrationInfo: defaultState,
  signUpState: defaultState,
  isAuthorized: false,
  blockingTime: null,
  codeToken: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getSignInRequest(state, action) {
      state.signInState = {
        ...action.payload,
        fetching: true,
        data: null,
        failure: null,
      };
    },
    getSignInSuccess(state, action) {
      state.signInState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
      state.isAuthorized = true;
    },
    getSignInFailure(state, action) {
      state.signInState = {
        fetching: false,
        data: null,
        failure: action.payload,
      };
    },

    resendCodeRequest(state, action) {
      state.resendCodeState = {
        ...action.payload,
        fetching: true,
        data: null,
        failure: null,
      };
    },
    resendCodeSuccess(state, action) {
      state.resendCodeState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    resendCodeFailure(state, action) {
      state.resendCodeState = {
        fetching: false,
        data: null,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    registrationInfoRequest(state, _: IPayloadAction<TRegistrationInfoOptions>) {
      state.registrationInfo = {
        fetching: true,
        data: null,
        failure: null,
      };
    },
    registrationInfoSuccess(state, action) {
      state.registrationInfo = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    registrationInfoFailure(state, action) {
      state.registrationInfo = {
        fetching: false,
        data: null,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    signUpRequest(state, _: IPayloadAction<TSignUpOptions>) {
      state.signUpState = {
        fetching: true,
        data: null,
        failure: null,
      };
    },
    signUpSuccess(state, action) {
      state.signUpState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    signUpFailure(state, action) {
      state.signUpState = {
        fetching: false,
        data: null,
        failure: action.payload,
      };
    },

    logout(state) {
      state.isAuthorized = false;
    },

    checkIsAuth(state, action) {
      state.isAuthorized = action.payload;
    },
    clearAuthState() {
      return initialState;
    },
    getBlockingTime(state, action) {
      state.blockingTime = action.payload;
    },
    getCodeToken(state, action) {
      state.codeToken = action.payload;
    },
  },
});

export const {
  getSignInRequest,
  getSignInSuccess,
  getSignInFailure,

  resendCodeRequest,
  resendCodeSuccess,
  resendCodeFailure,

  registrationInfoRequest,
  registrationInfoSuccess,
  registrationInfoFailure,

  signUpRequest,
  signUpSuccess,
  signUpFailure,

  logout,
  clearAuthState,
  checkIsAuth,
  getBlockingTime,
  getCodeToken,
} = authSlice.actions;

export default authSlice.reducer;
