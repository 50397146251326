import { createSlice } from "@reduxjs/toolkit";

import { IPayloadAction } from "../rootInterface";
import { defaultState } from "../constants";

import {
  TRiskModelsStoreState,
  TRiskModel,
  TRiskModelInfoOptions,
  TRisksModelCreateOptions,
  TRisksModelActivateOptions,
  TRisksModelDeleteOptions,
  TRisksModelEditOptions,
  TRisksModelRulesOptions,
  TRisksModelUpdateRulesOptions
} from "./types";

const initialState: TRiskModelsStoreState = {
  risksModelsState: defaultState,
  riskModelInfoState: defaultState,
  risksModelCreateState: defaultState,
  risksModelActivateState: defaultState,
  risksModelDeleteState: defaultState,
  risksModelEditState: defaultState,
  risksModelRulesState: defaultState,
  risksModelUpdateRulesState: defaultState,
};

const riskModelsSlice = createSlice({
  name: "riskModels",
  initialState,
  reducers: {
    risksModelsRequest(state) {
      state.risksModelsState = {
        ...state.risksModelsState,
        fetching: true,
      };
    },
    risksModelsSuccess(state, action) {
      state.risksModelsState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    risksModelsFailure(state, action) {
      state.risksModelsState = {
        ...state.risksModelsState,
        fetching: false,
        failure: action.payload,
      };
    },
    risksModelsAppend(state, action: IPayloadAction<TRiskModel>) {
      state.risksModelsState.data?.push(action.payload);
    },
    risksModelRemove(state, action: IPayloadAction<{ id: string }>) {
      state.risksModelsState.data =
        state.risksModelsState.data?.filter(item => item.id !== action.payload.id) || [];
    },
    risksModelEdit(state, action: IPayloadAction<TRiskModel>) {
      state.risksModelsState.data = state.risksModelsState.data?.map(item => {
        if (item.id !== action.payload.id) return item;
        return action.payload;
      }) || [];
    },
    risksModelSetActivate(state, action: IPayloadAction<{ id: string }>) {
      state.risksModelsState.data = state.risksModelsState.data?.map(item => {
        if (item.id !== action.payload.id) return { ...item, is_active: false };
        return { ...item, is_active: true };
      }) || [];
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    riskModelInfoRequest(state, _: IPayloadAction<TRiskModelInfoOptions>) {
      state.riskModelInfoState = {
        ...state.riskModelInfoState,
        fetching: true,
      };
    },
    riskModelInfoSuccess(state, action) {
      state.riskModelInfoState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    riskModelInfoFailure(state, action) {
      state.riskModelInfoState = {
        ...state.riskModelInfoState,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    risksModelCreateRequest(state, _: IPayloadAction<TRisksModelCreateOptions>) {
      state.risksModelCreateState = {
        ...state.risksModelCreateState,
        fetching: true,
      };
    },
    risksModelCreateSuccess(state, action) {
      state.risksModelCreateState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    risksModelCreateFailure(state, action) {
      state.risksModelCreateState = {
        ...state.risksModelCreateState,
        fetching: false,
        failure: action.payload,
      };
    },


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    risksModelActivateRequest(state, _: IPayloadAction<TRisksModelActivateOptions>) {
      state.risksModelActivateState = {
        ...state.risksModelActivateState,
        fetching: true,
      };
    },
    risksModelActivateSuccess(state, action) {
      state.risksModelActivateState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    risksModelActivateFailure(state, action) {
      state.risksModelActivateState = {
        ...state.risksModelActivateState,
        fetching: false,
        failure: action.payload,
      };
    },


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    risksModelDeleteRequest(state, _: IPayloadAction<TRisksModelDeleteOptions>) {
      state.risksModelDeleteState = {
        ...state.risksModelDeleteState,
        fetching: true,
      };
    },
    risksModelDeleteSuccess(state, action) {
      state.risksModelDeleteState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    risksModelDeleteFailure(state, action) {
      state.risksModelDeleteState = {
        ...state.risksModelDeleteState,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    risksModelEditRequest(state, _: IPayloadAction<TRisksModelEditOptions>) {
      state.risksModelEditState = {
        ...state.risksModelEditState,
        fetching: true,
      };
    },
    risksModelEditSuccess(state, action) {
      state.risksModelEditState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    risksModelEditFailure(state, action) {
      state.risksModelEditState = {
        ...state.risksModelEditState,
        fetching: false,
        failure: action.payload,
      };
    },



    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    risksModelRulesRequest(state, _: IPayloadAction<TRisksModelRulesOptions>) {
      state.risksModelRulesState = {
        ...state.risksModelRulesState,
        fetching: true,
      };
    },
    risksModelRulesSuccess(state, action) {
      state.risksModelRulesState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    risksModelRulesFailure(state, action) {
      state.risksModelRulesState = {
        ...state.risksModelRulesState,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    risksModelUpdateRulesRequest(state, _: IPayloadAction<TRisksModelUpdateRulesOptions>) {
      state.risksModelUpdateRulesState = {
        ...state.risksModelUpdateRulesState,
        fetching: true,
      };
    },
    risksModelUpdateRulesSuccess(state, action) {
      state.risksModelUpdateRulesState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    risksModelUpdateRulesFailure(state, action) {
      state.risksModelUpdateRulesState = {
        ...state.risksModelUpdateRulesState,
        fetching: false,
        failure: action.payload,
      };
    },

  },
});

export const {
  risksModelsRequest,
  risksModelsSuccess,
  risksModelsFailure,
  risksModelsAppend,
  risksModelRemove,
  risksModelEdit,
  risksModelSetActivate,

  riskModelInfoRequest,
  riskModelInfoSuccess,
  riskModelInfoFailure,

  risksModelCreateRequest,
  risksModelCreateSuccess,
  risksModelCreateFailure,

  risksModelActivateRequest,
  risksModelActivateSuccess,
  risksModelActivateFailure,

  risksModelDeleteRequest,
  risksModelDeleteSuccess,
  risksModelDeleteFailure,

  risksModelEditRequest,
  risksModelEditSuccess,
  risksModelEditFailure,

  risksModelRulesRequest,
  risksModelRulesSuccess,
  risksModelRulesFailure,

  risksModelUpdateRulesRequest,
  risksModelUpdateRulesSuccess,
  risksModelUpdateRulesFailure,
} = riskModelsSlice.actions;

export default riskModelsSlice.reducer;
