import { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { paths } from "config/paths";
import { TLinks } from "config/types";
import { LinkIds } from "config/constants";

import { monitoringWalletCount } from "store/monitoredWallets/selectors";

import { useActiveNavLink } from "../../hooks";

import SidebarCollapse, { ECollapseKeys } from "../SidebarCollapse";

export type SidebarCollapseLinkProps = TLinks
const SidebarCollapseLink: FC<SidebarCollapseLinkProps> = ({ title, id, to, sublinks }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const isActiveLink = useActiveNavLink();
  const monitoringCount = useSelector(monitoringWalletCount);

  return (
    <SidebarCollapse
      title={title}
      id={id}
      collapseKey={ECollapseKeys.invoices}
      path={to}
    >
      <>
        {sublinks?.filter(activeSub => activeSub.active).map(sub => {
          if (!monitoringCount
            && sub.id === LinkIds.TRANSFERS_MONITORED_WALLETS
            && pathname !== paths.TRANSFERS_MONITORED_WALLETS
          ) return;

          return <NavLink
            key={sub.id}
            isActive={() => isActiveLink(sub.to)}
            to={sub.to}
            exact={true}
            className="sidebar-nav-item-link-sub"
            id={sub.id}
            activeClassName="sidebar-nav-item-link-sub__active"
          >
            {sub.icon && <sub.icon />} {t(sub.title)}
          </NavLink>;
        })}
      </>
    </SidebarCollapse>
  );
};

export default SidebarCollapseLink;
