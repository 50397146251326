import { FC } from "react";

import VectorIcon from "components/Icons/VectorIcon";

const Vector: FC<TBreadCrumb> = ({ title, onClick }) => (
  <div className="new-header__back" onClick={onClick}>
    <VectorIcon className="new-header__back__icon"/>
    {title && <div className="new-header__back__title">
      {title}
    </div>}
  </div>
);

export type TBreadCrumb = {
  onClick: () => void,
  title?: string|string[]|undefined;
}
export type THeaderBreadCrumbsProps = {
  breadCrumbs: TBreadCrumb[]
}
const HeaderBreadCrumbs: FC<THeaderBreadCrumbsProps> = ({ breadCrumbs }) => (
  <div className="new-header__back__wrapper">
    {breadCrumbs.map((item, index) => <Vector key={index} {...item}/>)}
  </div>
);

export default HeaderBreadCrumbs;