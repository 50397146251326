import { FC, useMemo } from "react";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import CryptoIcon from "components/CryptoIcon";

import getStyledTextWidth from "utils/getStyledTextWidth";

import Hint from "../../../Hint";

import { useTransferContext } from "../../index";

const COUNTERPARTY_NAME_WIDTH = 146;
const TransferCounterparty: FC = () => {
  const { t } = useTranslation();
  const transfer = useTransferContext();
  const networkCode = get(transfer, "network_code");
  const networkName = get(transfer, "network_name");
  const counterpartyCategory = get(transfer, "counterparty_category");
  const counterpartyName = get(transfer, "counterparty_name");
  const counterpartyAddress = get(transfer, "counterparty_address");

  const isLongName = useMemo(() =>
    getStyledTextWidth(counterpartyName || t("naming.unknown"), "500 14px Roboto,sans-serif") > COUNTERPARTY_NAME_WIDTH,
  [counterpartyName]);

  return (
    <div className="transfer__cel transfer-counterparty">
      {counterpartyCategory && <div className="transfer-counterparty__category">
        {counterpartyCategory}
      </div>}
      <CryptoIcon
        asset={networkCode}
        className="transfer__icon"
        hintText={`${networkName} (${networkCode})`}
        hint={true}
      />
      <div className="transfer__col">
        <Hint 
          className={cn("transfer-counterparty__name", { "no-category": !counterpartyCategory })} 
          content={counterpartyName || t("naming.unknown")}
          open={isLongName ? undefined : false}
        >
          {counterpartyName || t("naming.unknown")}
        </Hint>
        <Hint className="transfer-counterparty__address" content={counterpartyAddress}>
          {counterpartyAddress || "???"}
        </Hint>
      </div>
    </div>
  );
};

export default TransferCounterparty;