import { createSlice } from "@reduxjs/toolkit";

import { IPaginationResponse } from "api/types";

import { defaultState } from "../constants";
import { IPayloadAction } from "../rootInterface";

import { TCatalogStoreState, TNetwork, TNetworksOptions, TToken, TTokensOptions, } from "./types";

const initialState: TCatalogStoreState = {
  networks: defaultState,
  tokens: defaultState,
};

const commonSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    networksRequest(state, _: IPayloadAction<TNetworksOptions>) {
      state.networks = {
        ...state.networks,
        fetching: true,
      };
    },
    networksSuccess(state, action: IPayloadAction<IPaginationResponse<TNetwork>>) {
      state.networks = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    networksFailure(state, action) {
      state.networks = {
        ...state.networks,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tokensRequest(state, _: IPayloadAction<TTokensOptions>) {
      state.tokens = {
        ...state.tokens,
        fetching: true,
      };
    },
    tokensSuccess(state, action: IPayloadAction<IPaginationResponse<TToken>>) {
      state.tokens = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    cleanTokens(state) {
      state.tokens = {
        fetching: false,
        data: null,
        failure: null,
      };
    },
    tokensFailure(state, action) {
      state.tokens = {
        ...state.tokens,
        fetching: false,
        failure: action.payload,
      };
    },
  },
});

export const {
  networksRequest,
  networksSuccess,
  networksFailure,

  tokensRequest,
  tokensSuccess,
  tokensFailure,
  cleanTokens,
} = commonSlice.actions;

export default commonSlice.reducer;
