import { createSelector } from "@reduxjs/toolkit";

import { IApplicationState } from "../rootInterface";

const selectState = (state: IApplicationState) => state.monitoredWallets;

export const monitoredWalletsSelector = createSelector(selectState, state => state?.monitoredWallets);

export const monitoringWalletCount = createSelector(monitoredWalletsSelector, state => state?.data?.count);

export const addNewWalletSelector = createSelector(selectState, state => state?.addNewWallet);
export const editWalletSelector = createSelector(selectState, state => state?.editWallet);
export const calculationSelector = createSelector(selectState, state => state?.calculation);
export const calculationHistoricalSelector = createSelector(selectState, state => state?.historicalCalculation);
export const historicalImportSelector = createSelector(selectState, state => state?.historicalImport);
export const startHistoricalImportSelector = createSelector(selectState, state => state?.startHistoricalImport);
export const deleteWalletSelector = createSelector(selectState, state => state?.deleteMonitoredWallet);
export const checkHistoricalTransfersSelector = createSelector(selectState, state => state?.checkHistoricalTransfers);
