import { FC, useMemo } from "react";
import { Dropdown } from "antd";

import TransfersDirection from "../../../Transfer/components/TransfersDirection";
import TransferAdditional from "../../../Transfer/components/TransferAdditional";
import EllipsisVerticalIcon from "../../../Icons/EllipsisVerticalIcon";

const TransferMobileHead: FC = () => {
  const items = useMemo(() => ([{
    key: "1",
    onClick: () => {
      // eslint-disable-next-line no-console
      console.log("item 1");
    },
    label: "Item 1",
  }]), []);

  return (<div className="transfer-head">
    <TransfersDirection/>
    <TransferAdditional/>
    {false && <Dropdown
      menu={{
        className: "bn-dropdown__menu",
        items,
      }}
      trigger={["click"]}
      placement="bottomRight"
    >
      <a
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        className="transfer-additional__menu"
      >
        <EllipsisVerticalIcon/>
      </a>
    </Dropdown>}
  </div>);
};

export default TransferMobileHead;
