import { FC, useMemo } from "react";
import { Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import EllipsisVerticalIcon from "components/Icons/EllipsisVerticalIcon";
// eslint-disable-next-line max-len
import { MonitoredWalletsDeleteModal } from "components/MonitoredWalletsDetails/components/WalletsInformation/components/MonitoredWalletsDetailsInformationDesktop/MonitoredWalletsModals/MonitoredWalletsDeleteModal";

import { openModal } from "store/modals/actions";
import { EModals } from "store/modals/types";

import { dropdownAlign } from "../..";

const MobileHeaderDeleteWallet: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const itemClick = () => {
    dispatch(openModal({ modal: EModals.monitoredWalletsDelete }));
  };

  const items = useMemo(
    () => [
      {
        key: "1",
        onClick: itemClick,
        label: t("monitoredWallets.deleteWallet"),
        style: { color: "#E5424C" },
      },
    ],
    []
  );

  return (
    <>
      <Dropdown
        menu={{
          className: "bn-dropdown__menu",
          items: [...items],
        }}
        trigger={["click"]}
        placement="bottomRight"
        disabled={false}
        align={dropdownAlign}
      >
        <a onClick={e => e.preventDefault()} className="header-mobile__menu">
          <EllipsisVerticalIcon />
        </a>
      </Dropdown>
      <MonitoredWalletsDeleteModal />
    </>
  );
};

export default MobileHeaderDeleteWallet;
