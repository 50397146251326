import { FC } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { paths } from "config/paths";

import { getUserInfoSelector } from "store/user/selectors";

const HeaderChecks: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { data } = useSelector(getUserInfoSelector);

  const handleClickChecks = () => {
    if (location.pathname === paths.PLAN) return;
    history.push(paths.PLAN);
  };

  return (
    <div onClick={handleClickChecks} className="new-header__checks">
      {t("naming.headerChecks", { count: data?.checks_available || 0 })}
    </div>
  );
};

export default HeaderChecks;