const getStyledTextWidth = (text: string, font: string): number => {
  // re-use canvas object for better performance
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (context) {
    context.font = font;
  }

  const metrics = context?.measureText(text);
  return metrics?.width || 0;
};

export default getStyledTextWidth;