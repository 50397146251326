import { createSlice } from "@reduxjs/toolkit";

import { IPayloadAction } from "../rootInterface";

import { EModals, TModalsStoreState } from "./types";
import { openModal } from "./actions";

const initialState: TModalsStoreState = {};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    clearModalsState() {
      return initialState;
    },
    closeModal(state, action: IPayloadAction<EModals>) {
      state[action.payload] = {
        open: false,
        data: null
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(openModal, (state, action) => {
        if (typeof action.payload === 'string') {
          state[action.payload] = {
            open: true,
            data: null
          };
        } else {
          state[action.payload.modal] = {
            open: true,
            data: action.payload.data
          };
        }
      });
  }
});

export const {
  clearModalsState,
  // openModal,
  closeModal
} = modalsSlice.actions;

export default modalsSlice.reducer;
