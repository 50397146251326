import { FC, useMemo } from "react";
import { Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ItemType } from "antd/es/menu/hooks/useItems";

import EllipsisVerticalIcon from "components/Icons/EllipsisVerticalIcon";
import DownloadIcon from "components/Icons/DownloadIcon";

import { EModals, ModalNewTransferData } from "store/modals/types";
import { transfersDetailsSelector } from "store/transfers/selectors";
import { ETransferDirection, ETransferType } from "store/transfers/types";
import { openModal } from "store/modals/actions";

import { dropdownAlign } from "../..";

const MobileHeaderTransfersMenu: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: transferDetails, initialLoading, fetching } = useSelector(transfersDetailsSelector);
  const disabled = transferDetails?.type === ETransferType.withdrawal
    || transferDetails?.type === ETransferType.deposit;

  const reportUrl = transferDetails?.counterparty?.report_url || transferDetails?.exposure?.report_url;

  const items = useMemo(() => {
    const res: ItemType[] = [
      {
        key: "1",
        onClick: () => {
          if (!transferDetails?.type) return;

          dispatch(openModal<ModalNewTransferData>({ modal:EModals.transferFast, data: {
            transferType: transferDetails?.type
          } }));
        },
        label: t("transfersDetails.bindTransaction")
      },
    ];

    const downloadItem = reportUrl ? {
      key: "2",
      label: (
        <a href={reportUrl} rel="noreferrer" target="_blank" className='download-pdf'>
          <p>
            { transferDetails?.direction === ETransferDirection.incoming
              ? t("common.origin_of_funds") : t("common.destination_of_funds")
            }
          </p>
          <DownloadIcon className='download-pdf__icon' />
        </a>
      )
    } : null;

    if(downloadItem) {
      res.push(downloadItem);
    }

    return res;
  }, [transferDetails]);

  if(disabled && reportUrl) {
    return (
      <a href={reportUrl} rel="noreferrer" target="_blank" className='download-pdf'>
        <DownloadIcon />
      </a>
    );
  }

  if (disabled || initialLoading || fetching) {
    return null;
  }

  return <Dropdown
    menu={{
      className: "bn-dropdown__menu",
      items: [...items],
    }}
    trigger={["click"]}
    placement="bottomRight"
    disabled={disabled}
    align={dropdownAlign}
  >
    <a
      onClick={e => e.preventDefault()}
      className="header-mobile__menu"
    >
      <EllipsisVerticalIcon/>
    </a>
  </Dropdown>;
};

export default MobileHeaderTransfersMenu;
