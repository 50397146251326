import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import isFunction from "lodash/isFunction";
import { round } from "lodash";

import request from "../../api";

import { IPayloadAction } from "../rootInterface";

import {
  riskModelInfoFailure, riskModelInfoRequest,
  riskModelInfoSuccess,
  risksModelActivateFailure, risksModelActivateRequest,
  risksModelActivateSuccess,
  risksModelCreateFailure, risksModelCreateRequest,
  risksModelCreateSuccess, risksModelDeleteFailure, risksModelDeleteRequest,
  risksModelDeleteSuccess, risksModelEditFailure, risksModelEditRequest, risksModelEditSuccess,
  risksModelRemove,
  risksModelsAppend,
  risksModelSetActivate,
  risksModelsFailure,
  risksModelsRequest,
  risksModelsSuccess,
  risksModelRulesFailure,
  risksModelRulesRequest,
  risksModelRulesSuccess, risksModelUpdateRulesFailure, risksModelUpdateRulesRequest,
  risksModelUpdateRulesSuccess
} from "./reducers";
import {
  TRiskModelInfoOptions,
  TRisksModelActivateOptions,
  TRisksModelCreateOptions,
  TRisksModelDeleteOptions, TRisksModelEditOptions,
  TRiskModel,
  TRisksModelRulesOptions, TRisksModelUpdateRulesOptions, TRisksModelRulesData
} from "./types";


function* amlRisksModels() {
  try {
    const response: AxiosResponse = yield call(request.get, "/risk-models/");
    yield put(risksModelsSuccess(response.data));
  } catch (error) {
    yield put(risksModelsFailure(error));
  }
}

function* amlRisksModelCreate(action: IPayloadAction<TRisksModelCreateOptions>) {
  const { data } = action.payload;
  try {
    const response: AxiosResponse<TRiskModel> = yield call(request.post, "/risk-models/create/", data);
    yield put(risksModelCreateSuccess(response.data));
    yield put(risksModelsAppend(response.data));

    if (isFunction(action.payload?.callOnSuccess)) action.payload.callOnSuccess(response.data);
  } catch (error) {
    yield put(risksModelCreateFailure(error));
  }
}

function* amlRiskModelInfo(action: IPayloadAction<TRiskModelInfoOptions>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.get, `/risk-models/${id}/`);
    yield put(riskModelInfoSuccess(response.data));
  } catch (error) {
    yield put(riskModelInfoFailure(error));
  }
}

function* amlRisksModelActivate(action: IPayloadAction<TRisksModelActivateOptions>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.post, `/risk-models/${id}/activate/`);
    yield put(risksModelActivateSuccess(response.data));
    yield put(risksModelSetActivate({ id }));
    if (isFunction(action.payload?.callOnSuccess)) action.payload.callOnSuccess();
  } catch (error) {
    yield put(risksModelActivateFailure(error));
  }
}


function* amlRisksModelDelete(action: IPayloadAction<TRisksModelDeleteOptions>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.delete, `/risk-models/${id}/delete/`);
    yield put(risksModelDeleteSuccess(response.data));
    yield put(risksModelRemove({ id }));
    if (isFunction(action.payload?.callOnSuccess)) action.payload.callOnSuccess();
  } catch (error) {
    yield put(risksModelDeleteFailure(error));
  }
}


function* risksModelEdit(action: IPayloadAction<TRisksModelEditOptions>) {
  const { id, data } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.patch, `/risk-models/${id}/edit/`, data);
    yield put(risksModelEditSuccess(response.data));
    yield put(riskModelInfoSuccess(response.data));
    if (isFunction(action.payload?.callOnSuccess)) action.payload.callOnSuccess();
  } catch (error) {
    yield put(risksModelEditFailure(error));
  }
}

function* risksModelRules(action: IPayloadAction<TRisksModelRulesOptions>) {
  const { id, query } = action.payload;
  try {
    const response: AxiosResponse<TRisksModelRulesData> = yield call(
      request.get, `/risk-models/${id}/rules/`, { params: query }
    );
    yield put(risksModelRulesSuccess(response.data
      .map(item => ({ ...item, min_share: item.min_share ? round(item.min_share * 100, 1): null,
        min_value_usd: item.min_value_usd ? Math.round(item.min_value_usd * 10) / 10 : null })))
    );
  } catch (error) {
    yield put(risksModelRulesFailure(error));
  }
}


function* risksModelUpdateRules(action: IPayloadAction<TRisksModelUpdateRulesOptions>) {
  const { id, data } = action.payload;
  try {
    const response: AxiosResponse<TRiskModel> =
      yield call(request.post, `/risk-models/${id}/update-rules/`, data);
    yield put(risksModelUpdateRulesSuccess(response.data));
    yield put(riskModelInfoSuccess(response.data));
    if (isFunction(action.payload?.callOnSuccess)) action.payload.callOnSuccess();
  } catch (error) {
    yield put(risksModelUpdateRulesFailure(error));
  }
}


function* Saga(): Generator {
  yield all([
    takeLatest(risksModelsRequest.type, amlRisksModels),
    takeLatest(riskModelInfoRequest.type, amlRiskModelInfo),
    takeLatest(risksModelCreateRequest.type, amlRisksModelCreate),
    takeLatest(risksModelActivateRequest.type, amlRisksModelActivate),
    takeLatest(risksModelDeleteRequest.type, amlRisksModelDelete),
    takeLatest(risksModelEditRequest.type, risksModelEdit),
    takeLatest(risksModelRulesRequest.type, risksModelRules),
    takeLatest(risksModelUpdateRulesRequest.type, risksModelUpdateRules),
  ]);
}

export default Saga;
