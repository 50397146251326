import { FC } from "react";
import { useLocation } from "react-router-dom";

import { paths } from "config/paths";

import MobileHeaderLeft from "./components/MobileHeaderLeft";
import MobileHeaderRight from "./components/MobileHeaderRight";
import MobileHeaderMain from "./components/MobileHeaderMain";

const MobileHeader: FC = () => {
  const router = useLocation();
  if (router.pathname === paths.FAST_CHECKS) {
    return null;
  }

  return <div className="header-mobile__wrapper container mob-p-16">
    <MobileHeaderLeft/>
    <MobileHeaderMain/>
    <MobileHeaderRight/>
  </div>;
};

export default MobileHeader;
