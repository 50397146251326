import { FC, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";

import PlusIcon from "components/Icons/PlusIcon";

import { openModal } from "store/modals/actions";
import { EModals, ModalNewTransferData } from "store/modals/types";
import { getUserInfoDataSelector } from "store/user/selectors";
import { ETransferType } from "store/transfers/types";

import { notificationNoPermission } from "utils/notifications";
import { isMonitoredWallets } from "utils/featuresCheck";

import {
  getTransferTypeTranslation
} from "../../../../../../Transfers/components/TransfersFilter/components/TransfersFilterType/utils";
import { dropdownAlign } from "../..";

const MobileHeaderAddTransfer: FC = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(getUserInfoDataSelector);

  const items = useMemo(() => {
    const res: ItemType[] = [
      {
        key: ETransferType.import_wallet,
        onClick: () => {
          dispatch(openModal({ modal: EModals.monitoredNewWallet }));
        },
        label: isMonitoredWallets() ? getTransferTypeTranslation(ETransferType.import_wallet) : null
      },
      {
        key: ETransferType.deposit,
        onClick: () => {
          dispatch(openModal<ModalNewTransferData>({
            modal:EModals.transferFast,
            data: { transferType: ETransferType.deposit }
          }));
        },
        label: getTransferTypeTranslation(ETransferType.deposit)
      },
      {
        key: ETransferType.withdrawal,
        onClick: () => {
          dispatch(openModal<ModalNewTransferData>({
            modal:EModals.transferFast,
            data: { transferType: ETransferType.withdrawal }
          }));
        },
        label: getTransferTypeTranslation(ETransferType.withdrawal)
      },
      {
        key: ETransferType.deposit_attempt,
        onClick: () => {
          dispatch(openModal<ModalNewTransferData>({
            modal:EModals.transferFast,
            data: { transferType: ETransferType.deposit_attempt }
          }));
        },
        label: getTransferTypeTranslation(ETransferType.deposit_attempt)
      },
      {
        key: ETransferType.withdrawal_attempt,
        onClick: () => {
          dispatch(openModal<ModalNewTransferData>({
            modal:EModals.transferFast,
            data: { transferType: ETransferType.withdrawal_attempt }
          }));
        },
        label: getTransferTypeTranslation(ETransferType.withdrawal_attempt)
      },
    ].filter(item => Boolean(item.label));

    return res;
  }, []);

  return <div className="header-mobile__left__add-transfer">
    <Dropdown
      open={!userInfo?.permissions.registering_transfers ? false : undefined}
      placement="bottomLeft"
      menu={{
        className: "bn-dropdown__menu",
        items,
      }}
      align={dropdownAlign}
      trigger={["click"]}
      onOpenChange={open => {
        if (!userInfo?.permissions.registering_transfers) {
          notificationNoPermission();
          return;
        }
        return open;
      }}
    >
      <PlusIcon className="plus-icon"/>
    </Dropdown>
  </div>;
};

export default MobileHeaderAddTransfer;
