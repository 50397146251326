import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import request from "api";

import {
  amlRiskSummaryFailure,
  amlRiskSummaryRequest,
  amlRiskSummarySuccess,
} from "./reducers";


function* getAmlRiskSummary() {
  try {
    const response: AxiosResponse = yield call(request.get, "/aml/risks-summary/");
    yield put(amlRiskSummarySuccess(response.data));
  } catch (error) {
    yield put(amlRiskSummaryFailure(error));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(amlRiskSummaryRequest.type, getAmlRiskSummary),
  ]);
}

export default Saga;

