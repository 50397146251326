import { createSlice } from "@reduxjs/toolkit";

import { IPaginationResponse } from "api/types";

import { defaultPaginationData, defaultState } from "../constants";
import { IPayloadAction } from "../rootInterface";

import {
  TAlert, TAlertDetail,
  TAlertDetailOptions, TAlertGroupsAntStatusesOptions, TAlertsActivitiesOptions, TAlertsAddCommentOptions,
  TAlertsAssignOptions,
  TAlertsChangeStatusOptions,
  TAlertsOptions, TAlertsStoreState
} from "./types";

const initialState: TAlertsStoreState = {
  alerts: {
    ...defaultState,
    options: {},
    data: defaultPaginationData,
    initialLoading: true,
  },
  alertGroupsAntStatuses: defaultState,
  alertDetail: defaultState,
  alertsAssign: defaultState,
  alertsChangeStatus: defaultState,
  alertsAddComment: defaultState,
  alertsActivities: defaultState,
};

const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {

    alertsRequest(state, action: IPayloadAction<TAlertsOptions>) {
      state.alerts = {
        ...state.alerts,
        options: action.payload,
        fetching: true,
        infiniteScroll: action.payload?.infiniteScroll
      };
    },
    alertsSuccess(state, action: IPayloadAction<{
      data: IPaginationResponse<TAlert>, infiniteScroll?: boolean;
    }>) {
      let newData = action.payload.data;
      if (action.payload.infiniteScroll) {
        const prevResults = state?.alerts?.data?.results || [];

        newData = {
          ...action.payload.data,
          results: [...prevResults, ...(action.payload?.data?.results || [])]
        };
      }
      state.alerts = {
        ...state.alerts,
        fetching: false,
        data: newData,
        failure: null,
        initialLoading: false,
      };
    },
    alertsSetItem(state, action: IPayloadAction<{id: string, data: Partial<TAlert>}>) {
      state.alerts = {
        ...state.alerts,
        data: {
          ...state.alerts.data as IPaginationResponse<TAlert>,
          results: state.alerts?.data?.results?.map(item => {
            if (action.payload.id !== item.id) return item;
            return {
              ...item,
              ...action.payload.data
            };
          }) || []
        }
      };
    },
    alertsFailure(state, action) {
      state.alerts = {
        ...state.alerts,
        fetching: false,
        failure: action.payload,
        initialLoading: false,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    alertGroupsAntStatusesRequest(state, _: IPayloadAction<TAlertGroupsAntStatusesOptions>) {
      state.alertGroupsAntStatuses = {
        ...state.alertGroupsAntStatuses,
        fetching: true,
      };
    },
    alertGroupsAntStatusesSuccess(state, action) {
      state.alertGroupsAntStatuses = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    alertGroupsAntStatusesFailure(state, action) {
      state.alertGroupsAntStatuses = {
        ...state.alertGroupsAntStatuses,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    alertDetailRequest(state, _: IPayloadAction<TAlertDetailOptions>) {
      state.alertDetail = {
        ...state.alertDetail,
        fetching: true,
      };
    },
    alertDetailSuccess(state, action) {
      state.alertDetail = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    alertDetailSetState(state, action: IPayloadAction<Partial<TAlertDetail>>) {
      const { data } = state.alertDetail;
      if (!data) {
        return;
      }
      const newData: TAlertDetail = { ...data, ...action.payload };

      state.alertDetail = {
        fetching: false,
        data: newData,
        failure: null,
      };
    },
    alertDetailFailure(state, action) {
      state.alertDetail = {
        ...state.alertDetail,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    alertsAssignRequest(state, _: IPayloadAction<TAlertsAssignOptions>) {
      state.alertsAssign = {
        ...state.alertsAssign,
        fetching: true,
      };
    },
    alertsAssignSuccess(state, action) {
      state.alertsAssign = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    alertsAssignFailure(state, action) {
      state.alertsAssign = {
        ...state.alertsAssign,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    alertsChangeStatusRequest(state, _: IPayloadAction<TAlertsChangeStatusOptions>) {
      state.alertsChangeStatus = {
        ...state.alertsChangeStatus,
        fetching: true,
      };
    },
    alertsChangeStatusSuccess(state, action) {
      state.alertsChangeStatus = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    alertsChangeStatusFailure(state, action) {
      state.alertsChangeStatus = {
        ...state.alertsChangeStatus,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    alertsAddCommentRequest(state, _: IPayloadAction<TAlertsAddCommentOptions>) {
      state.alertsAddComment = {
        ...state.alertsAddComment,
        fetching: true,
      };
    },
    alertsAddCommentSuccess(state, action) {
      state.alertsAddComment = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    alertsAddCommentFailure(state, action) {
      state.alertsAddComment = {
        ...state.alertsAddComment,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    alertsActivitiesRequest(state, _: IPayloadAction<TAlertsActivitiesOptions>) {
      state.alertsActivities = {
        ...state.alertsActivities,
        fetching: true,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    alertsActivitiesRequestNoLoading(state, _: IPayloadAction<TAlertsActivitiesOptions>) {
      state.alertsActivities = {
        ...state.alertsActivities,
        fetching: false,
      };
    },
    alertsActivitiesSuccess(state, action) {
      state.alertsActivities = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    alertsActivitiesFailure(state, action) {
      state.alertsActivities = {
        ...state.alertsActivities,
        fetching: false,
        failure: action.payload,
      };
    },
  },
});

export const {
  alertsRequest,
  alertsSuccess,
  alertsFailure,
  alertsSetItem,

  alertGroupsAntStatusesRequest,
  alertGroupsAntStatusesSuccess,
  alertGroupsAntStatusesFailure,

  alertDetailRequest,
  alertDetailSuccess,
  alertDetailFailure,
  alertDetailSetState,

  alertsAssignRequest,
  alertsAssignSuccess,
  alertsAssignFailure,

  alertsChangeStatusRequest,
  alertsChangeStatusSuccess,
  alertsChangeStatusFailure,

  alertsAddCommentRequest,
  alertsAddCommentSuccess,
  alertsAddCommentFailure,

  alertsActivitiesRequest,
  alertsActivitiesRequestNoLoading,
  alertsActivitiesSuccess,
  alertsActivitiesFailure,
} = alertsSlice.actions;

export default alertsSlice.reducer;
