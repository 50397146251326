import { FC } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";

import BNDropdown from "components/BNDropdown";
import { TBNDropdownChildren } from "components/BNDropdown/types";
import EllipsisHorizontalIcon from "components/Icons/EllipsisHorizontalIcon";

import { mobileAdditionalMenuSelector } from "store/common/selectors";
import { setMobileAdditionalMenu } from "store/common/reducers";

import { MOBILE_MENU_ADDITIONAL } from "../../container";

import MobileTabDrawer from "../MobileTabDrawer";

const MobileTabAdditionalMenu: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const additionalMenu = useSelector(mobileAdditionalMenuSelector);

  const overlay = ({ setActive }: TBNDropdownChildren) => <>
    {MOBILE_MENU_ADDITIONAL.map(link => (
      <NavLink
        key={link.id}
        to={link.to}
        activeClassName="active"
        className="mobile-tab-menu__additional__overlay__link"
        exact={true}
        onClick={() => setActive(false)}
      >
        {t(link.title)}
      </NavLink>
    ))}
  </>;

  return (
    <>
      <BNDropdown
        overlay={overlay}
        overlayClass="mobile-tab-menu__additional__overlay"
        active={additionalMenu}
        setActive={value => dispatch(setMobileAdditionalMenu(value))}
        activateOnClickOutside={false}
        overlayStyle={{ right: 0 }}
      >
        {({ active }) => (
          <div className={cn("mobile-tab-menu__item mobile-tab-menu__additional", { active })}>
            <EllipsisHorizontalIcon />
          </div>
        )}
      </BNDropdown>
      <MobileTabDrawer
        visible={additionalMenu}
        close={() => {
          dispatch(setMobileAdditionalMenu(!additionalMenu));
        }}
      />
    </>
  );
};

export default MobileTabAdditionalMenu;
