import { FC, Fragment, useMemo } from "react";
import cn from "classnames";
import { matchPath } from "react-router-dom";
import { Layout as AntLayout } from "antd";
import MediaQuery, { useMediaQuery } from "react-responsive";

import { paths } from "config/paths";
import { maxMobileMediaWidth, minShowSidebarWidth } from "config/constants";

import Sidebar from "components/segments/Sidebar";
import NewHeader from "components/segments/NewHeader";
import MobileTabMenu from "components/segments/MobileTabMenu";
import MobileHeader from "components/segments/MobileHeader";

import { ILayout } from "../../types";
import { useLayoutAppNameContext } from "../../hooks";

const PrivateContainer: FC<ILayout> = ({ children }) => {
  const isMaxMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });
  const isAvailableShowSidebar = useMediaQuery({ query: `(min-width: ${minShowSidebarWidth}px)` });
  const { routePath } = useLayoutAppNameContext();

  const lightGreyMobile = matchPath(location.pathname, paths.HOME)?.isExact;
  const showSidebar = ![
    paths.RISK_SETTINGS_DETAILS,
    paths.TRANSFERS_DETAILS,
    paths.ACCESS_MANAGEMENT_DETAIL,
    paths.CLIENTS_DETAILS,
    paths.CLIENTS_DETAILS_TRANSFERS,
    paths.CLIENTS_DETAILS_TRANSFERS_DETAILS,
    paths.CLIENTS_DETAILS_TRANSFER,
    paths.CLIENTS_ARCHIVE_DETAILS,
    paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS,
    paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS_DETAILS,
    paths.CLIENTS_ARCHIVE_DETAILS_TRANSFER,
    paths.FAST_CHECKS_DETAILS,
    paths.FAST_CHECKS_DETAILS_RISKS,
    paths.TRANSFERS_MONITORED_WALLETS_INFORMATION,
    paths.TRANSFERS_MONITORED_WALLETS_INFORMATION_ARCHIVE,
  ].includes(routePath as paths);

  const hideMobileMenu = useMemo(() => {
    const _paths: { [k in paths]?: paths } = {
      [paths.ALERTS_DETAIL]: paths.ALERTS_DETAIL,

      [paths.TRANSFERS_DETAILS]: paths.TRANSFERS_DETAILS,
      [paths.TRANSFERS_DETAILS_ALERT]: paths.TRANSFERS_DETAILS_ALERT,
      [paths.TRANSFERS_DETAILS_ALERTS]: paths.TRANSFERS_DETAILS_ALERTS,
      [paths.TRANSFERS_DETAILS_ALERTS_DETAILS]: paths.TRANSFERS_DETAILS_ALERTS_DETAILS,

      [paths.CLIENTS_DETAILS]: paths.CLIENTS_DETAILS,
      [paths.CLIENTS_DETAILS_ALERT]: paths.CLIENTS_DETAILS_ALERT,
      [paths.CLIENTS_DETAILS_ALERTS]: paths.CLIENTS_DETAILS_ALERTS,
      [paths.CLIENTS_DETAILS_ALERTS_DETAILS]: paths.CLIENTS_DETAILS_ALERTS_DETAILS,
      [paths.CLIENTS_DETAILS_TRANSFERS]: paths.CLIENTS_DETAILS_TRANSFERS,
      [paths.CLIENTS_DETAILS_TRANSFERS_DETAILS]: paths.CLIENTS_DETAILS_TRANSFERS_DETAILS,
      [paths.CLIENTS_DETAILS_TRANSFER]: paths.CLIENTS_DETAILS_TRANSFER,

      [paths.CLIENTS_ARCHIVE_DETAILS]: paths.CLIENTS_ARCHIVE_DETAILS,
      [paths.CLIENTS_ARCHIVE_DETAILS_ALERT]: paths.CLIENTS_ARCHIVE_DETAILS_ALERT,
      [paths.CLIENTS_ARCHIVE_DETAILS_ALERTS]: paths.CLIENTS_ARCHIVE_DETAILS_ALERTS,
      [paths.CLIENTS_ARCHIVE_DETAILS_ALERTS_DETAILS]: paths.CLIENTS_ARCHIVE_DETAILS_ALERTS_DETAILS,
      [paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS]: paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS,
      [paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS_DETAILS]: paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS_DETAILS,
      [paths.CLIENTS_ARCHIVE_DETAILS_TRANSFER]: paths.CLIENTS_ARCHIVE_DETAILS_TRANSFER,

      [paths.ACCESS_MANAGEMENT_NEW]: paths.ACCESS_MANAGEMENT_NEW,
      [paths.ACCESS_MANAGEMENT_DETAIL]: paths.ACCESS_MANAGEMENT_DETAIL,

      [paths.FAST_CHECKS_DETAILS]: paths.FAST_CHECKS_DETAILS,

      [paths.TRANSFERS_MONITORED_WALLETS_INFORMATION]: paths.TRANSFERS_MONITORED_WALLETS_INFORMATION,
      [paths.TRANSFERS_MONITORED_WALLETS_INFORMATION_ARCHIVE]: paths.TRANSFERS_MONITORED_WALLETS_INFORMATION_ARCHIVE,
    };
    return Boolean(_paths[routePath]);
  }, [routePath]);

  const pTop0 = useMemo(() => {
    if (routePath === paths.SETTINGS_SAFETY && isMaxMobile) {
      return true;
    }
    const _paths: { [k in paths]?: paths } = {
      [paths.TRANSFERS]: paths.TRANSFERS,
      [paths.HOME]: paths.HOME,
      [paths.CLIENTS]: paths.CLIENTS,
      [paths.CLIENTS_DETAILS_TRANSFERS]: paths.CLIENTS_DETAILS_TRANSFERS,
      [paths.CLIENTS_DETAILS_ALERTS]: paths.CLIENTS_DETAILS_ALERTS,
      [paths.CLIENTS_ARCHIVE]: paths.CLIENTS_ARCHIVE,
      [paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS]: paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS,
      [paths.CLIENTS_ARCHIVE_DETAILS_ALERTS]: paths.CLIENTS_ARCHIVE_DETAILS_ALERTS,
      [paths.FAST_CHECKS]: paths.FAST_CHECKS,
      [paths.TRANSFERS_MONITORED_WALLETS]: paths.TRANSFERS_MONITORED_WALLETS,
      [paths.TRANSFERS_MONITORED_WALLETS_INFORMATION_ARCHIVE]: paths.TRANSFERS_MONITORED_WALLETS_INFORMATION_ARCHIVE,
    };
    return Boolean(_paths[routePath]);
  }, [routePath]);

  const mobilePTop0 = useMemo(() => {
    const _paths: { [k in paths]?: paths } = {
      [paths.TRANSFERS_MONITORED_WALLETS_INFORMATION]: paths.TRANSFERS_MONITORED_WALLETS_INFORMATION,
    };
    return Boolean(_paths[routePath]);
  }, [routePath]);

  return <Fragment>
    {showSidebar && <MediaQuery minWidth={minShowSidebarWidth}>
      <Sidebar/>
    </MediaQuery>}
    <AntLayout className={cn("main-layout", {
      "sidebarExists": showSidebar && isAvailableShowSidebar
    })}>
      {!isMaxMobile ? <NewHeader/> : <MobileHeader/>}
      <main
        className={cn("main", {
          "p-t-0": pTop0,
          "mobile__p-t-0": mobilePTop0,
          "light-grey": lightGreyMobile,
          "noMobileMenu": hideMobileMenu,
        })}
      >
        {children}
      </main>
      {isMaxMobile && !hideMobileMenu && <MobileTabMenu/>}
    </AntLayout>
  </Fragment>;
};

export default PrivateContainer;
