import { FC, SVGProps } from "react";

import { colors } from "config/constants";

{/* eslint-disable max-len */}

const ArrowDown: FC<SVGProps<SVGSVGElement>> = ({  color = colors.pink , ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M18.6936 10.7529L18.6919 10.7548L13.2676 17.0221V2.57321C13.2676 2.31756 13.0598 2.1084 12.8033 2.1084H11.1961C10.9396 2.1084 10.7319 2.31756 10.7319 2.57321V17.0221L5.30848 10.756C5.30831 10.7558 5.30814 10.7556 5.30798 10.7554C5.17915 10.6051 4.99156 10.5208 4.79436 10.5208H2.62472C2.22937 10.5208 2.01307 10.9899 2.27557 11.2895C2.27583 11.2898 2.27609 11.2901 2.27635 11.2904L11.1651 21.5592C11.2689 21.6792 11.3972 21.7755 11.5415 21.8415C11.6857 21.9076 11.8424 21.9417 12.0011 21.9417C12.1597 21.9417 12.3164 21.9076 12.4607 21.8415L12.3566 21.6142L12.4607 21.8415C12.6049 21.7755 12.7332 21.6792 12.837 21.5592L21.7245 11.2888L21.7247 11.2886C21.9845 10.9876 21.7718 10.5208 21.3747 10.5208H19.2051C19.0087 10.5208 18.8231 10.6063 18.6936 10.7529Z" fill={color} stroke={color} strokeWidth="0.5"/>
  </svg>
);

export default ArrowDown;