import dayjs from "dayjs";

import { ELanguages } from "../i18n/constants";

import i18n from "./../i18n";

export enum EFormats {
  L = "L",
  ll = "ll",
  D = "D", // DD.MM.YYYY
  T = "T", // h:mm a / HH:mm
  TA = "TA", // h:mm A / HH:mm
  TS = "TS", // h:mm:ss
  LT = "LT", // DD.MM.YYYY h:mm a / DD.MM.YYYY HH:mm
  A = "A", // A / HH:mm
  DMMM = "DMMM", // DD M yyyy / dd M yyyy
  TA_DMMM = "TA_DMMM", // h:mm A, DD M yyyy / HH:mm, dd M yyyy
}

const formatDate = (date: Date|string, f: EFormats): string => {
  const formats = {
    [EFormats.L]: () => dayjs(date).locale(i18n.language).format("L"),
    [EFormats.ll]: () => dayjs(date).locale(i18n.language).format("ll"),
    [EFormats.D]: () => dayjs(date).format("DD.MM.YYYY"),
    [EFormats.T]: () => dayjs(date).format(i18n.language === ELanguages.enUS ? "h:mm a" : "HH:mm"),
    [EFormats.TA]: () => dayjs(date).format(i18n.language === ELanguages.enUS ? "h:mm A" : "HH:mm"),
    [EFormats.TS]: () => dayjs(date).format("h:mm:ss"),
    [EFormats.LT]: () => dayjs(date).format(
      i18n.language === ELanguages.enUS ? "DD.MM.YYYY h:mm a" : "DD.MM.YYYY HH:mm"),
    [EFormats.A]: () => dayjs(date).format(i18n.language === ELanguages.enUS ? "h:mm A" : "HH:mm"),
    [EFormats.DMMM]: () => dayjs(date).format(
      i18n.language === ELanguages.enUS ? "DD MMM YYYY" : "DD MMM YYYY"),
    [EFormats.TA_DMMM]: () => dayjs(date).format(
      i18n.language === ELanguages.enUS ? "h:mm A, DD MMM YYYY" : "HH:mm, DD MMM YYYY"),
  };
  return formats[f]();
};

export default formatDate;