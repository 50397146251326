import { FC } from "react";
import { useSelector } from "react-redux";

import DownloadIcon from "components/Icons/DownloadIcon";

import { fastChecksDetailsSelector } from "store/fastChecks/selectors";


const MobileHeaderFastCheck: FC = () => {
  const { data: fastCheckDetails, initialLoading, fetching } = useSelector(fastChecksDetailsSelector);


  const reportUrl = fastCheckDetails?.address_exposure.report_url || fastCheckDetails?.transfer_exposure.report_url;

  if (initialLoading || fetching || !reportUrl) {
    return null;
  }

  return (
    <a href={reportUrl} rel="noreferrer" target="_blank" className='download-pdf'>
      <DownloadIcon />
    </a>
  );
};

export default MobileHeaderFastCheck;
