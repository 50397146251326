export const PAGINATION_PAGE_LIMIT = 20;
export const MONITORING_PAGE_LIMIT = 10;
export const FAST_PAGE_LIMIT = 10;
export const PAGINATION_LARGE_LIMIT = 50;

export const defaultState = {
  fetching: false,
  data: null,
  failure: null,
};
export const defaultInitialState = {
  ...defaultState,
  initialLoading: true
};

export const defaultCalculationState = {
  ...defaultState, data: {
    allowed_to_import_without_checks: true,
    allowed_to_import_with_checks: true,
    count: undefined,
    has_more: false,
  }
};

export const defaultPaginationData = {
  next: null,
  previous: null,
  count: 0,
  pages	: null,
  results: []
};
export const defaultPaginationState = {
  ...defaultState,
  data: defaultPaginationData
};
export const defaultPaginationInitial = {
  ...defaultState,
  data: defaultPaginationData,
  initialLoading: true
};
