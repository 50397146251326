import { createSelector } from "@reduxjs/toolkit";

import { IApplicationState } from "../rootInterface";

const selectState = (state: IApplicationState) => state.alerts;

export const alertsSelector = createSelector(selectState, alerts => (alerts.alerts));
export const alertGroupsAntStatusesSelector = createSelector(selectState, alerts => (alerts.alertGroupsAntStatuses));
export const alertDetailSelector = createSelector(selectState, alerts => alerts.alertDetail);
export const alertsAssignSelector = createSelector(selectState, alerts => alerts.alertsAssign);
export const alertsChangeStatusSelector = createSelector(selectState, alerts => alerts.alertsChangeStatus);
export const alertsAddCommentSelector = createSelector(selectState, alerts => alerts.alertsAddComment);
export const alertsActivitiesSelector = createSelector(selectState, alerts => alerts.alertsActivities);