import { createSlice } from "@reduxjs/toolkit";

import {
  TUserChangePasswordData,
  TUserInfoData,
  TUserSendConfirmationLinkData,
  TUserSettingsInfoData, TUserBindPhoneEmailResendCodeData
} from "../../api/branch/user";

import { defaultState } from "../constants";
import { IPayloadAction } from "../rootInterface";

import {
  TUserBindEmailPhoneCodeOptions,
  TUserBindEmailPhoneOptions,
  TUserChangePasswordOptions,
  TUserInfoOptions,
  TUserSaveLocalSettingsOptions,
  TUserStoreState, TUserBindEmailPhoneResendOptions,
  TelegramData
} from "./types";

const initialState: TUserStoreState = {
  userInfo: defaultState,
  userSaveLocalSettingsState: defaultState,
  userChangePasswordState: defaultState,
  userSettingsInfoState: defaultState,
  userSendActivateEmailLinkState: defaultState,
  userBindEmailPhoneState: defaultState,
  userBindEmailPhoneCodeState: defaultState,
  userBindEmailPhoneResendState: defaultState,
  showBindCodeForm: false,
  showBindPopup: false,
  telegramConnecting: false,
};

const userSlace = createSlice({
  name: "user",
  initialState,
  reducers: {
    bindTelegram(state, _: IPayloadAction<TelegramData>) {
      state.telegramConnecting = true;
    },
    bindTelegramSuccess(state, _) {
      state.telegramConnecting = false;
    },
    getUserInfoRequest(state, _: IPayloadAction<TUserInfoOptions>) {
      state.userInfo = {
        fetching: true,
        data: null,
        failure: null,
      };
    },
    getUserInfoSuccess(state, action: IPayloadAction<TUserInfoData>) {
      state.userInfo = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    getUserInfoSetData(state, action) {
      state.userInfo = {
        ...state.userInfo,
        data: {  ...state.userInfo.data, ...action.payload },
      };
    },
    getUserInfoFailure(state, action) {
      state.userInfo = {
        fetching: false,
        data: null,
        failure: action.payload,
      };
    },


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    userChangePasswordRequest(state, _: IPayloadAction<TUserChangePasswordOptions>) {
      state.userChangePasswordState = {
        ...state.userChangePasswordState,
        fetching: true,
        failure: null,
      };
    },
    userChangePasswordSuccess(state, action: IPayloadAction<TUserChangePasswordData>) {
      state.userChangePasswordState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    userChangePasswordFailure(state, action) {
      state.userChangePasswordState = {
        fetching: false,
        data: null,
        failure: action.payload,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    userSaveLocalSettingsRequest(state, _: IPayloadAction<TUserSaveLocalSettingsOptions>) {
      state.userSaveLocalSettingsState = {
        ...state.userSaveLocalSettingsState,
        fetching: true,
        failure: null,
      };
    },
    userSaveLocalSettingsSuccess(state, action) {
      state.userSaveLocalSettingsState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    userSaveLocalSettingsFailure(state, action) {
      state.userSaveLocalSettingsState = {
        ...state.userSaveLocalSettingsState,
        fetching: false,
        failure: action.payload,
      };
    },

    getUserSettingsInfoRequest(state) {
      state.userSettingsInfoState = {
        fetching: true,
        data: null,
        failure: null,
      };
    },
    getUserSettingsInfoSuccess(state, action: IPayloadAction<TUserSettingsInfoData>) {
      state.userSettingsInfoState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    getUserSettingsInfoFailure(state, action) {
      state.userSettingsInfoState = {
        fetching: false,
        data: null,
        failure: action.payload,
      };
    },

    userSendActivateEmailLinkRequest(state) {
      state.userSendActivateEmailLinkState = {
        ...state.userSendActivateEmailLinkState,
        fetching: true,
        failure: null,
      };
    },
    userSendActivateEmailLinkSuccess(state, action: IPayloadAction<TUserSendConfirmationLinkData>) {
      state.userSendActivateEmailLinkState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    userSendActivateEmailLinkFailure(state, action) {
      state.userSendActivateEmailLinkState = {
        fetching: false,
        data: null,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    userBindEmailPhoneRequest(state, _: IPayloadAction<TUserBindEmailPhoneOptions>) {
      state.userBindEmailPhoneState = {
        ...state.userBindEmailPhoneState,
        fetching: true,
        failure: null,
      };
    },
    userBindEmailPhoneSuccess(state, action) {
      state.userBindEmailPhoneState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    userBindEmailPhoneFailure(state, action) {
      state.userBindEmailPhoneState = {
        fetching: false,
        data: null,
        failure: action.payload,
      };
    },

    userBindEmailPhoneCodeRequest(state, _: IPayloadAction<TUserBindEmailPhoneCodeOptions>) {
      state.userBindEmailPhoneCodeState = {
        ...state.userBindEmailPhoneCodeState,
        fetching: true,
        failure: null,
      };
    },
    userBindEmailPhoneCodeSuccess(state, action) {
      state.userBindEmailPhoneCodeState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    userBindEmailPhoneCodeFailure(state, action) {
      state.userBindEmailPhoneCodeState = {
        fetching: false,
        data: null,
        failure: action.payload,
      };
    },
    userBindEmailPhoneCodeClear(state) {
      state.userBindEmailPhoneCodeState = defaultState;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    userBindEmailPhoneResendRequest(state, _: IPayloadAction<TUserBindEmailPhoneResendOptions>) {
      state.userBindEmailPhoneResendState = {
        ...state.userBindEmailPhoneResendState,
        fetching: true,
        failure: null,
      };
    },
    userBindEmailPhoneResendSuccess(state, action: IPayloadAction<TUserBindPhoneEmailResendCodeData>) {
      state.userBindEmailPhoneResendState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    userBindEmailPhoneResendFailure(state, action) {
      state.userBindEmailPhoneResendState = {
        fetching: false,
        data: null,
        failure: action.payload,
      };
    },


    showBindCodeForm(state, action) {
      state.showBindCodeForm = action.payload;
    },
    showBindPopup(state, action) {
      state.showBindPopup = action.payload;
    },
    clearUserState() {
      return initialState;
    }
  },
});

export const {
  getUserInfoFailure,
  getUserInfoSuccess,
  getUserInfoSetData,
  getUserInfoRequest,
  clearUserState,

  userChangePasswordRequest,
  userChangePasswordSuccess,
  userChangePasswordFailure,

  userSaveLocalSettingsRequest,
  userSaveLocalSettingsSuccess,
  userSaveLocalSettingsFailure,

  getUserSettingsInfoRequest,
  getUserSettingsInfoSuccess,
  getUserSettingsInfoFailure,

  userSendActivateEmailLinkRequest,
  userSendActivateEmailLinkSuccess,
  userSendActivateEmailLinkFailure,

  userBindEmailPhoneRequest,
  userBindEmailPhoneSuccess,
  userBindEmailPhoneFailure,

  userBindEmailPhoneCodeRequest,
  userBindEmailPhoneCodeSuccess,
  userBindEmailPhoneCodeFailure,
  userBindEmailPhoneCodeClear,

  userBindEmailPhoneResendRequest,
  userBindEmailPhoneResendSuccess,
  userBindEmailPhoneResendFailure,

  showBindPopup,
  showBindCodeForm,

  bindTelegram,
  bindTelegramSuccess,

} = userSlace.actions;
export default userSlace.reducer;
