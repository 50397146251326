import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getClientId, paths, pathTitle } from "config/paths";

import { useLayoutAppNameContext } from "components/Layout/hooks";

import { alertDetailSelector } from "store/alerts/selectors";
import { transfersDetailsSelector, transfersSelector } from "store/transfers/selectors";
import { clientsDetailsSelector } from "store/clients/selectors";
import { clientsDetailsRequest } from "store/clients/reducers";
import { employeeSelector } from "store/account/selectors";

import formatDate, { EFormats } from "utils/formatDate";

import {
  getTransferTypeTranslation
} from "../../../../Transfers/components/TransfersFilter/components/TransfersFilterType/utils";
import { fastChecksDetailsSelector } from "../../../../../store/fastChecks/selectors";
import { EFastCheckType } from "../../../../../store/fastChecks/types";

import { formCheckIDTitle, formIDTitle } from "../../../NewHeader/components/HeaderLeft/utils";

type TSpesificTitles = { [k in paths]?:  {
  title: string,
  subTitle?: string|JSX.Element
}}
const MobileHeaderTitle: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { routePath } = useLayoutAppNameContext();
  const { data: alertDetail } = useSelector(alertDetailSelector);
  const { data: transferDetails } = useSelector(transfersDetailsSelector);
  const { data: transfers } = useSelector(transfersSelector);
  const { data: clientDetail, fetching: clientDetailFetching } = useSelector(clientsDetailsSelector);
  const { data: fastCheckDetails, initialLoading: fastCheckDetailsInitialLoading }
    = useSelector(fastChecksDetailsSelector);
  const { data: employee } = useSelector(employeeSelector);

  const clientId = getClientId();

  useEffect(() => {
    if (clientDetail || clientDetailFetching || !clientId) return;
    dispatch(clientsDetailsRequest({ id: clientId }));
  }, [clientDetail]);

  const specificTitles: TSpesificTitles = {
    [paths.ALERTS_DETAIL]: {
      title: pathTitle(paths.ALERTS_DETAIL),
      subTitle: formIDTitle(alertDetail?.id)
    },
    [paths.ACCESS_MANAGEMENT_DETAIL]: {
      title: (() => {
        if (!employee) return "";
        return employee?.full_name ? employee.full_name : "―";
      })(),
      subTitle:  (() => {
        if (!employee) return "";
        if (employee?.registered_at) {
          return employee?.last_activity_at
            ? t("accessManagement.lastActivity") +
            " " + formatDate(employee?.last_activity_at, EFormats.T) +
            ", " + formatDate(employee?.last_activity_at, EFormats.DMMM)
            : "";
        }
        return <span className="inviteSent">{t("accessManagement.inviteSent")}</span>;
      })()
    },
    [paths.TRANSFERS_DETAILS]: {
      title: transferDetails?.type ? getTransferTypeTranslation(transferDetails?.type) : pathTitle(paths.ALERTS_DETAIL),
      subTitle: formIDTitle(transferDetails?.id)
    },
    [paths.TRANSFERS_DETAILS_ALERT]: {
      title: pathTitle(paths.TRANSFERS_DETAILS_ALERT),
      subTitle: formIDTitle(transferDetails?.id)
    },
    [paths.TRANSFERS_DETAILS_ALERTS]: {
      title: pathTitle(paths.TRANSFERS_DETAILS_ALERTS),
      subTitle: formIDTitle(transferDetails?.id)
    },
    [paths.TRANSFERS_DETAILS_ALERTS_DETAILS]: {
      title: pathTitle(paths.TRANSFERS_DETAILS_ALERTS_DETAILS),
      subTitle: formIDTitle(transferDetails?.id)
    },
    [paths.CLIENTS_DETAILS]: {
      title: t("clients.clientDetail"),
      subTitle: formIDTitle(clientDetail?.ext_id)
    },
    [paths.CLIENTS_DETAILS_TRANSFERS]: {
      title: t("clients.transfersTitleCount", { count: transfers.count }),
    },
    [paths.CLIENTS_DETAILS_ALERT]: {
      title: t("common.alerts"),
      subTitle: formIDTitle(clientDetail?.ext_id)
    },
    [paths.CLIENTS_DETAILS_ALERTS]: {
      title: t("common.alerts"),
      subTitle: formIDTitle(clientDetail?.ext_id)
    },
    [paths.CLIENTS_DETAILS_ALERTS_DETAILS]: {
      title: t("common.alerts"),
      subTitle: formIDTitle(clientDetail?.ext_id)
    },
    [paths.CLIENTS_DETAILS_TRANSFERS_DETAILS]: {
      title: t("clients.transfersTitleCount", { count: transfers.count }),
    },
    [paths.CLIENTS_DETAILS_TRANSFER]: {
      title: t("clients.clientDetail"),
      subTitle: formIDTitle(clientDetail?.ext_id)
    },
    [paths.CLIENTS_ARCHIVE_DETAILS]: {
      title: t("clients.clientsArchive"),
      subTitle: formIDTitle(clientDetail?.ext_id)
    },
    [paths.CLIENTS_ARCHIVE_DETAILS_ALERTS]: {
      title: t("common.alerts"),
      subTitle: formIDTitle(clientDetail?.ext_id)
    },
    [paths.CLIENTS_ARCHIVE_DETAILS_ALERT]: {
      title: t("common.alerts"),
      subTitle: formIDTitle(clientDetail?.ext_id)
    },
    [paths.CLIENTS_ARCHIVE_DETAILS_ALERTS_DETAILS]: {
      title: t("common.alerts"),
      subTitle: formIDTitle(clientDetail?.ext_id)
    },
    [paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS]: {
      title: t("clients.transfersTitleCount", { count: transfers.count }),
    },
    [paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS_DETAILS]: {
      title: t("clients.clientsArchive"),
      subTitle: formIDTitle(clientDetail?.ext_id)
    },
    [paths.CLIENTS_ARCHIVE_DETAILS_TRANSFER]: {
      title: t("clients.clientsArchive"),
      subTitle: formIDTitle(clientDetail?.ext_id)
    },
    [paths.FAST_CHECKS_DETAILS]: {
      title: fastCheckDetailsInitialLoading ? "" : fastCheckDetails?.type === EFastCheckType.deposit
        ? t("fastChecks.transferCheck") : t("fastChecks.addressCheck"),
      subTitle: fastCheckDetailsInitialLoading ? "" : formCheckIDTitle(fastCheckDetails?.id)
    },
    [paths.TRANSFERS_MONITORED_WALLETS]: {
      title: t("monitoredWallets.title"),
    },
    [paths.TRANSFERS_MONITORED_WALLETS_INFORMATION]: {
      title: t("monitoredWallets.walletInformation"),
    },
    [paths.TRANSFERS_MONITORED_WALLETS_INFORMATION_ARCHIVE]: {
      title: t("clients.transfersTitleCount", { count: transfers.count }),
    },
  };

  const formPageTitle = () => {
    const specificTitle = specificTitles[routePath];

    if (specificTitle) {
      return <>
        <div className="header-mobile__title__title">{specificTitle.title}</div>
        <div className="header-mobile__title__sub">{specificTitle.subTitle}</div>
      </>;
    }
    return <div className="header-mobile__title__title">{pathTitle(routePath)}</div>;
  };

  return (
    <div className="header-mobile__title__wrapper">
      {formPageTitle()}
    </div>
  );
};

export default MobileHeaderTitle;
