import request from "../request";
import { IPaginationResponse, TPaginationOptions } from "../types";

export type TInvoicesWallet = {
  id: string
  created_at: string
  network_code: string
  network_name: string
  address: string
  name: string
}
export type TInvoicesWalletsData = IPaginationResponse<TInvoicesWallet>
export type TInvoicesWalletsQuery = TPaginationOptions

export type TInvoicesWalletAddBody = {
  network?: string
  address?: string
  name?: string
}

export type TInvoicesWalletEditBody = Partial<TInvoicesWalletAddBody>

const invoices = {
  invoicesWallets: (query?: TInvoicesWalletsQuery): Promise<TInvoicesWalletsData> => 
    request<TInvoicesWalletsData>({
      path: "/invoices/wallets/",
      method: "GET",
      query,
    }),
  invoicesWalletDetails: (id: string): Promise<TInvoicesWallet> =>
    request<TInvoicesWallet>({
      path: `/invoices/wallets/${id}/`,
      method: "GET",
    }),
  invoicesWalletAdd: (body: TInvoicesWalletAddBody): Promise<TInvoicesWallet> =>
    request<TInvoicesWallet>({
      path: "/invoices/wallets/add/",
      method: "POST",
      body,
    }),
  invoicesWalletEdit: (id: string, body: TInvoicesWalletEditBody): Promise<TInvoicesWallet> =>
    request<TInvoicesWallet>({
      path: `/invoices/wallets/${id}/edit/`,
      method: "PATCH",
      body,
    }),
  invoicesWalletDelete: (id: string): Promise<unknown> =>
    request<TInvoicesWallet>({
      path: `/invoices/wallets/${id}/delete/`,
      method: "DELETE",
    }),
};

export default invoices;