import { FC } from "react";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import CryptoIcon from "components/CryptoIcon";

import isNumeric from "../../../../utils/isNumeric";

import AssetAmount from "../../../AssetAmount";

import { useTransferContext } from "../../index";

const TransferCrypto: FC = () => {
  const { t } = useTranslation();
  const transfer = useTransferContext();
  const clientExtId = get(transfer, "client_ext_id");
  const asset = get(transfer, "asset");
  const amount = get(transfer, "amount");
  const fiatCurrency = get(transfer, "fiat_currency");
  const fiatValue = get(transfer, "fiat_value");
  const anyAsset = !asset && !isNumeric(amount);

  return <div className="transfer__cel transfer-crypto">
    <CryptoIcon asset={asset} className="transfer__icon" anyAsset={anyAsset} hint={true}/>
    <div className="transfer-crypto__client-ext-id">
      {clientExtId}
    </div>
    <div className="transfer__col">
      {anyAsset
        ? <div className={cn("transfer-crypto__any-asset", { "noClientId": !clientExtId })}>
          {t("common.anyAsset")}</div>
        : (
          <AssetAmount
            amount={amount}
            asset={asset || null}
            className={cn("transfer-crypto__amount", { "noClientId": !clientExtId })}
          />)}
      {anyAsset
        ? <div className="transfer-crypto__details">
          {t("transfers.networkName", { network: transfer.network_name })}
        </div>
        : (
          <div className="transfer-crypto__details">
            {(fiatCurrency && fiatValue) && <>
              ≈ <AssetAmount
                amount={fiatValue}
                asset={fiatCurrency || null}
                className="transfer-crypto__details"
                disableSign={true}
              />
            </>
            }
          </div>)
      }

    </div>
  </div>;
};

export default TransferCrypto;
