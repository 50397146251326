import { FC, useMemo } from "react";
import { Menu as AntMenu } from "antd";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";

import { NAVIGATION_LINKS } from "config/constants";
import { paths } from "config/paths";

import { useLayoutAppNameContext } from "components/Layout/hooks";

import { getUserInfoDataSelector } from "store/user/selectors";

import { useActiveNavLink } from "../../hooks";

import SidebarBottom from "../SidebarBottom";
import SidebarCollapseLink from "../SidebarCollapseLink";

const SidebarBody: FC = () => {
  const { t } = useTranslation();
  const { logo } = useLayoutAppNameContext();
  const isActiveLink = useActiveNavLink();
  const userInfo = useSelector(getUserInfoDataSelector);

  const filteredLinks = useMemo(() => NAVIGATION_LINKS.filter(link => {
    if (link.to === paths.API_KEYS) return userInfo?.permissions.managing_api_keys;
    return link.active;
  }), [userInfo?.permissions]);
  return  (
    <PerfectScrollbar
      options={{ suppressScrollX: true }}
    >
      <NavLink to={paths.HOME} exact={true} className="sidebar__logo">
        {logo}
      </NavLink>
      <AntMenu
        mode="inline"
        className="sidebar__nav"
      >
        {filteredLinks.map(link => {
          switch (link.to) {
          case paths.CLIENTS:
          case paths.INVOICES:
          case paths.TRANSFERS:
            return <SidebarCollapseLink key={link.id} {...link}/>;
          }
          return (
            (
              <li key={link.id}>
                <div className={cn("sidebar-nav-item", { active: isActiveLink(link.to) })}>
                  <NavLink
                    isActive={() => isActiveLink(link.to)}
                    to={link.to}
                    exact={true}
                    className="sidebar-nav-item-link"
                    id={link.id}
                    activeClassName="sidebar-nav-item-link__active"
                  >
                    {t(link.title)}
                  </NavLink>
                </div>
                {link.sublinks?.map(sub => (
                  <NavLink
                    key={sub.id}
                    isActive={() => isActiveLink(sub.to)}
                    to={sub.to}
                    exact={true}
                    className="sidebar-nav-item-link-sub"
                    id={sub.id}
                    activeClassName="sidebar-nav-item-link-sub__active"
                  >
                    {sub.icon && <sub.icon />} {t(sub.title)}
                  </NavLink>
                ))}
              </li>
            )
          );
        })}
      </AntMenu>
      <SidebarBottom/>
    </PerfectScrollbar>
  );
};

export default SidebarBody;
