import { createSelector } from "@reduxjs/toolkit";

import { IApplicationState } from "../rootInterface";

const selectState = (state: IApplicationState) => state.account;

export const planSelector = createSelector(selectState, account => account?.plan);
export const checkPackHistorySelector = createSelector(selectState, account => account?.checkPackHistory);
export const employeesSelector = createSelector(selectState, account => account?.employees);
export const employeeCreateSelector = createSelector(selectState, account => account?.employeeCreate);
export const employeeSelector = createSelector(selectState, account => account?.employee);
export const employeeEditSelector = createSelector(selectState, account => account?.employeeEdit);
export const employeeResendInviteSelector = createSelector(selectState, account => account?.employeeResendInvite);
export const employeeDeleteSelector = createSelector(selectState, account => account?.employeeDelete);
export const setAlertRiskLevelSelector = createSelector(selectState, account => account?.setAlertRiskLevel);
