import { FC, useEffect } from "react";
import { Drawer } from "antd";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import { setHeaderDrawer } from "store/common/reducers";
import { headerDrawerSelector } from "store/common/selectors";

import SidebarBody from "../../../Sidebar/components/SidebarBody";

import HeaderBurger from "../HeaderBurger";

import { HeaderDrawerContext } from "./hooks";

const NewHeaderDrawer: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const visible = useSelector(headerDrawerSelector);

  const handleDrawer = () => {
    dispatch(setHeaderDrawer(!visible));
  };

  const onClose = () => {
    dispatch(setHeaderDrawer(false));
  };

  useEffect(() => {
    dispatch(setHeaderDrawer(false));
  }, [location]);

  return (
    <div className="new-header__drawer">
      <HeaderBurger active={visible} onClick={handleDrawer}/>
      <Drawer
        width={268}
        placement="left"
        closable={false}
        onClose={onClose}
        open={visible}
        className={cn("new-header__drawer__body")}
      >
        <HeaderDrawerContext.Provider value={{ visible }}>
          <SidebarBody/>
        </HeaderDrawerContext.Provider>
      </Drawer>
    </div>
  );
};

export default NewHeaderDrawer;
