import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import { paths } from "config/paths";

import ConfirmModal from "components/ConfirmModal";
import Button from "components/Buttons/Button";
import DangerIcon from "components/Icons/DangerIcon";

import { EModals } from "store/modals/types";
import { modalStateSelector } from "store/modals/selectors";
import { closeModal } from "store/modals/reducers";
import { deleteMonitoredWalletFailure, deleteMonitoredWalletRequest } from "store/monitoredWallets/reducers";
import { deleteWalletSelector, historicalImportSelector } from "store/monitoredWallets/selectors";

import compressText from "utils/compressText";

export const MonitoredWalletsDeleteModal: React.FC = () => {
  const { id } = useParams<{id: string}>();
  const deleteData = useSelector(deleteWalletSelector);
  const { data } = useSelector(historicalImportSelector);
  const [deleteTransfers, setDeleteTransfers] = useState<boolean>(false);
  const walletModal = useSelector(modalStateSelector(EModals.monitoredWalletsDelete));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (deleteData.data) {
      dispatch(deleteMonitoredWalletFailure(null));
      dispatch(closeModal(EModals.monitoredWalletsDelete));
      history.push(paths.TRANSFERS_MONITORED_WALLETS);
    }
  }, [deleteData.data]);

  const handleDelete = () => {
    if (!deleteData.fetching) {
      dispatch(deleteMonitoredWalletRequest({ id: id || data?.id as string, delete_transfers: deleteTransfers }));
    }
  };

  const onChange = (event: CheckboxChangeEvent) => {
    setDeleteTransfers(event.target.checked);
  };

  const onClose = () => {
    dispatch(closeModal(EModals.monitoredWalletsDelete));
  };

  return <ConfirmModal
    icon={<DangerIcon />}
    className="monitored-wallets-delete-modal"
    title={t("myWallets.walletDeleteTitle", { name: `${compressText(data?.address as string)}` })}
    text={t("monitoredWallets.deleteDescription")}
    visible={!!walletModal?.open}
    onCancel={onClose}
    onOk={handleDelete}
    btns={
      <>
        <div className="checkbox">
          <Checkbox checked={deleteTransfers} onChange={onChange}>{t("monitoredWallets.deleteTransferWallet")}
          </Checkbox>
        </div>
        <div>
          <Button
            title={t("action.cancel")}
            transparent={true}
            onClick={onClose}
            disabled={deleteData.fetching}
          />
          <Button
            title={t('action.delete')}
            danger={true}
            loading={deleteData.fetching}
            onClick={handleDelete}
            disabled={deleteData.fetching}
          />
        </div>
      </>
    }
  />;
};
