import { createSlice } from "@reduxjs/toolkit";

import { defaultState } from "../constants";

import {
  TAmlStoreState
} from "./types";

const initialState: TAmlStoreState = {
  amlRiskSummaryState: defaultState,
  amlActivateDropdown: false,
};

const amlSlice = createSlice({
  name: "aml",
  initialState,
  reducers: {
    clearAmlState() {
      return initialState;
    },

    amlRiskSummaryRequest(state) {
      state.amlRiskSummaryState = {
        ...state.amlRiskSummaryState,
        fetching: true,
      };
    },
    amlRiskSummarySuccess(state, action) {
      state.amlRiskSummaryState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRiskSummaryFailure(state, action) {
      state.amlRiskSummaryState = {
        ...state.amlRiskSummaryState,
        fetching: false,
        failure: action.payload,
      };
    },

    setAmlActivateDropdown(state, action) {
      state.amlActivateDropdown = action.payload;
    },

  }
});

export const {
  clearAmlState,

  amlRiskSummaryRequest,
  amlRiskSummarySuccess,
  amlRiskSummaryFailure,

  setAmlActivateDropdown
} = amlSlice.actions;
export default amlSlice.reducer;
