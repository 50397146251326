import { FC, SVGProps } from "react";


{/* eslint-disable max-len */}
const WalletIcon:FC<SVGProps<SVGSVGElement>> = (props):JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_20095_21350)">
      <path d="M14.5712 0.859375H1.42836C1.11229 0.859375 0.856934 1.11473 0.856934 1.4308V14.5737C0.856934 14.8897 1.11229 15.1451 1.42836 15.1451H14.5712C14.8873 15.1451 15.1426 14.8897 15.1426 14.5737V1.4308C15.1426 1.11473 14.8873 0.859375 14.5712 0.859375ZM13.8569 9.14509H8.28551V6.85938H13.8569V9.14509ZM13.8569 13.8594H2.14265V2.14509H13.8569V5.71652H7.71408C7.39801 5.71652 7.14265 5.97188 7.14265 6.28795V9.71652C7.14265 10.0326 7.39801 10.2879 7.71408 10.2879H13.8569V13.8594ZM9.21408 8.00223C9.21408 8.19167 9.28933 8.37335 9.42329 8.50731C9.55724 8.64126 9.73892 8.71652 9.92836 8.71652C10.1178 8.71652 10.2995 8.64126 10.4334 8.50731C10.5674 8.37335 10.6426 8.19167 10.6426 8.00223C10.6426 7.81279 10.5674 7.63111 10.4334 7.49716C10.2995 7.3632 10.1178 7.28795 9.92836 7.28795C9.73892 7.28795 9.55724 7.3632 9.42329 7.49716C9.28933 7.63111 9.21408 7.81279 9.21408 8.00223Z" fill="#D64686"/>
    </g>
    <defs>
      <clipPath id="clip0_20095_21350">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);

export default WalletIcon;
