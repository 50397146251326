import { PayloadAction } from "@reduxjs/toolkit";

import { EModals } from "./types";

export const OPEN_MODAL = "OPEN_MODAL";

type OpenModalDataRequired<T> = {
  modal: EModals,
  data: T
}

type OpenModalDataOptional = {
  modal: EModals,
  data?: undefined | null
}

type OpenModalProps<T = void> = T extends void ? OpenModalDataOptional : OpenModalDataRequired<T>
export function openModal<T = void>({
  modal, data
}: OpenModalProps<T>):
  PayloadAction<EModals | { modal: EModals, data: T }> {
  return ({
    type: OPEN_MODAL,
    payload: {
      modal,
      data: (data||null) as T,
    }
  });
}

openModal.type = OPEN_MODAL;