import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosError, AxiosResponse } from "axios";

import request from "../../api";

import { IPayloadAction } from "../rootInterface";

import {
  explorerFindAddressFailure,
  explorerFindAddressOrTransactionFailure,
  explorerFindAddressOrTransactionRequest,
  explorerFindAddressOrTransactionSuccess,
  explorerFindAddressRequest,
  explorerFindAddressSuccess,
  explorerFindTransferFailure,
  explorerFindTransferRequest,
  explorerFindTransferSuccess
} from "./reducers";
import {
  TExplorerFindAddressOrTransaction,
  TExplorerFindTransferOptions, TExplorerFoundAddress,
  TExplorerTransferData, TFindAddressOptions,
  TFindAddressOrTransactionSOptions
} from "./types";


function* explorerFindTransfer(action: IPayloadAction<TExplorerFindTransferOptions>) {
  try {
    const response: AxiosResponse<TExplorerTransferData> =
      yield call(request.post, "/explorer/find-transfers/", action.payload.body);
    yield put(explorerFindTransferSuccess(response.data));
  } catch (error) {
    yield put(explorerFindTransferFailure(error));
  }
}

function* explorerFindAddressOrTransaction(action: IPayloadAction<TFindAddressOrTransactionSOptions>) {
  try {
    const response: AxiosResponse<TExplorerFindAddressOrTransaction> =
      yield call(request.post, "/explorer/find-address-or-transaction/", action.payload.body);
    yield put(explorerFindAddressOrTransactionSuccess(response.data));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess(response.data);
  } catch (error) {
    yield put(explorerFindAddressOrTransactionFailure(error));
  }
}

function* explorerFindAddress(action: IPayloadAction<TFindAddressOptions>) {
  try {
    const response: AxiosResponse<TExplorerFoundAddress> =
      yield call(request.post, "/monitored-wallets/find-address-to-add/", action.payload.body );
    yield put(explorerFindAddressSuccess(response.data));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess(response.data);
  } catch (error) {
    const serverSerror = error as AxiosError;
    if (serverSerror.response?.status === 400) {
      yield put(explorerFindAddressFailure((serverSerror.response?.data as Record<string, string>)?.address));
      return;
    }
    yield put(explorerFindAddressFailure(error));
  }
}


function* Saga(): Generator {
  yield all([
    takeLatest(explorerFindTransferRequest.type, explorerFindTransfer),
    takeLatest(explorerFindAddressOrTransactionRequest.type, explorerFindAddressOrTransaction),
    takeLatest(explorerFindAddressRequest.type, explorerFindAddress),
  ]);
}

export default Saga;
