import sortBy from "lodash/sortBy";
import groupBy from "lodash/groupBy";
import dayjs from "dayjs";

import i18n from "../i18n";

import { setDateTimeLocale } from "./dateHelpers";
interface Dictionary<T> {
  [Key: string]: T;
}
export type GroupedTransactions<T> = Dictionary<[...T[]]>

const getGroupedTransactions = <T extends Record<string, string|undefined|number|null>, K extends keyof T>(
  transactions: T[], key: K
): GroupedTransactions<T> => {
  setDateTimeLocale();
  const sort = sortBy(transactions, key);
  return groupBy(sort.reverse(), transaction => dayjs(transaction[key]).locale(i18n.language).format("DD MMM, YYYY"));
};

export default getGroupedTransactions;