import user from "./branch/user";
import account from "./branch/account";
import invoices from "./branch/invoices";

const api = {
  user,
  account,
  invoices,
};

export default api;