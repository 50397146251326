import { createSlice } from "@reduxjs/toolkit";


import {
  TAccountCheckPackHistoryData, TAccountEmployeeData, TAccountEmployeeDeleteData, TAccountEmployeeResendInviteData,
  TAccountEmployeesCreateData,
  TAccountEmployeesData,
  TAccountPlanData, TAccountSetAlertRiskLevelData
} from "../../api/branch/account";

import { defaultState } from "../constants";
import { IPayloadAction } from "../rootInterface";

import {
  TAccountStoreState,
  TCheckPackHistoryOptions,
  TEmployeeCreateOptions, TEmployeeDeleteOptions,
  TEmployeeEditOptions,
  TEmployeeOptions,
  TEmployeeResendInviteOptions, TSetAlertRiskLevelOptions,
} from "./types";

const initialState: TAccountStoreState = {
  plan: defaultState,
  checkPackHistory: defaultState,
  employees: defaultState,
  employeeCreate: defaultState,
  employee: defaultState,
  employeeEdit: defaultState,
  employeeResendInvite: defaultState,
  employeeDelete: defaultState,
  setAlertRiskLevel: defaultState,
};

const commonSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    planRequest(state) {
      state.plan = {
        ...state.plan,
        fetching: true,
      };
    },
    planSuccess(state, action: IPayloadAction<TAccountPlanData>) {
      state.plan = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    planFailure(state, action) {
      state.plan = {
        ...state.plan,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    checkPackHistoryRequest(state, _: IPayloadAction<TCheckPackHistoryOptions>) {
      state.checkPackHistory = {
        ...state.checkPackHistory,
        fetching: true,
      };
    },
    checkPackHistorySuccess(state, action: IPayloadAction<TAccountCheckPackHistoryData>) {
      state.checkPackHistory = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    checkPackHistoryFailure(state, action) {
      state.checkPackHistory = {
        ...state.checkPackHistory,
        fetching: false,
        failure: action.payload,
      };
    },

    employeesRequest(state) {
      state.employees = {
        ...state.employees,
        fetching: true,
      };
    },
    employeesSuccess(state, action: IPayloadAction<TAccountEmployeesData>) {
      state.employees = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    employeesAdd(state, action: IPayloadAction<TAccountEmployeesData>) {
      state.employees = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    employeesFailure(state, action) {
      state.employees = {
        ...state.employees,
        fetching: false,
        failure: action.payload,
      };
    },
    employeesUnshift(state, action) {
      state.employees.data?.unshift(action.payload);
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    employeeCreateRequest(state, _: IPayloadAction<TEmployeeCreateOptions>) {
      state.employeeCreate = {
        ...state.employeeCreate,
        fetching: true,
      };
    },
    employeeCreateSuccess(state, action: IPayloadAction<TAccountEmployeesCreateData>) {
      state.employeeCreate = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    employeeCreateFailure(state, action) {
      state.employeeCreate = {
        ...state.employeeCreate,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    employeeRequest(state, _: IPayloadAction<TEmployeeOptions>) {
      state.employee = {
        ...state.employee,
        fetching: true,
      };
    },
    employeeSuccess(state, action: IPayloadAction<TAccountEmployeeData>) {
      state.employee = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    employeeFailure(state, action) {
      state.employee = {
        ...state.employee,
        fetching: false,
        failure: action.payload,
      };
    },
    employeeClear(state) {
      state.employee = defaultState;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    employeeEditRequest(state, _: IPayloadAction<TEmployeeEditOptions>) {
      state.employeeEdit = {
        ...state.employeeEdit,
        fetching: true,
      };
    },
    employeeEditSuccess(state, action: IPayloadAction<TAccountEmployeeData>) {
      state.employeeEdit = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    employeeEditFailure(state, action) {
      state.employeeEdit = {
        ...state.employee,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    employeeResendInviteRequest(state, _: IPayloadAction<TEmployeeResendInviteOptions>) {
      state.employeeResendInvite = {
        ...state.employeeResendInvite,
        fetching: true,
      };
    },
    employeeResendInviteSuccess(state, action: IPayloadAction<TAccountEmployeeResendInviteData>) {
      state.employeeResendInvite = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    employeeResendInviteFailure(state, action) {
      state.employeeResendInvite = {
        ...state.employee,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    employeeDeleteRequest(state, _: IPayloadAction<TEmployeeDeleteOptions>) {
      state.employeeDelete = {
        ...state.employeeDelete,
        fetching: true,
      };
    },
    employeeDeleteSuccess(state, action: IPayloadAction<TAccountEmployeeDeleteData>) {
      state.employeeDelete = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    employeeDeleteFailure(state, action) {
      state.employeeDelete = {
        ...state.employee,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setAlertRiskLevelRequest(state, _: IPayloadAction<TSetAlertRiskLevelOptions>) {
      state.setAlertRiskLevel = {
        ...state.setAlertRiskLevel,
        fetching: true,
      };
    },
    setAlertRiskLevelSuccess(state, action: IPayloadAction<TAccountSetAlertRiskLevelData>) {
      state.setAlertRiskLevel = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    setAlertRiskLevelFailure(state, action) {
      state.setAlertRiskLevel = {
        ...state.setAlertRiskLevel,
        fetching: false,
        failure: action.payload,
      };
    },
  },
});

export const {
  planRequest,
  planSuccess,
  planFailure,

  checkPackHistoryRequest,
  checkPackHistorySuccess,
  checkPackHistoryFailure,

  employeesRequest,
  employeesSuccess,
  employeesFailure,
  employeesUnshift,

  employeeCreateRequest,
  employeeCreateSuccess,
  employeeCreateFailure,

  employeeRequest,
  employeeSuccess,
  employeeFailure,
  employeeClear,

  employeeEditRequest,
  employeeEditSuccess,
  employeeEditFailure,

  employeeResendInviteRequest,
  employeeResendInviteSuccess,
  employeeResendInviteFailure,

  employeeDeleteRequest,
  employeeDeleteSuccess,
  employeeDeleteFailure,

  setAlertRiskLevelRequest,
  setAlertRiskLevelSuccess,
  setAlertRiskLevelFailure,
} = commonSlice.actions;

export default commonSlice.reducer;
