import { createSlice } from "@reduxjs/toolkit";


import { defaultState } from "../constants";
import { IPayloadAction } from "../rootInterface";

import {
  TBasicAssetsData,
  TBasicAssetsOptions,
  TBasicBlockchainsData, TBasicBlockchainsOptions, TBasicEntityCategoryData, TBasicEntityCategoryOptions,
  TBasicsStoreState, TBasicUsers,
  TCounterpartiesData,
  TCounterpartiesOptions
} from "./types";

const initialState: TBasicsStoreState = {
  counterparties: defaultState,
  assets: defaultState,
  blockchains: defaultState,
  entityCategories: defaultState,
  basicUsers: defaultState,
};

const basicsSlice = createSlice({
  name: "basics",
  initialState,
  reducers: {
    counterpartiesRequest(state, _: IPayloadAction<TCounterpartiesOptions>) {
      state.counterparties = {
        ...state.counterparties,
        fetching: true
      };
    },
    counterpartiesSuccess(state, action: IPayloadAction<TCounterpartiesData>) {
      state.counterparties = {
        ...state.counterparties,
        fetching: false,
        data: action.payload
      };
    },
    counterpartiesFailure(state, action) {
      state.counterparties = {
        ...state.counterparties,
        fetching: false,
        failure: action.payload
      };
    },

    assetsRequest(state, _: IPayloadAction<TBasicAssetsOptions>) {
      state.assets = {
        ...state.assets,
        fetching: true
      };
    },
    assetsSuccess(state, action: IPayloadAction<TBasicAssetsData>) {
      state.assets = {
        ...state.assets,
        fetching: false,
        data: action.payload
      };
    },
    assetsFailure(state, action) {
      state.assets = {
        ...state.assets,
        fetching: false,
        failure: action.payload
      };
    },

    blockchainsRequest(state, _: IPayloadAction<TBasicBlockchainsOptions>) {
      state.blockchains = {
        ...state.blockchains,
        fetching: true
      };
    },
    blockchainsSuccess(state, action: IPayloadAction<TBasicBlockchainsData>) {
      state.blockchains = {
        ...state.blockchains,
        fetching: false,
        data: action.payload
      };
    },
    blockchainsFailure(state, action) {
      state.blockchains = {
        ...state.blockchains,
        fetching: false,
        failure: action.payload
      };
    },

    entityCategoriesRequest(state, _: IPayloadAction<TBasicEntityCategoryOptions>) {
      state.entityCategories = {
        ...state.entityCategories,
        fetching: true
      };
    },
    entityCategoriesSuccess(state, action: IPayloadAction<TBasicEntityCategoryData>) {
      state.entityCategories = {
        ...state.entityCategories,
        fetching: false,
        data: action.payload
      };
    },
    entityCategoriesFailure(state, action) {
      state.entityCategories = {
        ...state.entityCategories,
        fetching: false,
        failure: action.payload
      };
    },

    basicUsersRequest(state) {
      state.basicUsers = {
        ...state.basicUsers,
        fetching: true
      };
    },
    basicUsersSuccess(state, action: IPayloadAction<TBasicUsers[]>) {
      state.basicUsers = {
        ...state.basicUsers,
        fetching: false,
        data: action.payload
      };
    },
    basicUsersFailure(state, action) {
      state.basicUsers = {
        ...state.basicUsers,
        fetching: false,
        failure: action.payload
      };
    },
  },
});

export const {
  counterpartiesRequest,
  counterpartiesSuccess,
  counterpartiesFailure,

  assetsRequest,
  assetsSuccess,
  assetsFailure,
  
  blockchainsRequest,
  blockchainsSuccess,
  blockchainsFailure,

  entityCategoriesRequest,
  entityCategoriesSuccess,
  entityCategoriesFailure,

  basicUsersRequest,
  basicUsersSuccess,
  basicUsersFailure,
} = basicsSlice.actions;

export default basicsSlice.reducer;
