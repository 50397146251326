import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import request from "api";

import { TPaginationOptions } from "api/types";

import { paths } from "config/paths";

import { getRiskGradientColor } from "utils/colorGradient/RiskGradient";
import hexToRgb from "utils/hexToRgb";
import delay from "utils/delay";

import { IPayloadAction } from "../rootInterface";
import { ECounterpartyStatus, EExposureStatus } from "../transfers/types";

import {
  fastChecksCheckAddressFailure,
  fastChecksCheckAddressRequest,
  fastChecksCheckAddressSuccess,
  fastChecksCheckTransferFailure,
  fastChecksCheckTransferRequest,
  fastChecksCheckTransferSuccess,
  fastChecksDetailsFailure,
  fastChecksDetailsRequest,
  fastChecksDetailsSuccess,
  fastChecksFailure,
  fastChecksRecheckFailure,
  fastChecksRecheckRequest,
  fastChecksRecheckSuccess,
  fastChecksRequest,
  fastChecksRisksFailure,
  fastChecksRisksRequest,
  fastChecksRisksSuccess,
  fastChecksSuccess,
  getFastGroupsSuccess, getFastGroupsRequest, getFastGroupsFailure,
} from "./reducers";
import {
  TFastChecksCheckAddress,
  TFastChecksCheckAddressOptions,
  TFastChecksChecksRiskOptions,
  TFastChecksCheckTransfer,
  TFastChecksCheckTransferOptions,
  TFastChecksData,
  TFastChecksDetails,
  TFastChecksDetailsOptions,
  TFastChecksFilter,
  TFastChecksFilterKeys,
  TFastChecksOptions,
  TFastChecksRisksData
} from "./types";

const fastChecksParams = (
  filter: TFastChecksFilter|undefined,
  pagination: TPaginationOptions|undefined
) => {
  const params = new URLSearchParams();

  if (filter?.type) params.set("type", String(filter?.type));
  if (filter?.network) params.set("network", String(filter?.network));
  if (filter?.date_from) params.set("created_from", String(filter?.[TFastChecksFilterKeys.date_from]));
  if (filter?.date_to) params.set("created_to", String(filter?.[TFastChecksFilterKeys.date_to]));
  if (filter?.search) params.set("search", String(filter?.search));
  if (filter?.group) params.set("group", String(filter?.group));
  if (pagination?.size) params.set("size", String(pagination?.size));
  if (pagination?.page) params.set("page", String(pagination?.page));

  return params;
};

function* _fastChecksRequest(action: IPayloadAction<TFastChecksOptions>) {
  const params = fastChecksParams(action.payload.filter, action.payload.pagination);
  try {
    const response: AxiosResponse<TFastChecksData> = yield call(request.get, "/manual-checks/", { params });
    yield put(fastChecksSuccess({ data: response.data, infiniteScroll: action.payload.infiniteScroll }));
    return response;
  } catch (error) {
    yield put(fastChecksFailure(error));
  }
}

function* getFastGroups() {
  try {
    const response: AxiosResponse<TFastChecksData> = yield call(request.get, "/manual-checks/groups");
    yield put(getFastGroupsSuccess(response.data));
    return response;
  } catch (error) {
    yield put(getFastGroupsFailure(error));
  }
}

function* _fastChecksRequestRecheck(action: IPayloadAction<TFastChecksOptions>): unknown {
  yield delay(5000);
  const { pathname } = location;
  const response: AxiosResponse<TFastChecksData> = yield call(_fastChecksRequest, action);
  const recheckRequired = response.data.results.some(check => check.status === 'checking');

  if (recheckRequired && pathname === paths.FAST_CHECKS) {
    yield call(_fastChecksRequestRecheck, action);
  }
}

function* fastChecks(action: IPayloadAction<TFastChecksOptions>) {
  const response: AxiosResponse<TFastChecksData> = yield call(_fastChecksRequest, action);

  const recheckRequired = response.data.results.some(check => check.status === 'checking');
  if (recheckRequired) {
    yield call(_fastChecksRequestRecheck, action);
  }
}

function* fastChecksCheckTransfer(action: IPayloadAction<TFastChecksCheckTransferOptions>) {
  try {
    const response: AxiosResponse<TFastChecksCheckTransfer> = yield call(request.post,
      "/manual-checks/check-transfer/", action.payload.body);
    yield put(fastChecksCheckTransferSuccess(response.data));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess(response.data.id);
  } catch (error) {
    yield put(fastChecksCheckTransferFailure(error));
  }
}

function* fastChecksCheckAddress(action: IPayloadAction<TFastChecksCheckAddressOptions>) {
  try {
    const response: AxiosResponse<TFastChecksCheckAddress> = yield call(request.post,
      "/manual-checks/check-address/", action.payload.body);
    yield put(fastChecksCheckAddressSuccess(response.data));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess(response.data.id);
  } catch (error) {
    yield put(fastChecksCheckAddressFailure(error));
  }
}

function* fastChecksDetails(action: IPayloadAction<TFastChecksDetailsOptions>) {
  try {
    const response: AxiosResponse<TFastChecksDetails> = yield call(request.get,
      `/manual-checks/${action.payload.id}/`);

    const addressExposure = [...response.data?.address_exposure?.exposure || []].sort((a, b) => b.share - a.share );
    const indirectExposure = [...response.data?.transfer_exposure?.indirect_exposure || []]
      .sort((a, b) => b.share - a.share );

    yield put(fastChecksDetailsSuccess({
      ...response.data,
      address_exposure: {
        ...response.data?.address_exposure,
        exposure: addressExposure.map(item => {
          const color = getRiskGradientColor(item.risk_score);
          return ({
            ...item,
            color,
            rgb: color ? hexToRgb(color) : undefined
          });
        })
      },
      transfer_exposure: {
        ...response.data?.transfer_exposure,
        indirect_exposure: indirectExposure.map(item => {
          const color = getRiskGradientColor(item.risk_score);
          return ({
            ...item,
            color,
            rgb: color ? hexToRgb(color) : undefined
          });
        })
      },
    }));

    if (response.data.address_exposure_status === ECounterpartyStatus.checking
      || response.data.transfer_exposure_status === EExposureStatus.checking) {
      yield delay(5000);
      yield put(fastChecksDetailsRequest({
        ...action.payload,
        prev_address_exposure_status: response.data.address_exposure_status,
        prev_transfer_exposure_status: response.data.transfer_exposure_status,
      }));
    }
    if ((
      action.payload.prev_transfer_exposure_status === EExposureStatus.checking
        && response.data.transfer_exposure_status === EExposureStatus.checked
    )
      || (
        action.payload.prev_address_exposure_status === ECounterpartyStatus.checking
        && response.data.address_exposure_status === ECounterpartyStatus.checked
      )
    ) {
      yield put(fastChecksRisksRequest({
        id: action.payload.id
      }));
    }

  } catch (error) {
    yield put(fastChecksDetailsFailure(error));
  }
}

function* fastChecksRisks(action: IPayloadAction<TFastChecksChecksRiskOptions>) {
  try {
    const response: AxiosResponse<TFastChecksRisksData> = yield call(request.get,
      `/manual-checks/${action.payload.id}/risks/`);

    yield put(fastChecksRisksSuccess(response.data));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess(response.data);
  } catch (error) {
    yield put(fastChecksRisksFailure(error));
  }
}

function* fastChecksRecheck(action: IPayloadAction<{ id: string}>) {
  try {
    const response: AxiosResponse<unknown> = yield call(request.post,
      `/manual-checks/${action.payload.id}/recheck/`);

    yield put(fastChecksRecheckSuccess(response.data));
    yield put(fastChecksDetailsRequest({ id: action.payload.id }));
  } catch (error) {
    yield put(fastChecksRecheckFailure(error));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(fastChecksRequest.type, fastChecks),
    takeLatest(fastChecksCheckTransferRequest.type, fastChecksCheckTransfer),
    takeLatest(fastChecksCheckAddressRequest.type, fastChecksCheckAddress),
    takeLatest(fastChecksDetailsRequest.type, fastChecksDetails),
    takeLatest(fastChecksRisksRequest.type, fastChecksRisks),
    takeLatest(fastChecksRecheckRequest.type, fastChecksRecheck),
    takeLatest(getFastGroupsRequest.type, getFastGroups),
  ]);
}

export default Saga;
