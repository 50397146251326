import { FC, useMemo } from "react";
import cn from "classnames";
import type { AlignType } from 'rc-trigger/lib/interface';
import { useMediaQuery } from "react-responsive";

import Hint from "components/Hint";

import formatAssetAmount, { FormatAssetAmount } from "utils/formatAssetAmount";
import eToNumber from "utils/eToNumber";

import { maxMobileMediaWidth } from "../../config/constants";

type AssetAmountProps = FormatAssetAmount & { className?: string, align?: AlignType }

const AssetAmount: FC<AssetAmountProps>  = ({
  amount,
  asset,
  sign,
  disableSign,
  className,
  align,
  normalizeFrom
}) => {
  const isMaxMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });
  const assetAmount = useMemo(() =>
    formatAssetAmount({ amount, asset, sign, disableSign, normalizeFrom }),
  [amount, asset, sign, disableSign, normalizeFrom]);

  const amountTag = <div className={cn("asset-amount", className)}>
    {assetAmount}
  </div>;

  if (isMaxMobile) {
    return amountTag;
  }

  return (
    <Hint
      content={<span>{eToNumber(amount || 0)}{'\u00A0'}{asset}</span>}
      trigger="hover"
      align={align}
    >
      {amountTag}
    </Hint>
  );
};

export default AssetAmount;
