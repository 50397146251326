import { put, takeLatest, all, call } from "redux-saga/effects";
import { AxiosError } from "axios";
import isFunction from "lodash/isFunction";
import { TToken } from "api";

import request from "api/tracker";

import {
  getUserInfoRequest,
  getUserInfoFailure,
  getUserInfoSuccess,
  userSaveLocalSettingsSuccess,
  getUserInfoSetData,
  userSaveLocalSettingsFailure,
  userSaveLocalSettingsRequest,
  getUserSettingsInfoRequest,
  getUserSettingsInfoSuccess,
  getUserSettingsInfoFailure,
  userSendActivateEmailLinkRequest,
  userSendActivateEmailLinkSuccess,
  userSendActivateEmailLinkFailure,
  userBindEmailPhoneCodeRequest,
  showBindPopup,
  userBindEmailPhoneCodeSuccess,
  userBindEmailPhoneCodeFailure,
  userBindEmailPhoneResendRequest,
  userBindEmailPhoneResendFailure,
  userBindEmailPhoneResendSuccess,
  userBindEmailPhoneRequest,
  showBindCodeForm,
  userBindEmailPhoneSuccess,
  userBindEmailPhoneFailure,
  userChangePasswordRequest,
  userChangePasswordSuccess,
  userChangePasswordFailure,
  bindTelegram
} from "store/user/reducers";
import { checkIsAuth, getBlockingTime, getCodeToken } from "store/auth/reducers";
import {
  TUserBindEmailPhoneCodeOptions, TUserBindEmailPhoneOptions,
  TUserChangePasswordOptions,
  TUserInfoOptions,
  TUserSaveLocalSettingsOptions, TUserBindEmailPhoneResendOptions,
  TelegramData
} from "store/user/types";

import { setGoogleUserId } from "utils/googleAnalytic";
import { showError, showSuccess } from "utils/notifications";

import i18n from "../../i18n";
import { EStorageKeys } from "../../utils/storageHeplers";
import { errorTextHandler } from "../../utils/errorApiHandler";
import { getRecaptchaToken } from "../../utils/recaptchaExecute";
import api from "../../api/api";
import {
  TUserChangePasswordData,
  TUserInfoData,
  TUserSaveLocalSettingsData, TUserSendConfirmationLinkData,
  TUserSettingsInfoData, TUserBindPhoneEmailResendCodeData, UserBindPhoneEmailStep1Data
} from "../../api/branch/user";

import { IPayloadAction } from "../rootInterface";

function* getUserInfo(action: IPayloadAction<TUserInfoOptions>) {
  try {
    const data: TUserInfoData = yield call(api.user.userInfo);

    setGoogleUserId(data.id);

    yield put(checkIsAuth(true));
    yield put(getUserInfoSuccess(data));
    if (isFunction(action.payload?.callOnSuccess)) action.payload.callOnSuccess();
  } catch (e) {
    yield put(getUserInfoFailure(e));
  }
}

function* bindTelegramEffect(action: IPayloadAction<TelegramData>): any {
  try {
    const data = yield call(request.post, '/user/connect-oauth/', action.payload);
    return data;
    // yield put(getUserInfoSuccess(dta));
  } catch (e) {
    showError(errorTextHandler(e as AxiosError));
  }
}

function* putUserChangePassword(action: IPayloadAction<TUserChangePasswordOptions>) {
  try {
    const data: TUserChangePasswordData = yield call(api.user.userChangePassword, action.payload.body);
    action.payload.callOnSuccess();
    yield put(userChangePasswordSuccess(data));
  } catch (e) {
    yield put(userChangePasswordFailure(e));
  }
}

function* userSaveLocalSettings(action: IPayloadAction<TUserSaveLocalSettingsOptions>) {
  const { body, callOnSuccess } = action.payload;
  try {
    const data: TUserSaveLocalSettingsData = yield call(api.user.userSaveLocalSettings, body);
    yield put(userSaveLocalSettingsSuccess(data));
    yield put(getUserInfoSetData(data));
    if (isFunction(callOnSuccess)) callOnSuccess();
  } catch (e) {
    yield put(userSaveLocalSettingsFailure(e));
  }
}


function* getUserSettings() {
  try {
    const data: TUserSettingsInfoData = yield call(api.user.userAuthSettings);
    yield put(getUserSettingsInfoSuccess(data));
  } catch (e) {
    yield put(getUserSettingsInfoFailure(e));
  }
}

function* getUserSendActivateEmailLink() {
  try {
    const data: TUserSendConfirmationLinkData = yield call(api.user.userSendConfirmationLink);
    yield put(userSendActivateEmailLinkSuccess(data));
    showSuccess(i18n.t("notification.getUserSendActivateEmailLink"));
  } catch (e) {
    yield put(userSendActivateEmailLinkFailure(e));
  }
}

function* postBindEmailPhoneRequest(action: IPayloadAction<TUserBindEmailPhoneOptions>) {
  try {
    const recaptcha: string = yield call(getRecaptchaToken);
    const data: UserBindPhoneEmailStep1Data = yield call(
      api.user.userBindPhoneEmailStep1, { ...action.payload.body, recaptcha });

    yield put(userBindEmailPhoneSuccess(data));
    yield put(showBindCodeForm(true));
    yield put(getBlockingTime(data.blocking_time));
    yield put(getCodeToken(data.code_token));
    localStorage.setItem(EStorageKeys.TIMER, data.blocking_time);
    showSuccess(i18n.t("notification.postBindEmailPhoneRequest"));
  } catch (e) {
    yield put(userBindEmailPhoneFailure(e));
  }
}

function* postBindEmailPhoneCodeRequest(action: IPayloadAction<TUserBindEmailPhoneCodeOptions>) {
  try {
    const data: TToken = yield call(api.user.userBindPhoneEmailStep2, action.payload.body);
    yield put(userBindEmailPhoneCodeSuccess(data));
    yield put(showBindPopup(false));
    // setStorage(EStorageKeys.TOKEN, response.data);;
    showSuccess(i18n.t("notification.postBindEmailPhoneCodeRequest"));
  } catch (e) {
    yield put(userBindEmailPhoneCodeFailure(e));
  }
}

function* postBindEmailPhoneResendRequest(action: IPayloadAction<TUserBindEmailPhoneResendOptions>) {
  try {
    const data: TUserBindPhoneEmailResendCodeData =
      yield call(api.user.userBindPhoneEmailResendCode, action.payload.body);

    yield put(userBindEmailPhoneResendSuccess(data));
    yield put(getBlockingTime(data.blocking_time));
    yield put(getCodeToken(data.code_token));
    localStorage.setItem(EStorageKeys.TIMER, data.blocking_time);
    showSuccess(i18n.t("notification.postBindEmailPhoneResendRequest"));
  } catch (e) {
    yield put(userBindEmailPhoneResendFailure(e));
    showError(errorTextHandler(e as AxiosError));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(getUserInfoRequest.type, getUserInfo),
    takeLatest(bindTelegram.type, bindTelegramEffect),
    takeLatest(userChangePasswordRequest.type, putUserChangePassword),
    takeLatest(userSaveLocalSettingsRequest.type, userSaveLocalSettings),
    takeLatest(getUserSettingsInfoRequest.type, getUserSettings),
    takeLatest(userSendActivateEmailLinkRequest.type, getUserSendActivateEmailLink),
    takeLatest(userBindEmailPhoneRequest.type, postBindEmailPhoneRequest),
    takeLatest(userBindEmailPhoneCodeRequest.type, postBindEmailPhoneCodeRequest),
    takeLatest(userBindEmailPhoneResendRequest.type, postBindEmailPhoneResendRequest),
  ]);
}

export default Saga;
