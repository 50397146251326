import { all, call, put, takeLatest } from "redux-saga/effects";

import { TPaginationOptions } from "api/types";
import { TInvoicesWallet, TInvoicesWalletsData } from "api/branch/invoices";
import api from "api/api";

import { IPayloadAction } from "../rootInterface";

import {
  invoicesFailure,
  invoicesRequest,
  invoicesSuccess,
  invoicesWalletAddFailure,
  invoicesWalletAddRequest,
  invoicesWalletAddSuccess, invoicesWalletDeleteFailure,
  invoicesWalletDeleteRequest, invoicesWalletDeleteSuccess,
  invoicesWalletDetailsFailure,
  invoicesWalletDetailsRequest,
  invoicesWalletDetailsSuccess,
  invoicesWalletEditFailure,
  invoicesWalletEditRequest,
  invoicesWalletEditSuccess, invoicesWalletsAppend, invoicesWalletsEdit,
  invoicesWalletsFailure, invoicesWalletsRemove,
  invoicesWalletsRequest,
  invoicesWalletsSuccess
} from "./reducers";
import {
  TInvoicesFilter,
  TInvoicesOptions,
  TInvoicesWalletAddOptions, TInvoicesWalletDeleteOptions,
  TInvoicesWalletDetailsOptions, TInvoicesWalletEditOptions,
  TInvoicesWalletsOptions
} from "./types";
import { INVOICES_DATA } from "./constants";

const invoicesParams = (
  filter: TInvoicesFilter|undefined,
  pagination: TPaginationOptions|undefined
) => {
  const params = new URLSearchParams();

  if (filter?.status) params.set("status", String(filter?.status));
  if (filter?.name) params.set("name", String(filter?.name));
  if (filter?.asset) params.set("asset", String(filter?.asset));
  if (filter?.date_from) params.set("date_from", String(filter?.date_from));
  if (filter?.date_to) params.set("date_to", String(filter?.date_to));
  if (filter?.search) params.set("search", String(filter?.search));

  if (pagination?.page) params.set("page", String(pagination?.page));
  if (pagination?.size) params.set("size", String(pagination?.size));

  return params;
};

function* invoices(action: IPayloadAction<TInvoicesOptions>) {
  const params = invoicesParams(action.payload.filter, action.payload.pagination);
  // eslint-disable-next-line no-console
  console.log('invoices params= > ', params);
  try {
    // const response: AxiosResponse<TInvoicesData> = yield call(request.get, "/alerts/", { params });
    yield put(invoicesSuccess(INVOICES_DATA));
  } catch (error) {
    yield put(invoicesFailure(error));
  }
}

function* invoicesWallets(action: IPayloadAction<TInvoicesWalletsOptions>) {
  try {
    const data: TInvoicesWalletsData =
      yield call(api.invoices.invoicesWallets, action.payload.query);
    
    yield put(invoicesWalletsSuccess({ data, infiniteScroll: action.payload.infiniteScroll }));
  } catch (error) {
    yield put(invoicesWalletsFailure(error));
  }
}
function* invoicesWalletDetails(action: IPayloadAction<TInvoicesWalletDetailsOptions>) {
  try {
    const data: TInvoicesWallet =
      yield call(api.invoices.invoicesWalletDetails, action.payload.id);
    yield put(invoicesWalletDetailsSuccess(data));
  } catch (error) {
    yield put(invoicesWalletDetailsFailure(error));
  }
}
function* invoicesWalletAdd(action: IPayloadAction<TInvoicesWalletAddOptions>) {
  try {
    const data: TInvoicesWallet =
      yield call(api.invoices.invoicesWalletAdd, action.payload.body);
    yield put(invoicesWalletAddSuccess(data));
    yield put(invoicesWalletsAppend(data));

    if (action.payload.callOnSuccess) action.payload.callOnSuccess();
  } catch (error) {
    yield put(invoicesWalletAddFailure(error));
  }
}

function* invoicesWalletEdit(action: IPayloadAction<TInvoicesWalletEditOptions>) {
  try {
    const data: TInvoicesWallet =
      yield call(api.invoices.invoicesWalletEdit, action.payload.id, action.payload.body);
    yield put(invoicesWalletEditSuccess(data));
    yield put(invoicesWalletsEdit(data));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess();
  } catch (error) {
    yield put(invoicesWalletEditFailure(error));
  }
}

function* invoicesWalletDelete(action: IPayloadAction<TInvoicesWalletDeleteOptions>) {
  try {
    const data: unknown =
      yield call(api.invoices.invoicesWalletDelete, action.payload.id);
    yield put(invoicesWalletDeleteSuccess(data));
    yield put(invoicesWalletsRemove({ id: action.payload.id }));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess();
  } catch (error) {
    yield put(invoicesWalletDeleteFailure(error));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(invoicesRequest.type, invoices),
    takeLatest(invoicesWalletsRequest.type, invoicesWallets),
    takeLatest(invoicesWalletDetailsRequest.type, invoicesWalletDetails),
    takeLatest(invoicesWalletAddRequest.type, invoicesWalletAdd),
    takeLatest(invoicesWalletEditRequest.type, invoicesWalletEdit),
    takeLatest(invoicesWalletDeleteRequest.type, invoicesWalletDelete),
  ]);
}

export default Saga;
