import { createSlice } from "@reduxjs/toolkit";

import {
  INewWalletAction, INewWalletForm,
} from "../../components/MonitoredWallets/components/MonitoredNewWalletModal/schema";

import { defaultCalculationState, defaultPaginationState, defaultState } from "../constants";
import { IPayloadAction } from "../rootInterface";

import {
  CalculationTransferResult,
  THistoricalImport, TMonitoredWallets,
  TMonitoredWalletsData,
  TMonitoredWalletsOptions,
  TMonitoredWalletsStoreState,
  TNewWallet, TStartHistoricalImport,
} from "./types";
import { changeImportWalletStatus } from "./utils";

const initialState: TMonitoredWalletsStoreState = {
  monitoredWallets: defaultPaginationState,
  addNewWallet: defaultPaginationState,
  historicalImport: defaultState,
  calculation: defaultCalculationState,
  historicalCalculation: defaultCalculationState,
  editWallet: defaultState,
  startHistoricalImport: defaultState,
  stopImportingTransfer: defaultState,
  deleteMonitoredWallet: defaultState,
  retryHistory: defaultState,
  checkHistoricalTransfers: defaultState,
};

const monitoredWalletsSlice = createSlice({
  name: "monitoredWallets",
  initialState,
  reducers: {
    checkHistoricalTransfersRequest(state, _: IPayloadAction<{id: string}>) {
      state.checkHistoricalTransfers = {
        ...state.checkHistoricalTransfers,
        fetching: true,
        data: null,
        failure: null
      };
    },
    checkHistoricalTransfersUpdate(state, action: IPayloadAction<null | string>) {
      state.checkHistoricalTransfers = {
        ...state.checkHistoricalTransfers,
        data: action.payload,
        fetching: false,
      };
    },
    retryHistoryRequest(state, _: IPayloadAction<{id: string}>) {
      state.retryHistory = {
        ...state.retryHistory,
        fetching: true,
        data: null,
        failure: null
      };
    },
    retryHistorySuccess(state, action: IPayloadAction<THistoricalImport>) {
      state.retryHistory = {
        ...state.retryHistory,
        fetching: false,
        data: action.payload,
        failure: null
      };
    },
    retryHistoryFailure(state, action) {
      state.retryHistory = {
        ...state.retryHistory,
        fetching: false,
        data: null,
        failure: action.payload
      };
    },
    deleteMonitoredWalletRequest(state, _: IPayloadAction<{id: string, delete_transfers: boolean}>) {
      state.deleteMonitoredWallet = {
        ...state.deleteMonitoredWallet,
        fetching: true,
        data: null,
        failure: null
      };
    },
    deleteMonitoredWalletSuccess(state, _: IPayloadAction) {
      state.deleteMonitoredWallet = {
        ...state.deleteMonitoredWallet,
        data: {},
        failure: null,
        fetching: false,
      };
    },
    deleteMonitoredWalletFailure(state, action) {
      state.deleteMonitoredWallet = {
        ...state.deleteMonitoredWallet,
        data: null,
        failure: action.payload,
        fetching: false,
      };
    },
    stopImportingTransferRequest(state, _: IPayloadAction<{id: string}>) {
      state.stopImportingTransfer = {
        ...state.stopImportingTransfer,
        data: null,
        fetching: true,
        failure: null
      };
    },
    stopImportingTransferSuccess(state, _: IPayloadAction) {
      state.stopImportingTransfer = {
        ...state.stopImportingTransfer,
        data: {},
        fetching: false,
        failure: null
      };
    },
    stopImportingTransferFailure(state, action) {
      state.stopImportingTransfer = {
        ...state.stopImportingTransfer,
        fetching: false,
        data: null,
        failure: action.payload
      };
    },
    startImportClear(state, _: IPayloadAction) {
      state.startHistoricalImport = {
        data: null,
        fetching: false,
        failure: null
      };
    },
    startImportRequest(state, _: IPayloadAction<TStartHistoricalImport>) {
      state.startHistoricalImport = {
        ...state.startHistoricalImport,
        fetching: true,
        failure: null,
      };
    },
    startImportSuccess(state, action) {
      state.startHistoricalImport = {
        ...state.startHistoricalImport,
        data: action.payload,
        fetching: true,
        failure: null,
      };
    },
    startImportFailure(state, action) {
      state.startHistoricalImport = {
        ...state.startHistoricalImport,
        fetching: false,
        failure: action.payload
      };
    },
    calculationHistoricalRequest(state, _: IPayloadAction<TStartHistoricalImport>) {
      state.historicalCalculation = { ...defaultCalculationState, fetching: true, failure: null };
    },
    calculationHistoricalSuccess(state, action: IPayloadAction<CalculationTransferResult> ) {
      state.historicalCalculation = {
        ...state.historicalCalculation,
        fetching: false,
        data: { ...state.historicalCalculation.data, ...action.payload },
      };
    },
    calculationHistoricalFailure(state, action) {
      state.historicalCalculation = {
        ...state.historicalCalculation,
        fetching: false,
        failure: action.payload
      };
    },
    calculationHistoricalClear(state, _) {
      state.historicalCalculation = {
        ...state.historicalCalculation,
        data: null
      };
    },
    calculationRequest(state, _: IPayloadAction<INewWalletForm>) {
      state.calculation = { ...defaultCalculationState, fetching: true, failure: null };
    },
    calculationClear(state, _) {
      state.calculation = { ...defaultCalculationState, fetching: false, failure: null };
    },
    calculationResult(state, action: IPayloadAction<CalculationTransferResult> ) {
      state.calculation = {
        ...state.calculation,
        fetching: false,
        data: action.payload,
      };
    },
    calculationFailure(state, action) {
      state.calculation = {
        ...state.calculation,
        fetching: false,
        failure: action.payload
      };
    },
    monitoredWalletsRequest(state, _: IPayloadAction<Partial<TMonitoredWalletsOptions>>) {
      state.monitoredWallets = {
        ...state.monitoredWallets,
        fetching: true,
      };
    },
    editWalletRequest(state, _: IPayloadAction<Partial<THistoricalImport<string[]>>>) {
      state.editWallet = {
        ...state.editWallet,
        fetching: true,
        failure: null,
      };
    },
    editWalletSuccess(state, _: IPayloadAction) {
      state.editWallet = {
        ...state.editWallet,
        fetching: false,
      };
    },
    editWalletFailure(state, action) {
      state.editWallet = {
        ...state.editWallet,
        failure: action.payload,
        fetching: false,
      };
    },
    monitoredWalletsSuccess(
      state,
      action: IPayloadAction<{
        data: TMonitoredWalletsData;
        infiniteScroll?: boolean;
      }>
    ) {
      let newData = action.payload.data;
      if (action.payload.infiniteScroll) {
        const prevResults = state?.monitoredWallets?.data?.results || [];
        newData = {
          ...action.payload.data,
          results: [...prevResults, ...(action.payload?.data?.results || [])],
        };
      }
      state.monitoredWallets = {
        fetching: false,
        data: newData,
        failure: null,
        // initialLoading: false,
      };
    },
    monitoredWalletsFailure(state, action) {
      state.monitoredWallets = {
        ...state.monitoredWallets,
        fetching: false,
        failure: action.payload,
      };
    },
    // new wallet
    addNewWalletRequest(state, _: IPayloadAction<INewWalletAction>) {
      state.addNewWallet = {
        ...state.addNewWallet,
        fetching: true,
      };
    },
    setImportEnableRequest(state, action: IPayloadAction<TMonitoredWallets>) {
      const { id } = action.payload;
      if (state.monitoredWallets.data) {
        state.monitoredWallets.data = changeImportWalletStatus(state.monitoredWallets, id);
      }
    },
    addNewWalletClear(state) {
      state.addNewWallet = {
        ...state.addNewWallet,
        fetching: false,
        failure: null,
      };
    },
    addNewWalletSuccess(state, action: IPayloadAction<TNewWallet>) {
      state.addNewWallet = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    addNewWalletFailure(state, action) {
      state.addNewWallet = {
        ...state.addNewWallet,
        fetching: false,
        failure: action.payload,
      };
    },

    // historical import
    setHistoricalImportRequest(state, action: IPayloadAction<{fetching?: boolean, id: string}>) {
      state.historicalImport = {
        ...state.historicalImport,
        fetching: !!action.payload.fetching,
      };
    },
    setHistoricalImportClear(state) {
      state.historicalImport = defaultState;
    },
    setHistoricalImportSuccess(state, action: IPayloadAction<THistoricalImport>) {
      state.historicalImport = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    setHistoricalImportFailure(state, action) {
      state.historicalImport = {
        ...state.historicalImport,
        fetching: false,
        failure: action.payload,
      };
    },
  },
});

export const {
  monitoredWalletsRequest,
  monitoredWalletsSuccess,
  monitoredWalletsFailure,

  addNewWalletRequest,
  addNewWalletSuccess,
  addNewWalletFailure,
  addNewWalletClear,

  setImportEnableRequest,

  setHistoricalImportSuccess,
  setHistoricalImportFailure,
  setHistoricalImportRequest,
  setHistoricalImportClear,

  calculationRequest,
  calculationResult,
  calculationFailure,
  calculationClear,

  editWalletRequest,
  editWalletSuccess,
  editWalletFailure,

  startImportRequest,
  startImportSuccess,
  startImportFailure,
  startImportClear,

  stopImportingTransferRequest,
  stopImportingTransferFailure,
  stopImportingTransferSuccess,

  deleteMonitoredWalletSuccess,
  deleteMonitoredWalletRequest,
  deleteMonitoredWalletFailure,

  calculationHistoricalRequest,
  calculationHistoricalSuccess,
  calculationHistoricalClear,
  calculationHistoricalFailure,

  retryHistoryRequest,
  retryHistorySuccess,
  retryHistoryFailure,

  checkHistoricalTransfersRequest,
  checkHistoricalTransfersUpdate,
} = monitoredWalletsSlice.actions;

export default monitoredWalletsSlice.reducer;
