import RussianFlag from "components/Icons/Flags/ru";
import BritishFlag from "components/Icons/Flags/en";
import AlertIcon from "components/Icons/AlertIcon";

import { ELanguages } from "../i18n/constants";
import ClientsArchiveSidebarIcon from "../components/ClientsArchive/icons/ClientsArchiveSidebarIcon";
import SwapIcon from "../components/Icons/SwapIcon";
import UsersIcon from "../components/Icons/UsersIcon";
import i18n from "../i18n";
import {
  isClientAvailable,
  isInvoicesAvailable,
  isFastChecksAvailable,
  isMonitoredWallets
} from "../utils/featuresCheck";
import WalletIcon from "../components/Icons/WalletIcon";
import InvoicesIcon from "../components/Icons/InvoicesIcon";
import AddDocumentsIcon from "../components/Icons/AddDocumentsIcon";

import {
  TLinks,
} from "./types";
import { paths } from "./paths";

export const lgMediaWidth = 992;

export const mobileMediaWidth375 = 375;
export const mobileMediaWidth400 = 400;
export const mobileMediaWidth460 = 460;
export const mobileMediaWidth = 575.98;
export const maxMobileMediaWidth = 767.98;
export const minTableMediaWidth = 768;
export const moreMdMedia = 868;
export const tableMediaWidth = 924;
export const maxTableMediaWidth = 1024;
export const minLaptopMediaWidth = 1199;
export const minShowSidebarWidth = 1336;
export const contentWidth = 996;

export enum LinkIds {
  HOME = "home",
  API_KEYS = "api-keys",
  RISK_SETTINGS = "risks-settings",
  TRANSFERS = "transfers",
  TRANSFERS_MONITORED_WALLETS = "monitored-wallets",
  CLIENTS = "clients",
  CLIENTS_ARCHIVE = "clients-archive",
  INVOICES = "invoices",
  INVOICES_MY_WALLETS = "invoices-my-alerts",
  FAST_CHECKS = "fast-checks",
}

export const NAVIGATIONS = {
  [paths.HOME]: {
    id: LinkIds.HOME,
    to: paths.HOME,
    icon: AlertIcon,
    title: i18n.t("common.alerts"),
    active: true,
  },
  [paths.API_KEYS]: {
    id: LinkIds.API_KEYS,
    to: paths.API_KEYS,
    title: i18n.t("apiKeys.title"),
    active: true
  },
  [paths.RISK_SETTINGS]: {
    id: LinkIds.RISK_SETTINGS,
    to: paths.RISK_SETTINGS,
    title: i18n.t("amlRiskSettings.title"),
    active: true
  },
  [paths.TRANSFERS]: {
    id: LinkIds.TRANSFERS,
    to: paths.TRANSFERS,
    title: i18n.t("common.transfers"),
    icon: SwapIcon,
    active: true,
    sublinks: [{
      id: LinkIds.TRANSFERS_MONITORED_WALLETS,
      to: paths.TRANSFERS_MONITORED_WALLETS,
      title: i18n.t("monitoredWallets.title"),
      icon: WalletIcon,
      active: isMonitoredWallets()
    }]
  },
  [paths.CLIENTS]: {
    id: LinkIds.CLIENTS,
    to: paths.CLIENTS,
    title: i18n.t("clients.clients"),
    active: isClientAvailable(),
    icon: UsersIcon,
    sublinks: [{
      id: LinkIds.CLIENTS_ARCHIVE,
      to: paths.CLIENTS_ARCHIVE,
      title: i18n.t("clients.archive"),
      icon: ClientsArchiveSidebarIcon,
      active: true
    }]
  },
  [paths.INVOICES]: {
    id: LinkIds.INVOICES,
    to: paths.INVOICES,
    title: i18n.t("invoices.title"),
    active: isInvoicesAvailable(),
    icon: InvoicesIcon,
    iconStroke: true,
    sublinks: [{
      id: LinkIds.INVOICES_MY_WALLETS,
      to: paths.INVOICES_MY_WALLETS,
      title: i18n.t("myWallets.title"),
      icon: WalletIcon,
      active: true
    }]
  },
  [paths.FAST_CHECKS]: {
    id: LinkIds.FAST_CHECKS,
    to: paths.FAST_CHECKS,
    title: i18n.t("fastChecks.title"),
    active: isFastChecksAvailable(),
    icon: AddDocumentsIcon,
  },
};

export const NAVIGATION_LINKS: TLinks[] = [
  NAVIGATIONS[paths.HOME],
  NAVIGATIONS[paths.TRANSFERS],
  NAVIGATIONS[paths.FAST_CHECKS],
  NAVIGATIONS[paths.INVOICES],
  NAVIGATIONS[paths.CLIENTS],
  NAVIGATIONS[paths.RISK_SETTINGS],
  NAVIGATIONS[paths.API_KEYS],
];

export const colors = {
  main: "#748ADA",
  brown1: "#edd4c4",
  brown2: "#e1b79d",
  brown3: "#d59b76",
  brown4: "#c97e4f",
  brown5: "#b06536",
  brown6: "#894e2a",
  brown7: "#62381e",
  brown8: "#3b2212",
  brown9: "#21130a",

  grayMain: "#39393C",
  gray1: "#ffffff",
  gray2: "#f3f4f9",
  gray3: "#eeeff3",
  gray4: "#e8e9f0",
  gray5: "#d7d8e0",
  gray6: "#b9bbc2",
  gray7: "#8c8d94",
  gray8: "#626368",
  gray9: "#232325",
  grayLight: "#fafafc",

  blue2: "#99ACF2",
  blue3: "#475DAF",
  error2: "#E5424C",
  green1: "#F6FFED",
  green3: "#59bd83",
  red1: "#FFF1F0",
  red2: "#FFCCC7",
  red3: "#FFA39E",
  red4: "#ff7875",
  red5: "#ff4d4f",
  red6: "#e5424C",
  complementary: "#FF6D88",
  complementary2: "#9E83F8",
  darkBlue: "#00d5d7",
  lightBlue: "#00e7e6",
  lettuce: "#CDF964",
  pink: "#D64686",
};

export type LanguageOption = { nativeName: string, icon: () => JSX.Element }
export const LANGUAGE_OPTIONS: Record<ELanguages, LanguageOption> = {
  [ELanguages.ruRU]: {
    nativeName: 'Русский',
    icon: RussianFlag
  },
  [ELanguages.enUS]: {
    nativeName: 'English',
    icon: BritishFlag
  },
};

export const DEFAULT_FORMAT = "DD.MM.YYYY";

export const CLASSES = {
  modalRouteLeft: 'modal-route-left',
  modalRouteRight: 'modal-route-right',
};

export const ELEMENT_IDS = {
  layoutPageWrapper: 'layout-page-wrapper',
};
