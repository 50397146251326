import { FC, SVGProps } from "react";

{/* eslint-disable max-len */}

const TransfersDirectionIconAssert: FC<SVGProps<SVGSVGElement>> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M13.1309 15.3052H13.3283L13.3309 15.1079L13.5154 1.33602L13.5181 1.13334H13.3154H10.7588H10.5563L10.5588 1.33583L10.7301 15.1077L10.7326 15.3052H10.9301H13.1309ZM10.8739 18.8403L10.8737 18.8405C10.5995 19.1451 10.4665 19.5241 10.4665 19.9627C10.4665 20.4014 10.5995 20.7803 10.8737 21.085L10.8736 21.085L10.8785 21.0901C11.1736 21.3958 11.5934 21.5333 12.103 21.5333C12.6126 21.5333 13.0324 21.3958 13.3275 21.0901L13.3276 21.0901L13.3299 21.0876C13.6139 20.7833 13.7527 20.4034 13.7527 19.9627C13.7527 19.5226 13.6143 19.1432 13.331 18.8391C13.0367 18.5141 12.6158 18.3658 12.103 18.3658C11.5896 18.3658 11.1682 18.5145 10.8739 18.8403Z" fill="#E6424C" stroke="#E6424C" strokeWidth="0.4"/>
  </svg>
);

export default TransfersDirectionIconAssert;