import { TNullable } from "config/types";

import request from "../request";
import { IPaginationResponse, TPaginationOptions } from "../types";

import { EUserRiskLevel } from "./user";

export type TAccountPlanCheckPack = {
  activated_at: string
  expired_at: string
  is_active: boolean
  pack_name: string
  quantity: number
}
export type TAccountPlanData = {
  checks_available: number
  check_pack: TNullable<TAccountPlanCheckPack>
}

export type TAccountCheckPackHistory = {
  pack_name: string
  quantity: number
  activated_at: string
  expired_at: string
  is_active: boolean
}
export type TAccountCheckPackHistoryData = IPaginationResponse<TAccountCheckPackHistory>
export type TAccountCheckPackHistoryQuery = TPaginationOptions


export type TAccountEmployees = {
  id: string
  email: string
  full_name: string
  job_title: string
  registered_at: TNullable<string>
  last_activity_at: TNullable<string>
}
export type TAccountEmployeesData = TAccountEmployees[]

export type TAccountEmployeePermissions = {
  managing_employees: boolean
  managing_api_keys: boolean
  configuring_risk_settings: boolean
  assigning_alerts: boolean
  managing_alert_status: boolean
  registering_transfers: boolean
}
export type TAccountEmployeesCreateBody = {
  email: string
  full_name: string
  job_title: string
  permissions: TAccountEmployeePermissions
}
export type TAccountEmployeesCreateData = {
  email: string
  full_name: string
  job_title: string
  permissions: TAccountEmployeePermissions
}

export type TAccountEmployeeData = {
  id: string
  email: TNullable<string>
  full_name: TNullable<string>
  job_title: TNullable<string>
  registered_at: string
  last_activity_at: string
  permissions: TAccountEmployeePermissions
}

export type TAccountEmployeeEditBody = Partial<TAccountEmployeesCreateBody>

export type TAccountEmployeeResendInviteData = unknown

export type TAccountEmployeeDeleteData = unknown

export type TAccountSetAlertRiskLevelBody = {
  risk_level: EUserRiskLevel
}
export type TAccountSetAlertRiskLevelData = unknown

const account = {
  accountPlan: (): Promise<TAccountPlanData> =>
    request<TAccountPlanData>({
      path: "/account/plan/",
      method: 'GET',
    }),
  accountCheckPackHistory: (query?: TAccountCheckPackHistoryQuery): Promise<TAccountCheckPackHistoryData> =>
    request<TAccountCheckPackHistoryData>({
      path: "/account/check-pack-history/",
      method: 'GET',
      query,
    }),
  accountEmployees: (): Promise<TAccountEmployeesData> =>
    request<TAccountEmployeesData>({
      path: "/account/employees/",
      method: 'GET',
    }),
  accountEmployeesCreate: (body: TAccountEmployeesCreateBody): Promise<TAccountEmployeesCreateData> =>
    request<TAccountEmployeesCreateData>({
      path: "/account/employees/create/",
      method: 'POST',
      body,
    }),
  accountEmployee: (id: string): Promise<TAccountEmployeeData> =>
    request<TAccountEmployeeData>({
      path: `/account/employees/${id}/`,
      method: 'GET',
    }),
  accountEmployeeEdit: (id: string, body: TAccountEmployeeEditBody): Promise<TAccountEmployeeData> =>
    request<TAccountEmployeeData>({
      path: `/account/employees/${id}/edit/`,
      method: 'PATCH',
      body
    }),
  accountEmployeeResendInvite: (id: string): Promise<TAccountEmployeeResendInviteData> =>
    request<TAccountEmployeeResendInviteData>({
      path: `/account/employees/${id}/resend-invite/`,
      method: 'POST',
    }),
  accountEmployeeDelete: (id: string): Promise<TAccountEmployeeDeleteData> =>
    request<TAccountEmployeeDeleteData>({
      path: `/account/employees/${id}/delete/`,
      method: 'DELETE',
    }),
  setAlertRiskLevel: (body: TAccountSetAlertRiskLevelBody): Promise<TAccountSetAlertRiskLevelData> =>
    request<TAccountSetAlertRiskLevelData>({
      path: `/account/set-alert-risk-level/`,
      method: 'POST',
      body
    }),
};

export default account;