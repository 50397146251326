import { FC } from "react";

import { colors } from "config/constants";

import { EDirection } from "store/transfers/types";

import ArrowDown from "./components/ArrowDown";


export type TDirectionArrow = {
  direction: EDirection
}
const DirectionArrow: FC<TDirectionArrow> = ({ direction }) =>
  direction === EDirection.outgoing
    ? <ArrowDown style={{ transform: "rotate(180deg)" }} color={colors.main}/>
    : <ArrowDown className="direction-arrow" />;

export default DirectionArrow;