import { FC, useMemo } from "react";
import { Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ItemType } from "antd/es/menu/hooks/useItems";

import { getClientArchiveId, getClientId } from "config/paths";

import EllipsisVerticalIcon from "components/Icons/EllipsisVerticalIcon";

import { clientArchiveRequest, clientUnArchiveRequest } from "store/clients/reducers";
import {
  clientArchiveSelector,
  clientsDetailsSelector,
  clientUnArchiveSelector
} from "store/clients/selectors";

import { dropdownAlign } from "../..";

const MobileHeaderClientMenu: FC<{ archive?: boolean }> = ({ archive }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const clientId = getClientId();
  const archiveId = getClientArchiveId();
  const id = archive ? archiveId : clientId;
  
  const { data } = useSelector(clientsDetailsSelector);
  const { fetching: archiveFetching } = useSelector(clientArchiveSelector);
  const { fetching: unrchiveFetching } = useSelector(clientUnArchiveSelector);

  const items = useMemo(() => {
    const res: ItemType[] = [
      {
        key: "1",
        onClick: () => {
          if (!id) return;

          if (data?.is_archived) {
            dispatch(clientUnArchiveRequest({ id }));
          } else {
            dispatch(clientArchiveRequest({ id }));
          }

        },
        label: data?.is_archived ? t("clients.removeFromArchive") : t("clients.addToArchive"),
        disabled: archiveFetching || unrchiveFetching
      },
    ];

    return res;
  }, [data]);

  return <Dropdown
    menu={{
      className: "bn-dropdown__menu",
      items,
    }}
    align={dropdownAlign}
    trigger={["click"]}
    placement="bottomRight"
  >
    <a
      onClick={e => e.preventDefault()}
      className="header-mobile__menu"
    >
      <EllipsisVerticalIcon/>
    </a>
  </Dropdown>;
};

export default MobileHeaderClientMenu;