import { ETransferType } from "../transfers/types";

export enum EModals {
  alertDrawer = "alertDrawer",
  alertsFilter = "alertsFilter",
  amlRiskModelDelete = "amlRiskModelDelete",
  apiKeyCreate = "apiKeyCreate",
  apiKeyDelete = "apiKeyDelete",
  apiKeyEdit = "apiKeyEdit",
  clientsFilter = "riskModelActivate",
  riskModelActivate = "riskModelActivate",
  riskModelCreate = "riskModelCreate",
  riskModelUnavailableAdd = "riskModelUnavailableAdd",
  riskModelResetChanges = "riskModelResetChanges",
  riskModelEdit = "riskModelEdit",
  teamMemberRename = "teamMemberRename",
  teamMemberAdd = "teamMemberAdd",
  teamMemberDelete = "teamMemberDelete",
  transferDetail = "transferDetail",
  transferFast = "transferFast",
  transfersFilter = "transfersFilter",
  newWallet = "newWallet",
  walletEdit = "walletEdit",
  walletDelete = "walletDelete",
  monitoredWalletsDelete = 'monitoredWalletsDelete',
  stopImportingTransferModal = 'stopImportingTransferModal',
  invoiceNew = "invoiceNew",
  invoiceFilter = "invoiceFilter",
  fastChecksFilter = "fastChecksFilter",
  fastCheckTransfer = "fastCheckTransfer",
  fastCheckAddress = "fastCheckAddress",

  monitoredNewWallet = "monitoredNewWallet",
  monitoredCheckTransfers = "monitoredCheckTransfers",
  monitoredHistoricalImport = "monitoredHistoricalImport",
  monitoredWalletFilter = "monitoredWalletFilter",
}

export type ModalIdData = {
  id: string
}
export type ModalNewTransferData = {
  transferType: ETransferType
}
export type ModalData<T> = T | unknown

export type ModalState<T> = {
  open: boolean,
  data: ModalData<T>
}

export type TModalsStoreState = {
  [key in EModals]?: ModalState<unknown>
};
