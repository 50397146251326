import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import isFunction from "lodash/isFunction";

import request from "../../api";

import { IPayloadAction } from "../rootInterface";
import { PAGINATION_LARGE_LIMIT } from "../constants";

import {
  assetsFailure,
  assetsRequest,
  assetsSuccess, basicUsersFailure, basicUsersRequest, basicUsersSuccess,
  blockchainsFailure,
  blockchainsRequest,
  blockchainsSuccess,
  counterpartiesFailure,
  counterpartiesRequest,
  counterpartiesSuccess, entityCategoriesFailure, entityCategoriesRequest, entityCategoriesSuccess
} from "./reducers";
import {
  TBasicAssetsOptions,
  TBasicBlockchainsData,
  TBasicBlockchainsOptions, TBasicEntityCategoryOptions,
  TBasicUsers,
  TCounterpartiesOptions
} from "./types";

function* getCounterparties(action: IPayloadAction<TCounterpartiesOptions>) {
  const params = action.payload;
  try {
    const response: AxiosResponse = yield call(request.get, "/basics/counterparties/", {
      params: { ...params, size: PAGINATION_LARGE_LIMIT },
    });
    yield put(counterpartiesSuccess(response.data));
  } catch (error) {
    yield put(counterpartiesFailure(error));
  }
}

function* getAssets(action: IPayloadAction<TBasicAssetsOptions>) {
  const params: TBasicAssetsOptions = {};
  const url = action.payload.pathname ? action.payload.pathname : "/basics/assets/";
  if (action.payload.search) {
    params.search = action.payload.search;
  }

  try {
    const response: AxiosResponse = yield call(request.get, url, {
      params: { ...params, size: PAGINATION_LARGE_LIMIT },
    });
    yield put(assetsSuccess(response.data));
  } catch (error) {
    yield put(assetsFailure(error));
  }
}

function* getBlockchains(action: IPayloadAction<TBasicBlockchainsOptions>) {
  const params: TBasicBlockchainsOptions = {};
  const url = action.payload.pathname ? action.payload.pathname : "/basics/networks/";

  if (action.payload.search) {
    params.search = action.payload.search;
  }

  try {
    const response: AxiosResponse<TBasicBlockchainsData> = yield call(request.get, url, {
      params: { ...params, size: PAGINATION_LARGE_LIMIT },
    });
    yield put(blockchainsSuccess(response.data));
  } catch (error) {
    yield put(blockchainsFailure(error));
  }
}

function* entityCategories(action: IPayloadAction<TBasicEntityCategoryOptions>) {
  const { search } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.get, "/basics/entity-categories/", {
      params: { search, size: PAGINATION_LARGE_LIMIT },
    });
    yield put(entityCategoriesSuccess(response.data));
    if (isFunction(action.payload?.callOnSuccess)) action.payload.callOnSuccess(response.data);
  } catch (error) {
    yield put(entityCategoriesFailure(error));
  }
}

function* basicUsers() {
  try {
    const response: AxiosResponse<TBasicUsers[]> = yield call(request.get, "/basics/users/");
    yield put(basicUsersSuccess(response.data));
  } catch (error) {
    yield put(basicUsersFailure(error));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(counterpartiesRequest.type, getCounterparties),
    takeLatest(assetsRequest.type, getAssets),
    takeLatest(blockchainsRequest.type, getBlockchains),
    takeLatest(entityCategoriesRequest.type, entityCategories),
    takeLatest(basicUsersRequest.type, basicUsers),
  ]);
}

export default Saga;
