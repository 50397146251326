import { FC } from "react";
import { useDispatch } from "react-redux";

import PlusIcon from "components/Icons/PlusIcon";

import { openModal } from "store/modals/actions";
import { EModals } from "store/modals/types";

const MobileHeaderNewInvoice: FC = () => {
  const dispatch = useDispatch();

  return <PlusIcon
    className="plus-icon"
    onClick={() => dispatch(openModal({ modal:EModals.invoiceNew }))}
  />;
};

export default MobileHeaderNewInvoice;
