import { createSlice } from "@reduxjs/toolkit";


import { TInvoicesWallet, TInvoicesWalletsData } from "../../api/branch/invoices";

import { defaultPaginationInitial, defaultPaginationState, defaultState } from "../constants";
import { IPayloadAction } from "../rootInterface";

import {
  TInvoicesData,
  TInvoicesOptions,
  TInvoicesStoreState,
  TInvoicesWalletAddOptions,
  TInvoicesWalletDeleteOptions,
  TInvoicesWalletDetailsOptions,
  TInvoicesWalletEditOptions,
  TInvoicesWalletsOptions
} from "./types";

const initialState: TInvoicesStoreState = {
  invoices: defaultPaginationState,
  invoicesWallets: defaultPaginationInitial,
  invoicesWalletDetails: defaultState,
  invoicesWalletAdd: defaultState,
  invoicesWalletEdit: defaultState,
  invoicesWalletDelete: defaultState,
};

const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invoicesRequest(state, _: IPayloadAction<TInvoicesOptions>) {
      state.invoices = {
        ...state.invoices,
        fetching: true,
      };
    },
    invoicesSuccess(state, action: IPayloadAction<TInvoicesData>) {
      state.invoices = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    invoicesFailure(state, action) {
      state.invoices = {
        ...state.invoices,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invoicesWalletsRequest(state, _: IPayloadAction<TInvoicesWalletsOptions>) {
      state.invoicesWallets = {
        ...state.invoicesWallets,
        fetching: true,
      };
    },
    invoicesWalletsSuccess(state, action: IPayloadAction<{
      data: TInvoicesWalletsData
      infiniteScroll?: boolean
    }>) {
      let newData = action.payload.data;
      if (action.payload.infiniteScroll) {
        const prevResults = state?.invoicesWallets?.data?.results || [];

        newData = {
          ...action.payload.data,
          results: [...prevResults, ...(action.payload?.data?.results || [])]
        };
      }

      state.invoicesWallets = {
        fetching: false,
        data: newData,
        failure: null,
        initialLoading: false,
      };
    },
    invoicesWalletsFailure(state, action) {
      state.invoicesWallets = {
        ...state.invoicesWallets,
        fetching: false,
        failure: action.payload,
        initialLoading: false,
      };
    },
    invoicesWalletsRemove(state, action: IPayloadAction<{ id: string }>) {
      state.invoicesWallets.data.results = state.invoicesWallets.data?.results
        .filter(item => item.id !== action.payload.id) || [];
      state.invoicesWallets.data.count--;
    },
    invoicesWalletsAppend(state, action: IPayloadAction<TInvoicesWallet>) {
      state.invoicesWallets.data.results = [action.payload, ...(state.invoicesWallets.data.results || [])];
      state.invoicesWallets.data.count++;
    },
    invoicesWalletsEdit(state, action: IPayloadAction<TInvoicesWallet>) {
      state.invoicesWallets.data.results = state.invoicesWallets.data?.results
        .map(item => {
          if (item.id !== action.payload.id) return item;
          return action.payload;
        }) || [];
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invoicesWalletDetailsRequest(state, _: IPayloadAction<TInvoicesWalletDetailsOptions>) {
      state.invoicesWalletDetails = {
        ...state.invoicesWalletDetails,
        fetching: true,
      };
    },
    invoicesWalletDetailsSuccess(state, action: IPayloadAction<TInvoicesWallet>) {
      state.invoicesWalletDetails = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    invoicesWalletDetailsFailure(state, action) {
      state.invoicesWalletDetails = {
        ...state.invoicesWalletDetails,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invoicesWalletAddRequest(state, _: IPayloadAction<TInvoicesWalletAddOptions>) {
      state.invoicesWalletAdd = {
        ...state.invoicesWalletAdd,
        fetching: true,
      };
    },
    invoicesWalletAddSuccess(state, action: IPayloadAction<TInvoicesWallet>) {
      state.invoicesWalletAdd = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    invoicesWalletAddFailure(state, action) {
      state.invoicesWalletAdd = {
        ...state.invoicesWalletAdd,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invoicesWalletEditRequest(state, _: IPayloadAction<TInvoicesWalletEditOptions>) {
      state.invoicesWalletEdit = {
        ...state.invoicesWalletEdit,
        fetching: true,
      };
    },
    invoicesWalletEditSuccess(state, action: IPayloadAction<TInvoicesWallet>) {
      state.invoicesWalletEdit = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    invoicesWalletEditFailure(state, action) {
      state.invoicesWalletEdit = {
        ...state.invoicesWalletEdit,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invoicesWalletDeleteRequest(state, _: IPayloadAction<TInvoicesWalletDeleteOptions>) {
      state.invoicesWalletDelete = {
        ...state.invoicesWalletDelete,
        fetching: true,
      };
    },
    invoicesWalletDeleteSuccess(state, action: IPayloadAction<unknown>) {
      state.invoicesWalletDelete = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    invoicesWalletDeleteFailure(state, action) {
      state.invoicesWalletDelete = {
        ...state.invoicesWalletDelete,
        fetching: false,
        failure: action.payload,
      };
    },
  },
});

export const {
  invoicesRequest,
  invoicesSuccess,
  invoicesFailure,

  invoicesWalletsRequest,
  invoicesWalletsSuccess,
  invoicesWalletsFailure,
  invoicesWalletsRemove,
  invoicesWalletsEdit,
  invoicesWalletsAppend,

  invoicesWalletDetailsRequest,
  invoicesWalletDetailsSuccess,
  invoicesWalletDetailsFailure,

  invoicesWalletAddRequest,
  invoicesWalletAddSuccess,
  invoicesWalletAddFailure,

  invoicesWalletEditRequest,
  invoicesWalletEditSuccess,
  invoicesWalletEditFailure,

  invoicesWalletDeleteRequest,
  invoicesWalletDeleteSuccess,
  invoicesWalletDeleteFailure,
} = invoicesSlice.actions;

export default invoicesSlice.reducer;
