{/* eslint-disable max-len */}

import { IPaginationResponse, TPaginationOptions } from "../../api/types";
import { TNullable } from "../../config/types";

import { AlertStatus, EAlertSubjects, EProximity, TExposure } from "../alerts/types";
import { TRequestHandler } from "../rootInterface";
import { ECounterpartyStatus, EExposureStatus, EFastCheckGroups, ERiskLevel } from "../transfers/types";

export enum EFastCheckType {
  deposit = "deposit",
  withdrawal = "withdrawal",
  single_address = "single_address",
}

export type TFastMobileFilterGroups = {
  group: EFastCheckGroups,
  count: number
}

export type TFastCheck = {
  id: string
  created_at: string
  type: EFastCheckType
  risk_level: TNullable<ERiskLevel>
  network_code: string
  network_name: string
  subject: string
  status?: 'error' | 'checked' | 'checking' | null;
  asset_symbol: TNullable<string>
  asset_amount: TNullable<number>
}
export type TFastChecksData = IPaginationResponse<TFastCheck>

export enum TFastChecksFilterKeys {
  type = "type",
  group = 'group',
  risk_level = "risk_level",
  network = "network",
  date_from = "date_from",
  date_to = "date_to",
  search = "search",
  date_range = "date_range"
}
// export enum EFastChecksCheckTypes {
//   awaiting = "awaiting",
//   awaitingPartialPaid = "awaiting_partial_paid",
//   completing = "completing",
//   completed = "completed",
//   expired = "expired",
//   actionRequiredPartialPaid = "action_required_partial_paid",
//   actionRequiredAMLFailed = "action_required_aml_failed",
//   actionRequiredAMLCompleting = "action_required_aml_completing",
//   canceled = "canceled",
// }
export type TFastChecksFilter = {
  [TFastChecksFilterKeys.type]?: EFastCheckType
  [TFastChecksFilterKeys.group]?: EFastCheckGroups
  [TFastChecksFilterKeys.risk_level]?: ERiskLevel
  [TFastChecksFilterKeys.network]?: string
  [TFastChecksFilterKeys.date_from]?: TNullable<string>
  [TFastChecksFilterKeys.date_to]?: TNullable<string>
  [TFastChecksFilterKeys.date_range]?: TNullable<string>
  [TFastChecksFilterKeys.search]?: string
}
export type TFastChecksOptions = {
  filter?: TFastChecksFilter
  pagination?: TPaginationOptions
  infiniteScroll?: boolean;
}
export type TFastChecksState = TRequestHandler<TFastChecksData> & {
  initialLoading: boolean
}

export type TFastChecksCheckTransferBody = {
  token: string // - ID токена в формате UUID.
  tx_hash: string // - хэш транзакции, где произошел трансфер.
  output_address: string // - адрес получения в трансфере (и для входящих, и для исходящих трансферов).
  check_type: string // - тип проверки. Возможные значения: deposit, withdrawal.
  risk_model: string
}
export type TFastChecksCheckTransferOptions = {
  body: TFastChecksCheckTransferBody
  callOnSuccess?: (id: string) => void
}
export type TFastChecksCheckTransfer = {
  id: string
}
export type TFastChecksCheckTransferState = TRequestHandler<TFastChecksCheckTransfer>

export type TFastChecksCheckAddressBody = {
  network: string // - ID сети в формате UUID.
  token: TNullable<string> // - ID токена в формате UUID. Может быть null, если осуществляется проверка по всем токенам.
  address: string // - проверяемый адрес.
  risk_model: string

}
export type TFastChecksCheckAddressOptions = {
  body: TFastChecksCheckAddressBody
  callOnSuccess?: (id: string) => void
}
export type TFastChecksCheckAddress = {
  id: string
}
export type TFastChecksCheckAddressState = TRequestHandler<TFastChecksCheckAddress>

export type TFastChecksDetails = {
  id: string // - ID проверки.
  created_at: string // - дата и время создания проверки.
  occurred_at?: TNullable<string> // - дата и время создания проверки.
  type: EFastCheckType // - тип проверки. Возможные значения: deposit, withdrawal, single_address.
  risk_level: TNullable<ERiskLevel> // - уровень риска. Может быть null, если проверка не завершена.
  risk_score: number // - уровень риска. Может быть null, если проверка не завершена.
  risk_model_name: TNullable<string> // - название риска. Может быть null.
  address_info: TFastCheckAddressInfo // - данные, связанные с проверкой адреса. См. структуру FastCheckAddressInfo.
  address_exposure_status: ECounterpartyStatus // - статус проверки адреса и его экспожура.
  address_exposure: TFastCheckAddressExposure // - результаты проверки адреса и его экспожура. См. структуру AddressExposure.
  transfer_info: TFastCheckTransferInfo // - данные, связанные с проверкой трансфера. См. структуру FastCheckTransferInfo.
  transfer_exposure_status: EExposureStatus // - статус проверки экспожура трансфера.
  transfer_exposure: TFastCheckTransferExposure // - результат проверки экспожура трансфера. См. структуру TransferExposure.
  fiat_currency: string // - валюта для расчета стоимости активов в фиате.

  prev_address_exposure_status?: ECounterpartyStatus,
  prev_transfer_exposure_status?: EExposureStatus,
}
export type TFastCheckAddressInfo = {
  address: string // - адрес.
  network_code: string // - код сети.
  network_name: string // - название сети.
  balance_in_fiat: TNullable<number> // - баланс (в фиате) на момент проверки. Может быть null.
  transaction_count: TNullable<number> // - число транзакций на момент проверки. Может быть null.
  inflow_in_fiat: TNullable<number> // - сумма входящих транзакций в фиате на момент проверки. Может быть null.
  outflow_in_fiat: TNullable<number> // - сумма исходящих транзакций в фиате на момент проверки. Может быть null.
  first_transaction_at: TNullable<string> // - дата и время первой транзакции. Может быть null.
  last_transaction_at: TNullable<string> // - дата и время последней транзакции. Может быть null.
}
export type TFastCheckAddressExposure = {
  checked_at: TNullable<string> // - время проверки экспожура. Может быть null.
  entity_name: TNullable<string> // - название выявленной сущности (сервиса, компании, лица), к которой относится контрагент. Может быть null.
  entity_category: TNullable<string> // - название выявленной категории сущности, к которой относится контрагент. Может быть null.
  entity_risk_score: TNullable<number> // - уровень выявленной риска категории, к которой относится контрагент. От 0 до 1. Может быть null.
  exposure: TNullable<TExposure[]> // - взаимодействие с внешними сущностями. Если определено значение поля entity_name, то поле exposure будет null. Представлен массивом объектов структуры IndirectEntityConnection.
  report_url: TNullable<string> // - ссылка на PDF генератор
}
export type TFastCheckTransferInfo = {
  tx_hash: string // - хэш тразакции, к которой относится трансфер.
  network_code: string // - код сети.
  network_name: string // - название сети.
  asset_symbol: string // - код актива, с которым ассоциирован трансфер.
  input_address: string // - адрес отправителя.
  output_address: string // - адрес получателя
  amount: number // - сумма трансфера в криптоактиве.
  value_in_fiat: TNullable<number> // - сумма трансфера в фиате. Может быть null.
}
export type TFastCheckTransferExposure = {
  checked_at: string // - время проверки экспожура. Может быть null.
  interaction: EProximity // - "близость" источника. Возможные варианты: direct, indirect.
  counterparty: TNullable<TFastCheckTransferDirectExposure> // - прямой контрагент. Применимо для interaction в значении direct, иначе null. Представлен объектом DirectEntityConnection.
  indirect_exposure: TNullable<TExposure[]> // - непрямые источники. Применимо для interaction в значении indirect, иначе null. Представлен массивом объектов структуры IndirectEntityConnection.
  report_url: TNullable<string> // - ссылка на PDF генератор
}
export type TFastCheckTransferDirectExposure = {
  entity_name: string
  entity_category: string
  risk_score: number
}
export type TFastChecksDetailsState = TRequestHandler<TFastChecksDetails> & {
  initialLoading: boolean
}
export type TFastChecksDetailsOptions = {
  id: string
  prev_address_exposure_status?: ECounterpartyStatus
  prev_transfer_exposure_status?: EExposureStatus
}

export type TFastChecksChecksRisk = {
  alert_assignee_name: TNullable<string>
  alert_id: TNullable<string>
  alert_status: TNullable<AlertStatus>
  amount: TNullable<number>
  asset: TNullable<string>
  client_ext_id: TNullable<string>
  created_at: string
  entity_category: string
  fiat_currency: string
  fiat_value: TNullable<number>
  id: string
  interaction: EProximity
  network_code: string
  network_name: string
  occurred_at: string
  risk_level: ERiskLevel
  risky_value: TNullable<number>
  risky_value_share: number
  type: EAlertSubjects

  // client_ext_id: string // - всегда null для всех типов проверок.
  // risky_value: string // - всегда null для рисков адреса.
  // asset: string // - может быть null для риска адреса.
  // amount: string // и fiat_value - всегда null для риска адреса.
  // alert_id: string // alert_status, alert_assignee_name - всегда null для всех типов проверок.
}
export type TFastChecksRisksData = TFastChecksChecksRisk[]
export type TFastChecksRisksState = TRequestHandler<TFastChecksRisksData> & {
  initialLoading: boolean
}
export type TFastChecksChecksRiskOptions = {
  id: string
  callOnSuccess?: (data: TFastChecksRisksData) => void
}
export type TFastChecksRecheckState = TRequestHandler<unknown> & {
  initialLoading: boolean
}

export type TFastGroupsState = TRequestHandler<TFastMobileFilterGroups[]>;

export type TFastChecksStoreState = {
  fastChecks: TFastChecksState
  fastChecksDetails: TFastChecksDetailsState
  fastChecksCheckTransfer: TFastChecksCheckTransferState
  fastChecksCheckAddress: TFastChecksCheckAddressState
  fastChecksRisks: TFastChecksRisksState
  fastChecksRecheck: TFastChecksRecheckState
  fastFilterMobileGroups: TFastGroupsState
};
