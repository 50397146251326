/* eslint-disable max-len */
import * as React from "react";

const AddDocumentsIcon = (): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
  >
    <path
      fill="#626368"
      d="M22.895 11.069H20.01l2.595-4.819c.053-.102.005-.25-.083-.25H17.5c-.037 0-.072.029-.09.077l-3.395 8.615c-.04.102.01.231.09.231h2.285l-1.17 6.885c-.026.15.098.256.173.148l7.575-10.623c.069-.095.023-.264-.072-.264Z"
    />
    <rect width={10} height={1.8} x={2} y={20.4} fill="#626368" rx={0.9} />
    <rect width={10} height={1.8} x={2} y={14.6} fill="#626368" rx={0.9} />
    <rect width={12} height={1.8} x={2} y={8} fill="#626368" rx={0.9} />
    <rect width={14} height={1.8} x={2} y={2} fill="#626368" rx={0.9} />
  </svg>
);
export default AddDocumentsIcon;
