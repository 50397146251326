import axios from "axios";

import { getStorageData, EStorageKeys } from "utils/storageHeplers";

export type TToken = {
  access_token: string;
  refresh_token: string;
};

const baseURL = 'https://qa0.bittax.ru/backend/api/v1';

const trackerInstance = axios.create({
  withCredentials: true,
  baseURL,
});

trackerInstance.interceptors.request.use(
  config => {
    const token = getStorageData(EStorageKeys.TOKEN) as TToken;

    if (token && token?.access_token) {
      config.headers.Authorization = `Bearer ${token.access_token}`;
    }

    return config;
  },
  error =>
    // Do something with request error
    Promise.reject(error)
);


export default trackerInstance;
