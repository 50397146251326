import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  clientParamsKey,
  getClientArchiveId,
  getClientId,
  getClientsArchiveDetailsPath,
  getWalletArchiveDetailsTransfersPath,
  getClientsArchiveDetailsTransfersPath,
  getClientsDetailsPath,
  getClientsDetailsTransfersPath,
  getMonitoringWalletId,
  getWalletInformationPath,
  monitoringParamsKey,
  paths
} from "config/paths";
import { CLASSES, ELEMENT_IDS } from "config/constants";

import { useLayoutAppNameContext } from "components/Layout/hooks";

import { clientsDetailsSelector } from "store/clients/selectors";
import { clientsDetailsRequest } from "store/clients/reducers";
import { historicalImportSelector } from "store/monitoredWallets/selectors";
import { THistoricalImport } from "store/monitoredWallets/types";
import { setHistoricalImportRequest } from "store/monitoredWallets/reducers";

import compressText from "utils/compressText";

import HeaderBreadCrumbs, { TBreadCrumb } from "../HeaderBreadCrumbs";
import HeaderChecks from "../HeaderChecks";

import { formIDTitle, getNameOrAddress } from "./utils";

const HeaderLeft: FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const doesAnyHistoryEntryExist = history.location.key;
  const location = useLocation<any>();
  const routeModal = location.state && location.state.routeModal;
  const { routePath } = useLayoutAppNameContext();
  const clientId = getClientId();
  const monitoringWalletId = getMonitoringWalletId();
  const clientArchiveId = getClientArchiveId();
  const { data: clientDetail } = useSelector(clientsDetailsSelector);
  const wallet = useSelector(historicalImportSelector);

  const queryParam = new URLSearchParams(window.location.search);
  const monitoringId = queryParam.get(monitoringParamsKey);
  const clientParamId = queryParam.get(clientParamsKey);

  useEffect(() => {
    if (monitoringId && !wallet.data) {
      dispatch(setHistoricalImportRequest({ id: monitoringId, fetching: true }));
    }
  }, [dispatch]);

  const handleClickBreadCrumb = (path: string, withGoBack = false) => {
    const element = document.getElementById(ELEMENT_IDS.layoutPageWrapper);
    if (routeModal && element) {
      element.classList.add(CLASSES.modalRouteLeft);
    }

    if (doesAnyHistoryEntryExist && withGoBack) {
      history.goBack();
    } else {
      history.push(path);
      history.location.key = "";
    }
  };

  const monitoringDetailTransferPath = [
    {
      title: t("monitoredWallets.title"),
      onClick: () => handleClickBreadCrumb(paths.TRANSFERS_MONITORED_WALLETS),
    },
    {
      title: getNameOrAddress(wallet?.data as THistoricalImport, monitoringId),
      onClick: () => handleClickBreadCrumb(getWalletInformationPath(monitoringId as string)),
    },
    {
      title: t("common.transfers"),
      onClick: () => {
        const transferId = window.location.pathname.replace('/transfers/', '');
        const path = getWalletArchiveDetailsTransfersPath(monitoringId as string)
        + `${window.location.search}&view=${transferId}`;
        handleClickBreadCrumb(path);
      },
    },
  ];

  const clientDetailTransferPath = [
    {
      title: t("clients.clients"),
      onClick: () => {
        handleClickBreadCrumb(paths.CLIENTS);
      },
    },
    {
      title: formIDTitle(clientDetail?.ext_id) || compressText(formIDTitle(clientParamId)),
      onClick: () => {
        if (!clientParamId) {
          handleClickBreadCrumb(paths.CLIENTS);
          return;
        }
        handleClickBreadCrumb(getClientsDetailsPath(clientParamId));
      },
    },
    {
      title: t("common.transfers"),
      onClick: () => {
        if (!clientParamId) {
          handleClickBreadCrumb(paths.CLIENTS);
          return;
        }
        handleClickBreadCrumb(getClientsDetailsTransfersPath(clientParamId));
      },
    },
  ];

  const getTransferDetailPath = () => {
    if (clientParamId) {
      return clientDetailTransferPath;
    }

    if (monitoringId) {
      return monitoringDetailTransferPath;
    }

    return [
      {
        title: t("common.transfers"),
        onClick: () => handleClickBreadCrumb(paths.TRANSFERS, true),
      },
    ];
  };

  useEffect(() => {
    if ((clientDetail && clientDetail.id === clientId) || !clientId || clientId === "archive") return;

    dispatch(clientsDetailsRequest({ id: clientId }));
  }, [clientId, clientDetail]);

  useEffect(() => {
    if ((clientDetail && clientDetail.id === clientArchiveId) || !clientArchiveId) return;

    dispatch(clientsDetailsRequest({ id: clientArchiveId }));
  }, [clientArchiveId, clientDetail]);

  const breadCrumbs: Record<string, TBreadCrumb[]> = useMemo(() => ({
    [paths.RISK_SETTINGS_DETAILS]: [{
      title: t("amlRiskSettings.title"),
      onClick: () => handleClickBreadCrumb(paths.RISK_SETTINGS),
    }],
    [paths.TRANSFERS_DETAILS]: getTransferDetailPath(),
    [paths.CLIENTS_DETAILS]: [{
      title: t("clients.clients"),
      onClick: () => handleClickBreadCrumb(paths.CLIENTS, true),
    }],
    [paths.CLIENTS_DETAILS_TRANSFERS]: [
      {
        title: t("clients.clients"),
        onClick: () => {
          handleClickBreadCrumb(paths.CLIENTS);
        },
      },
      {
        title: formIDTitle(clientDetail?.ext_id),
        onClick: () => {
          if (!clientId) {
            handleClickBreadCrumb(paths.CLIENTS);
            return;
          }
          handleClickBreadCrumb(getClientsDetailsPath(clientId));
        },
      },
    ],
    [paths.CLIENTS_DETAILS_TRANSFERS_DETAILS]: clientDetailTransferPath,
    [paths.CLIENTS_DETAILS_TRANSFER]: [
      {
        title: t("clients.clients"),
        onClick: () => {
          handleClickBreadCrumb(paths.CLIENTS);
        },
      },
      {
        title: formIDTitle(clientDetail?.ext_id),
        onClick: () => {
          if (!clientId) {
            handleClickBreadCrumb(paths.CLIENTS);
            return;
          }
          handleClickBreadCrumb(getClientsDetailsPath(clientId));
        },
      },
    ],

    [paths.CLIENTS_ARCHIVE_DETAILS]: [{
      title: t("clients.clientsArchive"),
      onClick: () => handleClickBreadCrumb(paths.CLIENTS_ARCHIVE, true),
    }],
    [paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS]: [
      {
        title: t("clients.clientsArchive"),
        onClick: () => {
          handleClickBreadCrumb(paths.CLIENTS_ARCHIVE);
        },
      },
      {
        title: formIDTitle(clientDetail?.ext_id),
        onClick: () => {
          if (!clientArchiveId) {
            handleClickBreadCrumb(paths.CLIENTS_ARCHIVE);
            return;
          }
          handleClickBreadCrumb(getClientsArchiveDetailsPath(clientArchiveId));
        },
      },
    ],
    [paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS_DETAILS]: [
      {
        title: t("clients.clientsArchive"),
        onClick: () => {
          handleClickBreadCrumb(paths.CLIENTS_ARCHIVE);
        },
      },
      {
        title: formIDTitle(clientDetail?.ext_id),
        onClick: () => {
          if (!clientArchiveId) {
            handleClickBreadCrumb(paths.CLIENTS_ARCHIVE);
            return;
          }
          handleClickBreadCrumb(getClientsArchiveDetailsPath(clientArchiveId));
        },
      },
      {
        title: t("common.transfers"),
        onClick: () => {
          if (!clientArchiveId) {
            handleClickBreadCrumb(paths.CLIENTS_ARCHIVE);
            return;
          }
          handleClickBreadCrumb(getClientsArchiveDetailsTransfersPath(clientArchiveId));
        },
      },
    ],
    [paths.CLIENTS_ARCHIVE_DETAILS_TRANSFER]: [
      {
        title: t("clients.clientsArchive"),
        onClick: () => {
          handleClickBreadCrumb(paths.CLIENTS_ARCHIVE);
        },
      },
      {
        title: formIDTitle(clientDetail?.ext_id),
        onClick: () => {
          if (!clientArchiveId) {
            handleClickBreadCrumb(paths.CLIENTS_ARCHIVE);
            return;
          }
          handleClickBreadCrumb(getClientsArchiveDetailsPath(clientArchiveId));
        },
      },
    ],

    [paths.ACCESS_MANAGEMENT_DETAIL]: [
      {
        title: t("accessManagement.accessManagement"),
        onClick: () => handleClickBreadCrumb(paths.ACCESS_MANAGEMENT),
      }
    ],
    [paths.FAST_CHECKS_DETAILS]: [
      {
        title: t("fastChecks.title"),
        call: () => {history.push(paths.FAST_CHECKS);},
        onClick: () => handleClickBreadCrumb(paths.FAST_CHECKS,true),
      }
    ],
    [paths.TRANSFERS_MONITORED_WALLETS_INFORMATION]: [
      {
        title: t("monitoredWallets.title"),
        onClick: () => handleClickBreadCrumb(paths.TRANSFERS_MONITORED_WALLETS),
      }
    ],
    [paths.TRANSFERS_MONITORED_WALLETS_INFORMATION_ARCHIVE]: [
      {
        title: t("monitoredWallets.title"),
        onClick: () => handleClickBreadCrumb(paths.TRANSFERS_MONITORED_WALLETS),
      },
      {
        title: getNameOrAddress(wallet?.data as THistoricalImport, monitoringWalletId),
        onClick: () => handleClickBreadCrumb(getWalletInformationPath(monitoringWalletId as string)),
      }
    ],
  }), [wallet, i18n.language, routePath, doesAnyHistoryEntryExist, clientId, clientArchiveId, clientDetail?.ext_id]);

  return (
    <div className="new-header__left">
      {routePath && breadCrumbs[routePath]
        ? <HeaderBreadCrumbs breadCrumbs={breadCrumbs[routePath]} />
        : <HeaderChecks/>
      }
    </div>
  );
};

export default HeaderLeft;
