import { FC } from "react";
import { useDispatch } from "react-redux";

import BasketIcon from "components/Icons/BasketIcon";

import { openModal } from "store/modals/actions";
import { EModals } from "store/modals/types";

const MobileHeaderAccessManagementDelete: FC = () => {
  const dispatch = useDispatch();
  
  return <BasketIcon
    onClick={() => dispatch(openModal({ modal:EModals.teamMemberDelete }))}
  />;
};

export default MobileHeaderAccessManagementDelete;