import { FC } from "react";
import get from "lodash/get";
import cn from "classnames";

import formatDate, { EFormats } from "utils/formatDate";

import { ERiskLevel, EUnknownRiskReason } from "../../../../store/transfers/types";

import {
  getTransferTypeTranslation
} from "../../../Transfers/components/TransfersFilter/components/TransfersFilterType/utils";

import { useTransferContext } from "../../index";

import TransfersDirectionIcon from "../TransfersDirectionIcon";

const TransfersDirection: FC = () => {
  const clientTransaction = useTransferContext();
  const type = get(clientTransaction, "type");
  const occurredAt = get(clientTransaction, "occurred_at");
  const level = get(clientTransaction, "risk_level");
  const checkingRiskReason = get(clientTransaction, "unknown_risk_reason");
  let reasonClass = null;

  if (level === ERiskLevel.unknown) {
    switch(checkingRiskReason) {
    case EUnknownRiskReason.checking:
      reasonClass = 'checking';
      break;
    case EUnknownRiskReason.not_supported:
      reasonClass = 'not_supported';
      break;
    case EUnknownRiskReason.no_checks:
    case EUnknownRiskReason.canceled:
    case EUnknownRiskReason.check_error:
      reasonClass = 'not_checked';
      break;
    }
  }

  return <div className="transfer__cel transfer-operation">
    <div className={cn("transfer-operation__risk-level", level, reasonClass )}/>
    <TransfersDirectionIcon error={reasonClass === 'not_checked'}/>
    <div className="transfer__col">
      <div className={cn("transfer-operation__subject", { error: reasonClass === 'not_checked' })}>
        {type ? getTransferTypeTranslation(type) : "―"}
      </div>
      {occurredAt && <div className="transfer-operation__occurred-at">
        {formatDate(occurredAt, EFormats.A)}
      </div>}
    </div>
  </div>;
};

export default TransfersDirection;
