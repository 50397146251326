import 'dayjs/locale/ru';
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";

import i18n from "../i18n";

dayjs.extend(relativeTime);


export const getRelativeTime = (value?: string | null): string => {
  if (!value) return "";
  setDateTimeLocale();
  return dayjs(value).fromNow(true);
};

export const onlyDate = (value?: string | number | null ): number => {
  if (!value) return  0;
  return new Date(value).setHours(0,0,0,0);
};

export const setDateTimeLocale = (val?: string): void => {
  dayjs.locale(val || i18n.language);
};
