import { createSelector } from "@reduxjs/toolkit";

import { IApplicationState } from "../rootInterface";

const selectState = (state: IApplicationState) => state.auth;

export const isAuthorizedSelector = createSelector(selectState, auth => auth?.isAuthorized);
export const signInSelector = createSelector(selectState, auth => auth?.signInState);
export const blockingTimeSelector = createSelector(selectState, auth => auth?.blockingTime);
export const codeTokenSelector = createSelector(selectState, auth => auth?.codeToken);
export const registrationInfoSelector = createSelector(selectState, auth => auth?.registrationInfo);
export const signUpSelector = createSelector(selectState, auth => auth?.signUpState);