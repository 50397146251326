import { ECurrency, TNullable } from "../../config/types";
import { ELanguages } from "../../i18n/constants";
import { EDateFormat, ETimeFormat } from "../../store/user/types";

import request from "../request";
import { TToken } from "../index";

import { TAccountEmployeePermissions } from "./account";

export enum EUserRiskLevel {
  low = "low",
  medium = "medium",
  high = "high",
  severe = "severe",
}
export type TUserInfoData = {
  id: string
  username: string
  alert_risk_level: EUserRiskLevel
  language: TNullable<ELanguages|"auto">
  currency: TNullable<ECurrency>
  checks_available: number
  permissions: TAccountEmployeePermissions
};

export type TUserChangePasswordData = {
  detail: string;
};
export type TUserChangePasswordBody = { old_password: string; new_password: string }

export type TUserSaveLocalSettingsBody = Partial<TUserSaveLocalSettingsData>
export type TUserSaveLocalSettingsData = {
  language: ELanguages|"auto";
  time_zone: string|"auto";
  time_format: ETimeFormat;
  date_format: EDateFormat;
  currency: ECurrency;
}

export type TUserSettingsInfoData = {
  phone: TNullable<string>;
  email: TNullable<string>;
  email_confirmed: boolean;
  telegram_nickname?: string;
  telegram_full_name?: string;
};

export type TUserSendConfirmationLinkData = {
  code_token: string;
  blocking_time: string;
};

export type UserBindPhoneEmailStep1Body = {
  recaptcha: string
  email?: string
  phone?: string
  password?: string
  passwordConfirm?: string
};
export type UserBindPhoneEmailStep1Data = {
  code_token: string;
  blocking_time: string;
};

export type UserBindPhoneEmailStep2Body = {
  code_token: string
  code: string
}

export type TUserBindPhoneEmailResendCodeBody = {
  code_token: string
}
export type TUserBindPhoneEmailResendCodeData = {
  code_token: string;
  blocking_time: string;
}

const user = {
  userInfo: (): Promise<TUserInfoData> =>
    request<TUserInfoData>({
      path: `/user/info/`,
      method: 'GET',
    }),
  userChangePassword: (body: TUserChangePasswordBody): Promise<TUserChangePasswordData> =>
    request<TUserChangePasswordData>({
      path: `/user/change-password/`,
      method: 'PUT',
      body
    }),
  userSaveLocalSettings: (body: TUserSaveLocalSettingsBody): Promise<TUserSaveLocalSettingsData> =>
    request<TUserSaveLocalSettingsData>({
      path: `/user/save-locale-settings/`,
      method: 'POST',
      body
    }),
  userAuthSettings: (): Promise<TUserSettingsInfoData> =>
    request<TUserSettingsInfoData>({
      path: `/user/auth-settings/`,
      method: 'GET',
    }),
  userSendConfirmationLink: (): Promise<TUserSendConfirmationLinkData> =>
    request<TUserSendConfirmationLinkData>({
      path: "/user/send-confirmation-link/",
      method: 'POST',
    }),
  userBindPhoneEmailStep1: (body: UserBindPhoneEmailStep1Body): Promise<UserBindPhoneEmailStep1Data> =>
    request<UserBindPhoneEmailStep1Data>({
      path: "/user/bind-phone-or-email/step-1/",
      method: 'POST',
      body,
    }),
  userBindPhoneEmailStep2: (body: UserBindPhoneEmailStep2Body): Promise<TToken> =>
    request<TToken>({
      path: "/user/bind-phone-or-email/step-2/",
      method: 'POST',
      body,
    }),
  userBindPhoneEmailResendCode: (body: TUserBindPhoneEmailResendCodeBody): Promise<TUserBindPhoneEmailResendCodeData> =>
    request<TUserBindPhoneEmailResendCodeData>({
      path: "/user/bind-phone-or-email/resend-code/",
      method: 'POST',
      body,
    }),
};

export default user;