import { FC } from "react";

import NotesIcon from "components/Icons/NotesIcon";

const TransferAdditional: FC = () => (
  <div className="transfer__cel transfer-additional">
    {false && <div className="transfer-additional__tag">
      <span>#</span>
    </div>}
    {false && <NotesIcon className="transfer-additional__notes"/>}
  </div>
);

export default TransferAdditional;
