import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import request from "api";

import { TPaginationOptions } from "api/types";

import hexToRgb from "utils/hexToRgb";
import { getRiskGradientColor } from "utils/colorGradient/RiskGradient";

import { IPayloadAction } from "../rootInterface";

import {
  TAlertDetail,
  TAlertDetailOptions, TAlertGroupsAntStatus,
  TAlertGroupsAntStatusesOptions,
  TAlertsActivitiesOptions,
  TAlertsAddCommentOptions,
  TAlertsAssignOptions,
  TAlertsChangeStatusOptions, TAlertsData,
  TAlertsFilter,
  TAlertsOptions
} from "./types";
import {
  alertDetailFailure,
  alertDetailRequest,
  alertDetailSetState,
  alertDetailSuccess,
  alertGroupsAntStatusesFailure,
  alertGroupsAntStatusesRequest,
  alertGroupsAntStatusesSuccess,
  alertsActivitiesFailure,
  alertsActivitiesRequest, alertsActivitiesRequestNoLoading,
  alertsActivitiesSuccess,
  alertsAddCommentFailure,
  alertsAddCommentRequest,
  alertsAddCommentSuccess,
  alertsAssignFailure,
  alertsAssignRequest,
  alertsAssignSuccess,
  alertsChangeStatusFailure,
  alertsChangeStatusRequest,
  alertsChangeStatusSuccess,
  alertsFailure,
  alertsRequest,
  alertsSetItem,
  alertsSuccess
} from "./reducers";


const alertsParams = (
  filter: TAlertsFilter|undefined,
  pagination: TPaginationOptions|undefined
) => {
  const params = new URLSearchParams();

  if (filter?.risk_level) params.set("risk_level", String(filter?.risk_level));
  if (filter?.status) params.set("status", String(filter?.status));
  if (filter?.group) params.set("group", String(filter?.group));
  if (filter?.type) params.set("type", String(filter?.type));
  if (filter?.entity_category) params.set("entity_category", String(filter?.entity_category));
  if (filter?.asset) params.set("asset", String(filter?.asset));
  if (filter?.date_from) params.set("date_from", String(filter?.date_from));
  if (filter?.date_to) params.set("date_to", String(filter?.date_to));
  if (filter?.client) params.set("client", String(filter?.client));
  if (filter?.search) params.set("search", String(filter?.search));
  if (filter?.transfer) params.set("transfer", String(filter?.transfer));

  if (pagination?.page) params.set("page", String(pagination?.page));
  if (pagination?.size) params.set("size", String(pagination?.size));

  // params.set("archived", "false");
  return params;
};

function* alerts(action: IPayloadAction<TAlertsOptions>) {
  const params = alertsParams(action.payload.filter, action.payload.pagination);
  try {
    const response: AxiosResponse<TAlertsData> = yield call(request.get, "/alerts/", { params });
    yield put(alertsSuccess({ data:  response.data, infiniteScroll: action.payload.infiniteScroll }));
    if (action.payload.callOnSuccess) action?.payload.callOnSuccess(response.data);
  } catch (error) {
    yield put(alertsFailure(error));
  }
}

function* alertGroupsAntStatuses(action: IPayloadAction<TAlertGroupsAntStatusesOptions>) {
  const params = alertsParams(action.payload, undefined);
  try {
    const response: AxiosResponse<TAlertGroupsAntStatus[]> =
      yield call(request.get, "/alerts/groups-and-statuses/", { params });
    yield put(alertGroupsAntStatusesSuccess(response.data));
    if (action.payload.callOnSuccess) {
      action.payload.callOnSuccess(response.data);
    }
  } catch (error) {
    yield put(alertGroupsAntStatusesFailure(error));
  }
}

function* alertDetail(action: IPayloadAction<TAlertDetailOptions>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse<TAlertDetail> = yield call(request.get, `/alerts/${id}/`);
    const sortedCategories = [...response.data.indirect_exposure || []].sort((a, b) => b.share - a.share );

    yield put(alertDetailSuccess({
      ...response.data,
      indirect_exposure: sortedCategories?.map(item => {
        const color = getRiskGradientColor(item.risk_score);
        return {
          ...item,
          color,
          rgb: color && hexToRgb(color)
        };
      })
    }));
  } catch (error) {
    yield put(alertDetailFailure(error));
  }
}

function* alertsAssign(action: IPayloadAction<TAlertsAssignOptions>) {
  const { id, data } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.post, `/alerts/${id}/assign/`, data);
    yield put(alertsAssignSuccess(response.data));
    yield put(alertDetailSetState({ assignee_name: data.user, updated_at: new Date().toUTCString() }));
    yield put(alertsSetItem({ id, data: { assignee_name: data.assignee_name } }));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess();
  } catch (error) {
    yield put(alertsAssignFailure(error));
  }
}

function* alertsChangeStatus(action: IPayloadAction<TAlertsChangeStatusOptions>) {
  const { id, data } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.post, `/alerts/${id}/change-status/`, data);
    yield put(alertsChangeStatusSuccess(response.data));
    yield put(alertDetailSetState({ status: data.status, updated_at: new Date().toUTCString() }));
    yield put(alertsSetItem({ id, data: { status: data.status } }));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess();
  } catch (error) {
    yield put(alertsChangeStatusFailure(error));
  }
}

function* alertsAddComment(action: IPayloadAction<TAlertsAddCommentOptions>) {
  const { id, data } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.post, `/alerts/${id}/add-comment/`, data);
    yield put(alertsAddCommentSuccess(response.data));
    // yield put(alertDetailSetState({ status: data.status }));
    if (action.payload.callOnSubmit) action.payload.callOnSubmit();
  } catch (error) {
    yield put(alertsAddCommentFailure(error));
  }
}

function* alertsActivities(action: IPayloadAction<TAlertsActivitiesOptions>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.get, `/alerts/${id}/activities/`);
    yield put(alertsActivitiesSuccess(response.data));
  } catch (error) {
    yield put(alertsActivitiesFailure(error));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(alertsRequest.type, alerts),
    takeLatest(alertGroupsAntStatusesRequest.type, alertGroupsAntStatuses),
    takeLatest(alertDetailRequest.type, alertDetail),
    takeLatest(alertsAssignRequest.type, alertsAssign),
    takeLatest(alertsChangeStatusRequest.type, alertsChangeStatus),
    takeLatest(alertsAddCommentRequest.type, alertsAddComment),
    takeLatest(alertsActivitiesRequest.type, alertsActivities),
    takeLatest(alertsActivitiesRequestNoLoading.type, alertsActivities),
  ]);
}

export default Saga;
