import { AxiosError } from "axios";

import i18n from "../i18n";

import { clearNotification } from "./notifications";

export const errorTextHandler = (e: AxiosError<any>): string => {
  clearNotification();
  if (e.response) {
    const key = Object.keys(e.response.data)[0];
    if (Array.isArray(e.response.data[key])) return e.response.data[key][0];
    else return e.response.data[key];
  } else return i18n.t("errors.errorTextHandler");
};

export enum EApiError {
  DETAIL_ERROR = "detail",
}

export const detailError = (e: AxiosError<any>): string =>
  e.response && e.response.data[EApiError.DETAIL_ERROR];

interface IResponseHandleError {
  detail?: string;
  parsedErrors?: Record<string, string>;
}

export const handleError = (e: AxiosError<any>): IResponseHandleError => {
  let detail;
  let parsedErrors;
  if ((e?.response?.status === 400 || e?.response?.status === 401 || e?.response?.status === 403)
    && e?.response?.data) {
    detail = detailError(e);
    parsedErrors = e?.response?.data;
  }
  return { detail, parsedErrors };
};
