import { assign, map } from "lodash";

import { TMonitoredWallets, TMonitoredWalletsData, TMonitoredWalletsState } from "./types";

export const toggleImportState = (wallet: TMonitoredWallets, importId: string): TMonitoredWallets => {
  const { id, live_import_enabled } = wallet;
  if (id === importId) {
    return assign(wallet, { live_import_enabled: !live_import_enabled });
  }
  return wallet;
};

export const changeImportWalletStatus = (monitoredWallets: TMonitoredWalletsState, id: string)
  :TMonitoredWalletsData => {
  const results = map(monitoredWallets.data?.results, wallet => toggleImportState(wallet, id));

  return assign(monitoredWallets.data, results);
};
