import { createSlice } from "@reduxjs/toolkit";


import { defaultState } from "../constants";
import { IPayloadAction } from "../rootInterface";

import {
  TExplorerFindAddressOrTransaction,
  TExplorerFindTransferOptions, TExplorerFoundAddress,
  TExplorerStoreState,
  TExplorerTransferData, TFindAddressOptions, TFindAddressOrTransactionSOptions
} from "./types";

const initialState: TExplorerStoreState = {
  explorerFindTransfer: defaultState,
  explorerFindAddressOrTransaction: defaultState,
  explorerFindAddress: defaultState,
};

const explorerSlice = createSlice({
  name: "explorer",
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    explorerFindTransferRequest(state, _: IPayloadAction<TExplorerFindTransferOptions>) {
      state.explorerFindTransfer = {
        ...state.explorerFindTransfer,
        fetching: true,
      };
    },
    explorerFindTransferSuccess(state, action: IPayloadAction<TExplorerTransferData>) {
      state.explorerFindTransfer = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    explorerFindTransferFailure(state, action) {
      state.explorerFindTransfer = {
        ...state.explorerFindTransfer,
        fetching: false,
        failure: action.payload,
      };
    },
    explorerFindTransferClear(state) {
      state.explorerFindTransfer = defaultState;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    explorerFindAddressOrTransactionRequest(state, _: IPayloadAction<TFindAddressOrTransactionSOptions>) {
      state.explorerFindAddressOrTransaction = {
        ...state.explorerFindAddressOrTransaction,
        fetching: true,
      };
    },
    explorerFindAddressOrTransactionSuccess(state, action: IPayloadAction<TExplorerFindAddressOrTransaction>) {
      state.explorerFindAddressOrTransaction = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    explorerFindAddressOrTransactionFailure(state, action) {
      state.explorerFindAddressOrTransaction = {
        fetching: false,
        data: null,
        failure: action.payload,
      };
    },
    explorerFindAddressOrTransactionClear(state) {
      state.explorerFindAddressOrTransaction = defaultState;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    explorerFindAddressRequest(state, _: IPayloadAction<TFindAddressOptions>) {
      state.explorerFindAddress = {
        ...state.explorerFindAddress,
        fetching: true,
      };
    },
    explorerFindAddressSuccess(state, action: IPayloadAction<TExplorerFoundAddress>) {
      state.explorerFindAddress = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    explorerCleanData(state) {
      state.explorerFindAddress = {
        data: null,
        failure: null,
        fetching: false
      };
    },
    explorerFindAddressFailure(state, action) {
      state.explorerFindAddress = {
        fetching: false,
        data: null,
        failure: action.payload,
      };
    },
    explorerFindAddressClear(state) {
      state.explorerFindAddress = defaultState;
    },
  },
});

export const {
  explorerFindTransferRequest,
  explorerFindTransferSuccess,
  explorerFindTransferFailure,
  explorerFindTransferClear,

  explorerFindAddressOrTransactionRequest,
  explorerFindAddressOrTransactionSuccess,
  explorerFindAddressOrTransactionFailure,
  explorerFindAddressOrTransactionClear,

  explorerFindAddressRequest,
  explorerFindAddressSuccess,
  explorerFindAddressFailure,
  explorerFindAddressClear,
  explorerCleanData,
} = explorerSlice.actions;

export default explorerSlice.reducer;
