import { createSelector } from "@reduxjs/toolkit";

import { IApplicationState } from "../rootInterface";

const selectState = (state: IApplicationState) => state.clients;

export const clientsSelector = createSelector(selectState, clients => clients?.clients);
export const clientsArchiveSelector = createSelector(selectState, clients => clients?.clientsArchive);
export const clientsDetailsSelector = createSelector(selectState, clients => clients?.clientsDetails);
export const clientsDetailsExposureSelector = createSelector(selectState, clients => clients?.clientsDetailsExposure);
export const clientArchiveSelector = createSelector(selectState, clients => clients?.clientArchive);
export const clientUnArchiveSelector = createSelector(selectState, clients => clients?.clientUnArchive);
