import { createSlice } from "@reduxjs/toolkit";

import { defaultPaginationData, defaultState, defaultInitialState } from "../constants";
import { IPayloadAction } from "../rootInterface";

import {
  TClientArchiveOptions,
  TClientsData,
  TClientsDetails, TClientsDetailsExposure,
  TClientsDetailsExposureOptions,
  TClientsDetailsOptions,
  TClientsOptions,
  TClientsStoreState, TClientUnArchiveOptions
} from "./types";

const initialState: TClientsStoreState = {
  clients: {
    ...defaultState,
    data: defaultPaginationData,
    initialLoading: true,
  },
  clientsArchive: {
    ...defaultState,
    data: defaultPaginationData,
    initialLoading: true,
  },
  clientsDetails: {
    ...defaultState,
    initialLoading: true
  },
  clientsDetailsExposure: defaultState,
  clientArchive: defaultState,
  clientUnArchive: defaultState,
};

const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    clientsRequest(state, action: IPayloadAction<TClientsOptions>) {
      state.clients = {
        ...state.clients,
        fetching: true,
        infiniteScroll: action.payload?.infiniteScroll
      };
    },
    clientsSuccess(state, action: IPayloadAction<{
      data: TClientsData, infiniteScroll?: boolean;
    }>) {
      let newData = action.payload.data;
      if (action.payload.infiniteScroll) {
        const prevResults = state?.clients?.data?.results || [];

        newData = {
          ...action.payload.data,
          results: [...prevResults, ...(action.payload?.data?.results || [])]
        };
      }
      state.clients = {
        ...state.clients,
        fetching: false,
        data: newData,
        failure: null,
        initialLoading: false,
      };
    },
    clientsFailure(state, action) {
      state.clients = {
        ...state.clients,
        fetching: false,
        failure: action.payload,
      };
    },
    clientsRemove(state, action: IPayloadAction<{ id: string }>) {
      const newData = state.clients.data;
      if (newData) {
        newData.results = newData.results
          .filter(item => item.id !== action.payload.id) || [];
        newData.count = newData.count - 1;
      }
      state.clients = {
        ...state.clients,
        fetching: false,
        data: newData,
        failure: null,
        initialLoading: false,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    clientsArchiveRequest(state, action: IPayloadAction<TClientsOptions>) {
      state.clientsArchive = {
        ...state.clientsArchive,
        fetching: true,
        infiniteScroll: action.payload?.infiniteScroll
      };
    },
    clientsArchiveSuccess(state, action: IPayloadAction<{
      data: TClientsData, infiniteScroll?: boolean;
    }>) {
      let newData = action.payload.data;
      if (action.payload.infiniteScroll) {
        const prevResults = state?.clientsArchive?.data?.results || [];

        newData = {
          ...action.payload.data,
          results: [...prevResults, ...(action.payload?.data?.results || [])]
        };
      }
      state.clientsArchive = {
        ...state.clientsArchive,
        fetching: false,
        data: newData,
        failure: null,
        initialLoading: false,
      };
    },
    clientsArchiveFailure(state, action) {
      state.clientsArchive = {
        ...state.clientsArchive,
        fetching: false,
        failure: action.payload,
      };
    },
    clientsArchiveRemove(state, action: IPayloadAction<{ id: string }>) {
      const newData = state.clientsArchive.data;
      if (newData) {
        newData.results = newData.results
          .filter(item => item.id !== action.payload.id) || [];
        newData.count = newData.count - 1;
      }
      state.clientsArchive = {
        ...state.clientsArchive,
        fetching: false,
        data: newData,
        failure: null,
        initialLoading: false,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    clientsDetailsRequest(state, _: IPayloadAction<TClientsDetailsOptions>) {
      state.clientsDetails = {
        ...state.clientsDetails,
        fetching: true,
      };
    },
    clientsDetailsSuccess(state, action: IPayloadAction<TClientsDetails>) {
      state.clientsDetails = {
        fetching: false,
        data: action.payload,
        failure: null,
        initialLoading: false,
      };
    },
    clientsDetailsFailure(state, action) {
      state.clientsDetails = {
        ...state.clientsDetails,
        fetching: false,
        failure: action.payload,
        initialLoading: false,
      };
    },
    clientsDetailsLeave(state) {
      state.clientsDetails = {
        ...state.clientsDetails,
        initialLoading: true,
      };
    },
    clientsDetailsClear(state) {
      state.clientsDetails = defaultInitialState;
    },


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    clientsDetailsExposureRequest(state, _: IPayloadAction<TClientsDetailsExposureOptions>) {
      state.clientsDetailsExposure = {
        ...state.clientsDetailsExposure,
        fetching: true,
      };
    },
    clientsDetailsExposureSuccess(state, action: IPayloadAction<TClientsDetailsExposure>) {
      state.clientsDetailsExposure = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    clientsDetailsExposureFailure(state, action) {
      state.clientsDetailsExposure = {
        ...state.clientsDetailsExposure,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    clientArchiveRequest(state, _: IPayloadAction<TClientArchiveOptions>) {
      state.clientArchive = {
        ...state.clientArchive,
        fetching: true,
      };
    },
    clientArchiveSuccess(state, action: IPayloadAction<unknown>) {
      state.clientArchive = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
      state.clientsDetails = {
        ...state.clientsDetails,
        data: state.clientsDetails.data ? {
          ...state.clientsDetails.data,
          is_archived: true
        } : null
      };
    },
    clientArchiveFailure(state, action) {
      state.clientArchive = {
        ...state.clientArchive,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    clientUnArchiveRequest(state, _: IPayloadAction<TClientUnArchiveOptions>) {
      state.clientUnArchive = {
        ...state.clientUnArchive,
        fetching: true,
      };
    },
    clientUnArchiveSuccess(state, action: IPayloadAction<unknown>) {
      state.clientUnArchive = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
      state.clientsDetails = {
        ...state.clientsDetails,
        data: state.clientsDetails.data ? {
          ...state.clientsDetails.data,
          is_archived: false
        } : null
      };
    },
    clientUnArchiveFailure(state, action) {
      state.clientUnArchive = {
        ...state.clientUnArchive,
        fetching: false,
        failure: action.payload,
      };
    },
  },
});

export const {
  clientsRequest,
  clientsSuccess,
  clientsFailure,
  clientsRemove,

  clientsArchiveRequest,
  clientsArchiveSuccess,
  clientsArchiveFailure,
  clientsArchiveRemove,

  clientsDetailsRequest,
  clientsDetailsSuccess,
  clientsDetailsFailure,
  clientsDetailsClear,
  clientsDetailsLeave,

  clientsDetailsExposureRequest,
  clientsDetailsExposureSuccess,
  clientsDetailsExposureFailure,

  clientArchiveRequest,
  clientArchiveSuccess,
  clientArchiveFailure,

  clientUnArchiveRequest,
  clientUnArchiveSuccess,
  clientUnArchiveFailure,
} = clientsSlice.actions;

export default clientsSlice.reducer;
