import { FC } from "react";
import { useHistory } from "react-router";

import PlusIcon from "../../../../../../Icons/PlusIcon";
import { paths } from "../../../../../../../config/paths";

const MobileHeaderNewMember: FC = () => {
  const history = useHistory();
  
  return <PlusIcon className="plus-icon" onClick={() => history.push(paths.ACCESS_MANAGEMENT_NEW)}/>;
};

export default MobileHeaderNewMember;