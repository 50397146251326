import { IPaginationResponse, TPagination, TPaginationOptions } from "api/types";

import { ECurrency, TNullable } from "config/types";

import { RGB } from "utils/hexToRgb";

import {
  TRequestDataExistsHandler,
  TRequestHandler
} from "../rootInterface";
import { AlertStatus, EAlertSubjects, EProximity } from "../alerts/types";
import { TExplorerTransfer } from "../explorer/types";

export enum EDirection {
  incoming = "incoming",
  outgoing = "outgoing",
}
export enum ETransferAction {
  c2c_transfer = "с2с_transfer",
  sbp_transfer = "sbp_transfer",
  card_charge = "card_charge",
  card_refund = "card_refund",
  salary_payment = "salary_payment",
  wire_transfer = "wire_transfer",
}
export enum ERiskLevel {
  no_risk = "no_risk",
  none = "none",
  unknown = "unknown",
  low = "low",
  medium = "medium",
  high = "high",
  severe = "severe",
}

export enum EFastCheckGroups {
  transfer_checks  = 'transfer_checks',
  address_checks = 'address_checks',
  all = 'all',
}

export enum EUnknownRiskReason {
  no_checks = "no_checks",
  check_error = "check_error",
  canceled = "canceled",
  not_supported = "not_supported",
  checking = "checking",
}
export type TTransfer = {
  id: string
  occurred_at: string
  direction: EDirection
  type: ETransferType
  type_str: string
  client_ext_id: string
  risk_level: ERiskLevel
  network_code: string
  network_name: string
  asset: TNullable<string>
  amount: TNullable<number>
  fiat_currency: TNullable<string>
  fiat_value: TNullable<number>
  counterparty_category: TNullable<string>
  counterparty_name: TNullable<string>
  counterparty_address: TNullable<string>
  unknown_risk_reason: TNullable<EUnknownRiskReason>
}

export enum ETransfersFilterKeys {
  type = "type",
  risk_level = "risk_level",
  group = 'group',
  counterparty = "counterparty",
  asset = "asset",
  blockchain = "blockchain",
  date_from = "date_from",
  date_to = "date_to",
  search = "search",
  network = "network",
  client = "client",
  date_range = "date_range",
  monitored_wallet = "monitored_wallet",
}
export enum ETransferType {
  deposit = "deposit",
  withdrawal = "withdrawal",
  deposit_attempt = "deposit_attempt",
  withdrawal_attempt = "withdrawal_attempt",
  import_wallet = 'import_wallet',
}

export type TTransfersFilter = {
  [ETransfersFilterKeys.type]?: ETransferType;
  [ETransfersFilterKeys.risk_level]?: ERiskLevel;
  [ETransfersFilterKeys.group]?: EFastCheckGroups,
  [ETransfersFilterKeys.counterparty]?: string;
  [ETransfersFilterKeys.asset]?: string;
  [ETransfersFilterKeys.blockchain]?: string;
  [ETransfersFilterKeys.date_from]?: TNullable<string>;
  [ETransfersFilterKeys.date_to]?: TNullable<string>;
  [ETransfersFilterKeys.date_range]?: TNullable<string>;
  [ETransfersFilterKeys.search]?: string;
  [ETransfersFilterKeys.network]?: string;
  [ETransfersFilterKeys.client]?: string;
  [ETransfersFilterKeys.monitored_wallet]?: string;
};

export type TTransferOptions = TPagination & {
  filter?: TTransfersFilter
  infiniteScroll?: boolean;
  callOnSuccess?: (next?: TNullable<number>) => void
}
export type TTransferData = IPaginationResponse<TTransfer>
export type TTransferState = TRequestDataExistsHandler<TTransferData> & {
  infiniteScroll?: boolean;
  lastElementFromPreviousPage: TNullable<TTransfer>;
  firstElementOfNextPage: TNullable<TTransfer>;
  initialLoading: boolean
}

export type TTransferDetailsIndirectExposure = {
  entity_category: string
  share: number
  risk_score: number
  color?: string // formed after load
  rgb?: RGB // formed after load
}
export enum ETransferDirection {
  incoming = "incoming",
  outgoing = "outgoing",
}
export type TTransferDetailsCounterparty = {
  entity_name: string
  entity_category: string
  risk_score: number
}
export type TExposureDetails = {
  checked_at: TNullable<string>
  proximity: EProximity
  counterparty: TNullable<TTransferDetailsCounterparty>
  indirect_exposure: TNullable<TTransferDetailsIndirectExposure[]>
  report_url: string | null
}
export type TCounterparty = {
  checked_at: TNullable<string>
  entity_name: TNullable<string>
  entity_category: TNullable<string>
  risk_score: TNullable<number>
  exposure: TNullable<TTransferDetailsIndirectExposure[]>
  report_url: string | null
}
export enum EExposureStatus {
  not_checked = "not_checked",
  error = "error",
  checked = "checked",
  checking = "checking",
  not_supported = "not_supported",
  not_available = "not_available",
}
export enum ECounterpartyStatus {
  not_checked = "not_checked",
  checking = "checking",
  checked = "checked",
  error = "error",
  not_supported = "not_supported",
  not_available = "not_available",
}
export type TTransferDetails = {
  id: string
  occurred_at: string
  type: ETransferType
  direction: ETransferDirection
  exposure_status: EExposureStatus
  client_ext_id: string
  client_id: string
  registered_at: string
  network_code: string
  network_name: string
  tx_hash: string
  input_address: TNullable<string>
  output_address: TNullable<string>
  asset: string
  asset_token_id: string
  amount: number
  fiat_currency: ECurrency
  fiat_value: number
  exposure: TNullable<TExposureDetails>
  counterparty_status: ECounterpartyStatus
  counterparty: TNullable<TCounterparty>
}
export type TTransferDetailsOptions = {
  id: string
  prev_exposure_status?: EExposureStatus
  prev_counterparty_status?: ECounterpartyStatus

}
export type TTransferDetailsState = TRequestHandler<TTransferDetails> & {
  initialLoading: boolean
}
export type TTransferRegisterData = {
  client_ext_id: TNullable<string>
  token: string
  tx_hash: string
  output_address: string
  direction: ETransferDirection
}
export type TTransferRegisterOptions = {
  data: TTransferRegisterData
  callOnSuccess?: (id: string) => void
}

export enum ERegisteringResult {
  started = "started",
  completed = "completed",
  already_registered = "already_registered",
}
export enum ECheckingResult {
  started = "started",
  not_supported = "not_supported",
  no_checks = "no_checks",
  not_required = "not_required",
}
export enum ETransferCheckStatus {
  not_checked = "not_checked",
  checking = "checking",
  checked = "checked",
  error = "error",
  not_supported = "not_supported",
}
export type TTransferRegisterResponseData = {
  registering_result: ERegisteringResult
  checking_result: ECheckingResult
  id: string
  type: string
  exposure_status: ETransferCheckStatus
}
export type TTransferRegisterState = TRequestHandler<TTransferRegisterResponseData>

export type TTransferRegisterAttemptData = {
  client_ext_id: TNullable<string>
  token: TNullable<string>
  network: string
  direction: ETransferDirection
  input_address?: string
  output_address?: string
  amount: TNullable<string>
}
export type TTransferRegisterAttemptOptions = {
  data: TTransferRegisterAttemptData
  callOnSuccess?: (id: string) => void
}
export type TTransferRegisterAttemptState = TRequestHandler<TTransferRegisterResponseData>

export type TTransferBindTransactionData = {
  tx_hash: string
  token: string
  input_address: string
  output_address: string
}
export type TTransferBindTransactionOptions = {
  id: string
  data: TTransferBindTransactionData
  callOnSuccess?: (id: string) => void
}
export type TTransferBindTransactionState = TRequestHandler<TTransferRegisterResponseData>

export type TTransferRecheckExposureOptions = {
  id: string
}
export type TTransferRecheckExposure = {
  checking_result: ECheckingResult
  exposure_status: EExposureStatus
}
export type TTransferRecheckExposureState = TRequestHandler<TTransferRecheckExposure>


export type TTransferRecheckCounterpartyOptions = {
  id: string
}
export type TTransferRecheckCounterparty = {
  counterparty_status: ECounterpartyStatus
  checking_result: ECheckingResult
}
export type TTransferRecheckCounterpartyState = TRequestHandler<TTransferRecheckCounterparty>


export type TTransferRisksOptions = {
  infiniteScroll?: boolean;
  query?: TPaginationOptions & {
    transfer?: string
  }
  callOnSuccess?: (data: TTransferRisksData) => void
}
export type TTransferRisk = {
  id: string
  created_at: string
  occurred_at: string
  type: EAlertSubjects
  risk_level: ERiskLevel
  entity_category: string
  interaction: EProximity
  fiat_currency: ECurrency
  fiat_value: TNullable<number>
  risky_value: number
  risky_value_share: number
  client_ext_id: string
  network_code: string
  network_name: string
  asset: TNullable<string>
  amount: TNullable<number>
  alert_id: string
  alert_status: AlertStatus
  alert_assignee_name: TNullable<string>
};
export type TTransferRisksData = IPaginationResponse<TTransferRisk>
export type TTransferRisksState = TRequestHandler<TTransferRisksData> & {
  initialLoading: boolean
};

export type TTransferFindTransactionToBindData = {
  tx_hash: string
  transfers: TExplorerTransfer[]
}
export type TTransferFindTransactionToBindState = TRequestHandler<TTransferFindTransactionToBindData>
export type TTransferFindTransactionToBindBody = {
  tx_hash: string
}
export type TTransferFindTransactionToBindOptions = {
  id: string
  body: TTransferFindTransactionToBindBody
}

export type TTransfersStoreState = {
  transfersState: TTransferState
  transfersClone: TNullable<TTransferState>
  transfersDetails: TTransferDetailsState
  transferRegister: TTransferRegisterState
  transferRegisterAttempt: TTransferRegisterAttemptState
  transferBindTransaction: TTransferBindTransactionState
  transferRecheckExposure: TTransferRecheckExposureState
  transferRecheckCounterparty: TTransferRecheckCounterpartyState
  transferRisks: TTransferRisksState
  transferFindTransactionToBind: TTransferFindTransactionToBindState
};
